.control-label {
	sup {
		font-size: 100%;
		color: $red;
		@include rem(top, -2px);
	}
}

.form-control {
	border: 1px solid $gray-600;
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, .25);
	}
}