.navbar-custom {
	@include rem(margin-bottom, 30px);
	background-color: #7b7f98;
	background-image: linear-gradient(180deg, rgba(0,0,0,.3) 0%, transparent 50%);

	.navbar-collapse {
		@include media-breakpoint-down(md) {
			@include rem(padding, 10px);
			background-color: rgba(255,255,255,0.14);
		}
	}

	@include media-breakpoint-up(lg) {
		.nav-link {
			color: white;
			font-weight: bold;

			&.active,
			&:hover {
				//color: #99B745;
				color: #e4ff98;
			}
		}
	}

	.navbar-toggler-icon {
		background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
	}
}
