.section-news-overview {
	@include rem(border-radius, 10px);

	.news-content-box {
		@include rem(padding, 20px);
		position: relative;

		.news-headline {
			a:hover {
				text-decoration: underline;
				color: $body-color;
			}
		}

		.news-calendar {
			@include rem(padding-bottom, 20px);
			
			i {
				@include rem(margin-right, 5px);
			}

			span {
				@include rem(font-size, 14px);
				font-weight: 300;
			}
		}

		.news-teaser {
			@include rem(font-size, 14px);
			@include rem(margin-bottom, 10px);
		}

		.news-button {

		}
	}


	&.has-image {
		background-color: #000;
		color: white;

		a {
			color: white;
			text-decoration: underline;
			&:hover {
				color: white;

			}
		}

		.news-image-box {
			position: relative;

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100px;
				background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
			}

			img {
				@include rem(border-radius, 10px 10px 0 0);
			}
		}

		.news-content-box {
			@include rem(margin-top, -30px);
			padding-top: 0;

			.news-headline {
				a {
					color: white;
				}
			}

			.news-button {
				text-align: right;
			}
		}
	}

	&:not(.has-image) {
		background-color: white;
	}
}