.scrolling-text-outer {

	background-color: #f317ac;
	@include rem(margin, 50px 0 25px 0);
	border-radius: 10px;

	@include media-breakpoint-up(lg) {
			@include rem(margin, 0 0 25px 0);
		}

	.scrolling-text-inner {

		@include rem(margin, 0 20px);
		position: relative;
		overflow: hidden;
		height: 39px;
		@include media-breakpoint-up(md) {
			height: 39px;
		}

		.scrolling-text {
			position: absolute;
			overflow: auto;
			@include rem(font-size, 14px);
			@include media-breakpoint-up(md) {
				@include rem(font-size, 16px);
			}

			div {
				padding: 10px;
				float: left;
				color: white;

				a {
					color: white;
					text-decoration: underline;
				}
			}
		}
	}
}

.scrolling-sidebar-text {
	@include rem(margin-bottom, 20px);
	@include rem(padding, 10px);
	border-radius: 10px;
	@include rem(font-size, 14px);
	font-weight: bold;
	text-align: center;
	color: white;

	a {
		color: white;
		text-decoration: underline;

		&:hover,
		&:active {
			text-decoration: none;
		}
	}
}
