// BEISPIEL
//.text-gray-40 {
//	color: $gray-40 !important;
//}
@each $color in map-keys($arr_text_color) {
	.#{$color} {
		color: map-get($arr_text_color, $color) !important;
	}
}

// BEISPIEL
//.bg-gray-40 {
//	background: $gray-40 !important;
//}
@each $color in map-keys($arr_bg_color) {
	.#{$color} {
		background: map-get($arr_bg_color, $color) !important;
	}
}