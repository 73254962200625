.section-home-hero {

	.section-home-hero-content {
		background-color: #7b7f98;
	}

	.section-home-hero-bg-image {
		@include media-breakpoint-up(lg) {
			background: url('../images/bg-home-hero-desktop-v2.jpg') no-repeat;
			height: calc(100vh - 81px);
			background-position-y: -70px;
			background-size: cover;
			min-height: 850px;
		}
	}

	.section-home-hero-arrow {
		position: relative;
		height: 40px;
		//background-color: $white;

		&:after {
			top: 0;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(0, 0, 0, 0);
			border-top-color: #7b7f98;
			border-width: 40px;
			margin-left: -40px;
		}
	}

	.home-youtube-box {
		text-align: center;
		@include rem(margin-top, 50px);
		@include rem(margin-bottom, 30px);

		p {
			color: white;
			@include rem(font-size, 14px);
		}
	}

	.section-tiles {
		display: flex;
		flex-wrap: wrap;
		@include rem(padding-bottom, 30px);

		.tile-box {
			width: calc(100% - 8px);

			@media (min-width: 350px) {
				width: calc(50% - 8px);
			}

			@include media-breakpoint-up(lg) {
				width: calc(25% - 8px);
			}

			@include media-breakpoint-up(xl) {
				width: calc(12.5% - 8px);
			}


			color: white;
			background-color: rgba(255, 255, 255, 0.18);
			//border: 1px solid $gray-800;
			height: 230px;
			@include rem(margin, 4px 4px);
			@include rem(border-radius, 10px);

			img {
				transition: transform 0.2s ease-in;
			}

			p {
				margin-bottom: 0;
				text-transform: uppercase;
			}

			&:hover {
				background-color: rgba(1, 168, 162, 30%);
				text-decoration: none;

				img {
					transform: scaleX(-1);
				}

				p {
					color: $white;
					text-decoration: none;
				}
			}
		}
	}


	.tile-old-logo {
		color: $white;
		background-color: $gray-900;
		border: 1px solid $white;
		height: 370px;
	}

	.tile-old-box {
		color: white;
		background-color: $gray-900;
		border: 1px solid $white;
		height: 185px;
	}
}


//.section-home-hero-footer {
//	position: relative;
//	height: 40px;
//	background-color: $white;
//	&:after {
//		top: 0;
//		left: 50%;
//		border: solid transparent;
//		content: " ";
//		height: 0;
//		width: 0;
//		position: absolute;
//		pointer-events: none;
//		border-color: rgba(0, 0, 0, 0);
//		border-top-color: #000;
//		border-width: 40px;
//		margin-left: -40px;
//	}
//}