footer {
	background-size: cover;
	@include rem(margin, 40px 0 0 0);
	@include rem(padding, 50px 0);
	color: $white;
	background: #7b7f98 url('../images/bg-footer-desktop.png') no-repeat center top;
	@include rem(font-size, 18px);
	font-weight: 300;

	@include media-breakpoint-down(lg) {
		background: #3c3e4b url('../images/bg-footer-mobile.jpg') no-repeat;
		background-size: contain;
		background-position: center bottom;
	}

	a {
		color: $white;
		text-decoration: none;

		&:hover,
		&:focus {
			color: $white;
			text-decoration: none;
		}
	}

	ul.list-pipe {
		li {
			@include rem(font-size, 14px);
			@include media-breakpoint-up(lg) {
				@include rem(font-size, 16px);
			}
			&:first-of-type {
				&:before {
					content: "";
					margin-right: 0;
				}
			}

			&:before {
				content: "|";
				@include rem(margin-right, 5px);
			}
		}
	}

	ul.list-social-icons {
		a {
			color: white;
			@include rem(font-size, 40px);
		}
	}
}