/*!
 * Bootstrap v4.0.0-beta.3 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #00ace9;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #d9534f;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: #ffc107;
  --secondary: #000;
  --success: #28a745;
  --info: #01A8A2;
  --warning: #ffc107;
  --danger: #d9534f;
  --light: #e9ecef;
  --dark: #343a40;
  --breakpoint-xxs: 0;
  --breakpoint-xs: 360px;
  --breakpoint-sm: 480px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nunito Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.46875rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #495057;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #495057;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.46875rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 0.9375rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid, .section-content-white img {
  max-width: 100%;
  height: auto; }

.img-thumbnail, .note-float-left, .note-float-right {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.46875rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #868e96; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 480px) {
    .container {
      max-width: 480px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 750px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1170px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 360px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xs-first {
    order: -1; }
  .order-xs-1 {
    order: 1; }
  .order-xs-2 {
    order: 2; }
  .order-xs-3 {
    order: 3; }
  .order-xs-4 {
    order: 4; }
  .order-xs-5 {
    order: 5; }
  .order-xs-6 {
    order: 6; }
  .order-xs-7 {
    order: 7; }
  .order-xs-8 {
    order: 8; }
  .order-xs-9 {
    order: 9; }
  .order-xs-10 {
    order: 10; }
  .order-xs-11 {
    order: 11; }
  .order-xs-12 {
    order: 12; }
  .offset-xs-0 {
    margin-left: 0; }
  .offset-xs-1 {
    margin-left: 8.33333%; }
  .offset-xs-2 {
    margin-left: 16.66667%; }
  .offset-xs-3 {
    margin-left: 25%; }
  .offset-xs-4 {
    margin-left: 33.33333%; }
  .offset-xs-5 {
    margin-left: 41.66667%; }
  .offset-xs-6 {
    margin-left: 50%; }
  .offset-xs-7 {
    margin-left: 58.33333%; }
  .offset-xs-8 {
    margin-left: 66.66667%; }
  .offset-xs-9 {
    margin-left: 75%; }
  .offset-xs-10 {
    margin-left: 83.33333%; }
  .offset-xs-11 {
    margin-left: 91.66667%; } }

@media (min-width: 480px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.9375rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffeeba; }

.table-hover .table-primary:hover {
  background-color: #ffe8a1; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #ffe8a1; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b8b8b8; }

.table-hover .table-secondary:hover {
  background-color: #ababab; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #ababab; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e7e5; }

.table-hover .table-info:hover {
  background-color: #a5e1de; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a5e1de; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4cfce; }

.table-hover .table-danger:hover {
  background-color: #efbbb9; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #efbbb9; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9fafb; }

.table-hover .table-light:hover {
  background-color: #eaedf1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eaedf1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 359.99px) {
  .table-responsive-xs {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xs > .table-bordered {
      border: 0; } }

@media (max-width: 479.99px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.99px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.99px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.99px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.4;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #6d7a86;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25); }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.15rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.4; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.75rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.4;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn, .pagination li .input-group-sm > .input-group-prepend > span.form-control-plaintext,
  .pagination li .input-group-sm > .input-group-prepend > a.form-control-plaintext,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .pagination li .input-group-sm > .input-group-append > span.form-control-plaintext,
  .pagination li .input-group-sm > .input-group-append > a.form-control-plaintext, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn, .pagination li .input-group-lg > .input-group-prepend > span.form-control-plaintext,
  .pagination li .input-group-lg > .input-group-prepend > a.form-control-plaintext,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn, .pagination li .input-group-lg > .input-group-append > span.form-control-plaintext,
  .pagination li .input-group-lg > .input-group-append > a.form-control-plaintext {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn, .pagination li .input-group-sm > .input-group-prepend > span,
.pagination li .input-group-sm > .input-group-prepend > a,
.input-group-sm > .input-group-append > .btn, .pagination li .input-group-sm > .input-group-append > span,
.pagination li .input-group-sm > .input-group-append > a {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.625rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn, .pagination li .input-group-lg > .input-group-prepend > span,
.pagination li .input-group-lg > .input-group-prepend > a,
.input-group-lg > .input-group-append > .btn, .pagination li .input-group-lg > .input-group-append > span,
.pagination li .input-group-lg > .input-group-append > a {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.5rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d9534f; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(217, 83, 79, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #d9534f; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #d9534f;
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d9534f; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d9534f; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #f0b9b8; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e27c79; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d9534f; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 480px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .pagination li span,
.pagination li a {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.4;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:focus, .pagination li span:focus,
  .pagination li a:focus, .btn:hover, .pagination li span:hover,
  .pagination li a:hover {
    text-decoration: none; }
  .btn:focus, .pagination li span:focus,
  .pagination li a:focus, .btn.focus, .pagination li span.focus,
  .pagination li a.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25); }
  .btn.disabled, .pagination li span.disabled,
  .pagination li a.disabled, .btn:disabled, .pagination li span:disabled,
  .pagination li a:disabled {
    opacity: 0.65; }
  .btn:not([disabled]):not(.disabled), .pagination li span:not([disabled]):not(.disabled),
  .pagination li a:not([disabled]):not(.disabled) {
    cursor: pointer; }
  .btn:not([disabled]):not(.disabled):active, .pagination li span:not([disabled]):not(.disabled):active,
  .pagination li a:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active, .pagination li span:not([disabled]):not(.disabled).active,
  .pagination li a:not([disabled]):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
.pagination li a.disabled,
fieldset[disabled] a.btn,
fieldset[disabled] .pagination li a,
.pagination li fieldset[disabled] a {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-primary:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-primary:not([disabled]):not(.disabled):active:focus, .btn-primary:not([disabled]):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #000;
  border-color: #000; }
  .btn-secondary:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #000;
    border-color: #000; }
  .btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-secondary:not([disabled]):not(.disabled):active:focus, .btn-secondary:not([disabled]):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not([disabled]):not(.disabled):active:focus, .btn-success:not([disabled]):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info, .pagination li span,
.pagination li a {
  color: #fff;
  background-color: #01A8A2;
  border-color: #01A8A2; }
  .btn-info:hover, .pagination li span:hover,
  .pagination li a:hover {
    color: #fff;
    background-color: #01827d;
    border-color: #017571; }
  .btn-info:focus, .pagination li span:focus,
  .pagination li a:focus, .btn-info.focus, .pagination li span.focus,
  .pagination li a.focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 168, 162, 0.5); }
  .btn-info.disabled, .pagination li span.disabled,
  .pagination li a.disabled, .btn-info:disabled, .pagination li span:disabled,
  .pagination li a:disabled {
    background-color: #01A8A2;
    border-color: #01A8A2; }
  .btn-info:not([disabled]):not(.disabled):active, .pagination li span:not([disabled]):not(.disabled):active,
  .pagination li a:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active, .pagination li span:not([disabled]):not(.disabled).active,
  .pagination li a:not([disabled]):not(.disabled).active,
  .show > .btn-info.dropdown-toggle,
  .pagination li .show > span.dropdown-toggle,
  .pagination li .show > a.dropdown-toggle {
    color: #fff;
    background-color: #017571;
    border-color: #016965; }
    .btn-info:not([disabled]):not(.disabled):active:focus, .pagination li span:not([disabled]):not(.disabled):active:focus,
    .pagination li a:not([disabled]):not(.disabled):active:focus, .btn-info:not([disabled]):not(.disabled).active:focus, .pagination li span:not([disabled]):not(.disabled).active:focus,
    .pagination li a:not([disabled]):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus,
    .pagination li .show > span.dropdown-toggle:focus,
    .pagination li .show > a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(1, 168, 162, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not([disabled]):not(.disabled):active:focus, .btn-warning:not([disabled]):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f; }
  .btn-danger:hover {
    color: #fff;
    background-color: #d23430;
    border-color: #c9302c; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #d9534f;
    border-color: #d9534f; }
  .btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    border-color: #bf2e29; }
    .btn-danger:not([disabled]):not(.disabled):active:focus, .btn-danger:not([disabled]):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }

.btn-light {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef; }
  .btn-light:hover {
    color: #212529;
    background-color: #d3d9df;
    border-color: #cbd3da; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #e9ecef;
    border-color: #e9ecef; }
  .btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #cbd3da;
    border-color: #c4ccd4; }
    .btn-light:not([disabled]):not(.disabled):active:focus, .btn-light:not([disabled]):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not([disabled]):not(.disabled):active:focus, .btn-dark:not([disabled]):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-secondary {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #000; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #000;
    background-color: transparent; }
  .btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #000;
    border-color: #000;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #28a745;
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #01A8A2;
  background-color: transparent;
  background-image: none;
  border-color: #01A8A2; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #01A8A2;
    border-color: #01A8A2; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 168, 162, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #01A8A2;
    background-color: transparent; }
  .btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #01A8A2;
    border-color: #01A8A2;
    box-shadow: 0 0 0 0.2rem rgba(1, 168, 162, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #d9534f;
  background-color: transparent;
  background-image: none;
  border-color: #d9534f; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d9534f;
    background-color: transparent; }
  .btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #212529;
    background-color: #d9534f;
    border-color: #d9534f;
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }

.btn-outline-light {
  color: #e9ecef;
  background-color: transparent;
  background-image: none;
  border-color: #e9ecef; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #e9ecef;
    background-color: transparent; }
  .btn-outline-light:not([disabled]):not(.disabled):active, .btn-outline-light:not([disabled]):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #e9ecef;
    border-color: #e9ecef;
    box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not([disabled]):not(.disabled):active, .btn-outline-dark:not([disabled]):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #212529;
    background-color: #343a40;
    border-color: #343a40;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #495057;
  background-color: transparent; }
  .btn-link:hover {
    color: #495057;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #868e96; }

.btn-lg, .btn-group-lg > .btn, .pagination li .btn-group-lg > span,
.pagination li .btn-group-lg > a {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn, .pagination li .btn-group-sm > span,
.pagination li .btn-group-sm > a {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15); }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.46875rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #ffc107; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #868e96;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #868e96;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .pagination li .btn-group > span,
  .pagination li .btn-group > a,
  .btn-group-vertical > .btn,
  .pagination li .btn-group-vertical > span,
  .pagination li .btn-group-vertical > a {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover, .pagination li .btn-group > span:hover,
    .pagination li .btn-group > a:hover,
    .btn-group-vertical > .btn:hover,
    .pagination li .btn-group-vertical > span:hover,
    .pagination li .btn-group-vertical > a:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .pagination li .btn-group > span:focus,
    .pagination li .btn-group > a:focus, .btn-group > .btn:active, .pagination li .btn-group > span:active,
    .pagination li .btn-group > a:active, .btn-group > .btn.active, .pagination li .btn-group > span.active,
    .pagination li .btn-group > a.active,
    .btn-group-vertical > .btn:focus,
    .pagination li .btn-group-vertical > span:focus,
    .pagination li .btn-group-vertical > a:focus,
    .btn-group-vertical > .btn:active,
    .pagination li .btn-group-vertical > span:active,
    .pagination li .btn-group-vertical > a:active,
    .btn-group-vertical > .btn.active,
    .pagination li .btn-group-vertical > span.active,
    .pagination li .btn-group-vertical > a.active {
      z-index: 1; }
  .btn-group .btn + .btn, .btn-group .pagination li span + .btn, .pagination li .btn-group span + .btn, .btn-group .pagination li a + .btn, .pagination li .btn-group a + .btn, .btn-group .pagination li .btn + span, .pagination li .btn-group .btn + span, .btn-group .pagination li span + span, .pagination li .btn-group span + span, .btn-group .pagination li a + span, .pagination li .btn-group a + span,
  .btn-group .pagination li .btn + a, .pagination li .btn-group .btn + a,
  .btn-group .pagination li span + a, .pagination li .btn-group span + a,
  .btn-group .pagination li a + a, .pagination li .btn-group a + a,
  .btn-group .btn + .btn-group,
  .btn-group .pagination li span + .btn-group,
  .pagination li .btn-group span + .btn-group,
  .btn-group .pagination li a + .btn-group,
  .pagination li .btn-group a + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .pagination li .btn-group + span,
  .pagination li .btn-group .btn-group + span,
  .btn-group .pagination li .btn-group + a,
  .pagination li .btn-group .btn-group + a,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .pagination li span + .btn,
  .pagination li .btn-group-vertical span + .btn,
  .btn-group-vertical .pagination li a + .btn,
  .pagination li .btn-group-vertical a + .btn,
  .btn-group-vertical .pagination li .btn + span,
  .pagination li .btn-group-vertical .btn + span,
  .btn-group-vertical .pagination li span + span,
  .pagination li .btn-group-vertical span + span,
  .btn-group-vertical .pagination li a + span,
  .pagination li .btn-group-vertical a + span,
  .btn-group-vertical .pagination li .btn + a,
  .pagination li .btn-group-vertical .btn + a,
  .btn-group-vertical .pagination li span + a,
  .pagination li .btn-group-vertical span + a,
  .btn-group-vertical .pagination li a + a,
  .pagination li .btn-group-vertical a + a,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .pagination li span + .btn-group,
  .pagination li .btn-group-vertical span + .btn-group,
  .btn-group-vertical .pagination li a + .btn-group,
  .pagination li .btn-group-vertical a + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .pagination li .btn-group + span,
  .pagination li .btn-group-vertical .btn-group + span,
  .btn-group-vertical .pagination li .btn-group + a,
  .pagination li .btn-group-vertical .btn-group + a,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child, .pagination li .btn-group > span:first-child,
.pagination li .btn-group > a:first-child {
  margin-left: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .pagination li .btn-group-sm > span + .dropdown-toggle-split, .pagination li .btn-group-sm > a + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .pagination li .btn-group-lg > span + .dropdown-toggle-split, .pagination li .btn-group-lg > a + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn, .btn-group-vertical .pagination li span, .pagination li .btn-group-vertical span,
  .btn-group-vertical .pagination li a, .pagination li .btn-group-vertical a,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn, .pagination li .btn-group-vertical > span + .btn, .pagination li .btn-group-vertical > a + .btn, .pagination li .btn-group-vertical > .btn + span, .pagination li .btn-group-vertical > span + span, .pagination li .btn-group-vertical > a + span,
  .pagination li .btn-group-vertical > .btn + a,
  .pagination li .btn-group-vertical > span + a,
  .pagination li .btn-group-vertical > a + a,
  .btn-group-vertical > .btn + .btn-group,
  .pagination li .btn-group-vertical > span + .btn-group,
  .pagination li .btn-group-vertical > a + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .pagination li .btn-group-vertical > .btn-group + span,
  .pagination li .btn-group-vertical > .btn-group + a,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }

.btn-group-toggle > .btn, .pagination li .btn-group-toggle > span,
.pagination li .btn-group-toggle > a,
.btn-group-toggle > .btn-group > .btn,
.pagination li .btn-group-toggle > .btn-group > span,
.pagination li .btn-group-toggle > .btn-group > a {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .pagination li .btn-group-toggle > span input[type="radio"], .pagination li .btn-group-toggle > a input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .pagination li .btn-group-toggle > span input[type="checkbox"],
  .pagination li .btn-group-toggle > a input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .pagination li .btn-group-toggle > .btn-group > span input[type="radio"],
  .pagination li .btn-group-toggle > .btn-group > a input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .pagination li .btn-group-toggle > .btn-group > span input[type="checkbox"],
  .pagination li .btn-group-toggle > .btn-group > a input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group .form-control,
  .input-group .custom-select,
  .input-group .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group .form-control:focus,
    .input-group .custom-select:focus,
    .input-group .custom-file:focus {
      z-index: 3; }
    .input-group .form-control + .form-control,
    .input-group .custom-select + .form-control,
    .input-group .custom-file + .form-control {
      margin-left: -1px; }
  .input-group .custom-file {
    display: flex;
    align-items: center; }

.input-group-prepend,
.input-group-append {
  display: flex;
  align-items: center; }
  .input-group-prepend .btn, .input-group-prepend .pagination li span, .pagination li .input-group-prepend span,
  .input-group-prepend .pagination li a, .pagination li .input-group-prepend a,
  .input-group-append .btn,
  .input-group-append .pagination li span,
  .pagination li .input-group-append span,
  .input-group-append .pagination li a,
  .pagination li .input-group-append a {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn, .input-group-prepend .pagination li span + .btn, .pagination li .input-group-prepend span + .btn, .input-group-prepend .pagination li a + .btn, .pagination li .input-group-prepend a + .btn, .input-group-prepend .pagination li .btn + span, .pagination li .input-group-prepend .btn + span, .input-group-prepend .pagination li span + span, .pagination li .input-group-prepend span + span, .input-group-prepend .pagination li a + span, .pagination li .input-group-prepend a + span,
  .input-group-prepend .pagination li .btn + a, .pagination li .input-group-prepend .btn + a,
  .input-group-prepend .pagination li span + a, .pagination li .input-group-prepend span + a,
  .input-group-prepend .pagination li a + a, .pagination li .input-group-prepend a + a,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .pagination li span + .input-group-text,
  .pagination li .input-group-prepend span + .input-group-text,
  .input-group-prepend .pagination li a + .input-group-text,
  .pagination li .input-group-prepend a + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .pagination li .input-group-text + span,
  .pagination li .input-group-prepend .input-group-text + span,
  .input-group-prepend .pagination li .input-group-text + a,
  .pagination li .input-group-prepend .input-group-text + a,
  .input-group-append .btn + .btn,
  .input-group-append .pagination li span + .btn,
  .pagination li .input-group-append span + .btn,
  .input-group-append .pagination li a + .btn,
  .pagination li .input-group-append a + .btn,
  .input-group-append .pagination li .btn + span,
  .pagination li .input-group-append .btn + span,
  .input-group-append .pagination li span + span,
  .pagination li .input-group-append span + span,
  .input-group-append .pagination li a + span,
  .pagination li .input-group-append a + span,
  .input-group-append .pagination li .btn + a,
  .pagination li .input-group-append .btn + a,
  .input-group-append .pagination li span + a,
  .pagination li .input-group-append span + a,
  .input-group-append .pagination li a + a,
  .pagination li .input-group-append a + a,
  .input-group-append .btn + .input-group-text,
  .input-group-append .pagination li span + .input-group-text,
  .pagination li .input-group-append span + .input-group-text,
  .input-group-append .pagination li a + .input-group-text,
  .pagination li .input-group-append a + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .pagination li .input-group-text + span,
  .pagination li .input-group-append .input-group-text + span,
  .input-group-append .pagination li .input-group-text + a,
  .pagination li .input-group-append .input-group-text + a {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.4rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #ffc107; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 193, 7, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #ffeeba; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #868e96; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.2rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.2rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #ffc107; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.15rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.4;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #6d7a86;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 193, 7, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #868e96;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.625rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.5rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.15rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.15rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #6d7a86;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #6d7a86; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.15rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.4;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.15rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.4;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #868e96; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #868e96;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ffc107; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.46875rem 0.9375rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 0.9375rem;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:not([disabled]):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 359.99px) {
  .navbar-expand-xs > .container,
  .navbar-expand-xs > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 360px) {
  .navbar-expand-xs {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xs .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xs .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xs .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xs .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-xs > .container,
    .navbar-expand-xs > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xs .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xs .navbar-toggler {
      display: none; }
    .navbar-expand-xs .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 479.99px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 480px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.99px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.99px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.99px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: #fff; }
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: #fff; }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%; }

.card-img-top {
  width: 100%; }

.card-img-bottom {
  width: 100%; }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 480px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 480px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 480px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #868e96;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #868e96; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:focus, .page-link:hover {
    color: #495057;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:not([disabled]):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }
  .badge:empty {
    display: none; }

.btn .badge, .pagination li span .badge, .pagination li a .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em; }

.badge-primary {
  color: #212529;
  background-color: #ffc107; }
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-secondary {
  color: #fff;
  background-color: #000; }
  .badge-secondary[href]:focus, .badge-secondary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: black; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:focus, .badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #01A8A2; }
  .badge-info[href]:focus, .badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #017571; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:focus, .badge-warning[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #d9534f; }
  .badge-danger[href]:focus, .badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #c9302c; }

.badge-light {
  color: #212529;
  background-color: #e9ecef; }
  .badge-light[href]:focus, .badge-light[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #cbd3da; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:focus, .badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef; }
  @media (min-width: 480px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-primary hr {
    border-top-color: #ffe8a1; }
  .alert-primary .alert-link {
    color: #533f03; }

.alert-secondary {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8; }
  .alert-secondary hr {
    border-top-color: #ababab; }
  .alert-secondary .alert-link {
    color: black; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #015754;
  background-color: #cceeec;
  border-color: #b8e7e5; }
  .alert-info hr {
    border-top-color: #a5e1de; }
  .alert-info .alert-link {
    color: #002523; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #712b29;
  background-color: #f7dddc;
  border-color: #f4cfce; }
  .alert-danger hr {
    border-top-color: #efbbb9; }
  .alert-danger .alert-link {
    color: #4c1d1b; }

.alert-light {
  color: #797b7c;
  background-color: #fbfbfc;
  border-color: #f9fafb; }
  .alert-light hr {
    border-top-color: #eaedf1; }
  .alert-light .alert-link {
    color: #606162; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #ffc107;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:focus, .list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #000;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:last-child {
    margin-bottom: 0; }
  .list-group-item:focus, .list-group-item:hover {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #868e96;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #856404;
  background-color: #ffeeba; }

a.list-group-item-primary,
button.list-group-item-primary {
  color: #856404; }
  a.list-group-item-primary:focus, a.list-group-item-primary:hover,
  button.list-group-item-primary:focus,
  button.list-group-item-primary:hover {
    color: #856404;
    background-color: #ffe8a1; }
  a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-secondary {
  color: black;
  background-color: #b8b8b8; }

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: black; }
  a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
  button.list-group-item-secondary:focus,
  button.list-group-item-secondary:hover {
    color: black;
    background-color: #ababab; }
  a.list-group-item-secondary.active,
  button.list-group-item-secondary.active {
    color: #fff;
    background-color: black;
    border-color: black; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

a.list-group-item-success,
button.list-group-item-success {
  color: #155724; }
  a.list-group-item-success:focus, a.list-group-item-success:hover,
  button.list-group-item-success:focus,
  button.list-group-item-success:hover {
    color: #155724;
    background-color: #b1dfbb; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #015754;
  background-color: #b8e7e5; }

a.list-group-item-info,
button.list-group-item-info {
  color: #015754; }
  a.list-group-item-info:focus, a.list-group-item-info:hover,
  button.list-group-item-info:focus,
  button.list-group-item-info:hover {
    color: #015754;
    background-color: #a5e1de; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    color: #fff;
    background-color: #015754;
    border-color: #015754; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856404; }
  a.list-group-item-warning:focus, a.list-group-item-warning:hover,
  button.list-group-item-warning:focus,
  button.list-group-item-warning:hover {
    color: #856404;
    background-color: #ffe8a1; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #712b29;
  background-color: #f4cfce; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #712b29; }
  a.list-group-item-danger:focus, a.list-group-item-danger:hover,
  button.list-group-item-danger:focus,
  button.list-group-item-danger:hover {
    color: #712b29;
    background-color: #efbbb9; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    color: #fff;
    background-color: #712b29;
    border-color: #712b29; }

.list-group-item-light {
  color: #797b7c;
  background-color: #f9fafb; }

a.list-group-item-light,
button.list-group-item-light {
  color: #797b7c; }
  a.list-group-item-light:focus, a.list-group-item-light:hover,
  button.list-group-item-light:focus,
  button.list-group-item-light:hover {
    color: #797b7c;
    background-color: #eaedf1; }
  a.list-group-item-light.active,
  button.list-group-item-light.active {
    color: #fff;
    background-color: #797b7c;
    border-color: #797b7c; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21; }
  a.list-group-item-dark:focus, a.list-group-item-dark:hover,
  button.list-group-item-dark:focus,
  button.list-group-item-dark:hover {
    color: #1b1e21;
    background-color: #b9bbbe; }
  a.list-group-item-dark.active,
  button.list-group-item-dark.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not([disabled]):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.4; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 480px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #000; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:focus, .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-next:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #ffc107 !important; }

a.bg-primary:focus, a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #d39e00 !important; }

.bg-secondary {
  background-color: #000 !important; }

a.bg-secondary:focus, a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: black !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #01A8A2 !important; }

a.bg-info:focus, a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #017571 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #d9534f !important; }

a.bg-danger:focus, a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #c9302c !important; }

.bg-light {
  background-color: #e9ecef !important; }

a.bg-light:focus, a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #cbd3da !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ffc107 !important; }

.border-secondary {
  border-color: #000 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #01A8A2 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #d9534f !important; }

.border-light {
  border-color: #e9ecef !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.375rem !important; }

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important; }

.rounded-right {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.rounded-left {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 360px) {
  .d-xs-none {
    display: none !important; }
  .d-xs-inline {
    display: inline !important; }
  .d-xs-inline-block {
    display: inline-block !important; }
  .d-xs-block {
    display: block !important; }
  .d-xs-table {
    display: table !important; }
  .d-xs-table-row {
    display: table-row !important; }
  .d-xs-table-cell {
    display: table-cell !important; }
  .d-xs-flex {
    display: flex !important; }
  .d-xs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 480px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 360px) {
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 480px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 360px) {
  .float-xs-left {
    float: left !important; }
  .float-xs-right {
    float: right !important; }
  .float-xs-none {
    float: none !important; } }

@media (min-width: 480px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.23438rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.23438rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.23438rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.23438rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.23438rem !important; }

.m-2 {
  margin: 0.46875rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.46875rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.46875rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.46875rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.46875rem !important; }

.m-3 {
  margin: 0.9375rem !important; }

.mt-3,
.my-3 {
  margin-top: 0.9375rem !important; }

.mr-3,
.mx-3 {
  margin-right: 0.9375rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 0.9375rem !important; }

.ml-3,
.mx-3 {
  margin-left: 0.9375rem !important; }

.m-4 {
  margin: 1.40625rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.40625rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.40625rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.40625rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.40625rem !important; }

.m-5 {
  margin: 2.8125rem !important; }

.mt-5,
.my-5 {
  margin-top: 2.8125rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2.8125rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2.8125rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2.8125rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.23438rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.23438rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.23438rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.23438rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.23438rem !important; }

.p-2 {
  padding: 0.46875rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.46875rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.46875rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.46875rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.46875rem !important; }

.p-3 {
  padding: 0.9375rem !important; }

.pt-3,
.py-3 {
  padding-top: 0.9375rem !important; }

.pr-3,
.px-3 {
  padding-right: 0.9375rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 0.9375rem !important; }

.pl-3,
.px-3 {
  padding-left: 0.9375rem !important; }

.p-4 {
  padding: 1.40625rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.40625rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.40625rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.40625rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.40625rem !important; }

.p-5 {
  padding: 2.8125rem !important; }

.pt-5,
.py-5 {
  padding-top: 2.8125rem !important; }

.pr-5,
.px-5 {
  padding-right: 2.8125rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2.8125rem !important; }

.pl-5,
.px-5 {
  padding-left: 2.8125rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 360px) {
  .m-xs-0 {
    margin: 0 !important; }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important; }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important; }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important; }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important; }
  .m-xs-1 {
    margin: 0.23438rem !important; }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.23438rem !important; }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.23438rem !important; }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.23438rem !important; }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.23438rem !important; }
  .m-xs-2 {
    margin: 0.46875rem !important; }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.46875rem !important; }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.46875rem !important; }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.46875rem !important; }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.46875rem !important; }
  .m-xs-3 {
    margin: 0.9375rem !important; }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 0.9375rem !important; }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 0.9375rem !important; }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 0.9375rem !important; }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 0.9375rem !important; }
  .m-xs-4 {
    margin: 1.40625rem !important; }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.40625rem !important; }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.40625rem !important; }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.40625rem !important; }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.40625rem !important; }
  .m-xs-5 {
    margin: 2.8125rem !important; }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 2.8125rem !important; }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 2.8125rem !important; }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 2.8125rem !important; }
  .p-xs-0 {
    padding: 0 !important; }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important; }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important; }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important; }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important; }
  .p-xs-1 {
    padding: 0.23438rem !important; }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.23438rem !important; }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.23438rem !important; }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.23438rem !important; }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.23438rem !important; }
  .p-xs-2 {
    padding: 0.46875rem !important; }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.46875rem !important; }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.46875rem !important; }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.46875rem !important; }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.46875rem !important; }
  .p-xs-3 {
    padding: 0.9375rem !important; }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 0.9375rem !important; }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 0.9375rem !important; }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 0.9375rem !important; }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 0.9375rem !important; }
  .p-xs-4 {
    padding: 1.40625rem !important; }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.40625rem !important; }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.40625rem !important; }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.40625rem !important; }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.40625rem !important; }
  .p-xs-5 {
    padding: 2.8125rem !important; }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 2.8125rem !important; }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 2.8125rem !important; }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 2.8125rem !important; }
  .m-xs-auto {
    margin: auto !important; }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important; }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important; }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important; }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important; } }

@media (min-width: 480px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.23438rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.23438rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.23438rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.23438rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.23438rem !important; }
  .m-sm-2 {
    margin: 0.46875rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.46875rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.46875rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.46875rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.46875rem !important; }
  .m-sm-3 {
    margin: 0.9375rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.9375rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.9375rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.9375rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.9375rem !important; }
  .m-sm-4 {
    margin: 1.40625rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.40625rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.40625rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.40625rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.40625rem !important; }
  .m-sm-5 {
    margin: 2.8125rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2.8125rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2.8125rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2.8125rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.23438rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.23438rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.23438rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.23438rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.23438rem !important; }
  .p-sm-2 {
    padding: 0.46875rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.46875rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.46875rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.46875rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.46875rem !important; }
  .p-sm-3 {
    padding: 0.9375rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.9375rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.9375rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.9375rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.9375rem !important; }
  .p-sm-4 {
    padding: 1.40625rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.40625rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.40625rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.40625rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.40625rem !important; }
  .p-sm-5 {
    padding: 2.8125rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2.8125rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2.8125rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2.8125rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.23438rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.23438rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.23438rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.23438rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.23438rem !important; }
  .m-md-2 {
    margin: 0.46875rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.46875rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.46875rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.46875rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.46875rem !important; }
  .m-md-3 {
    margin: 0.9375rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.9375rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.9375rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.9375rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.9375rem !important; }
  .m-md-4 {
    margin: 1.40625rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.40625rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.40625rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.40625rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.40625rem !important; }
  .m-md-5 {
    margin: 2.8125rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2.8125rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2.8125rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2.8125rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.23438rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.23438rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.23438rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.23438rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.23438rem !important; }
  .p-md-2 {
    padding: 0.46875rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.46875rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.46875rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.46875rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.46875rem !important; }
  .p-md-3 {
    padding: 0.9375rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.9375rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 0.9375rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.9375rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 0.9375rem !important; }
  .p-md-4 {
    padding: 1.40625rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.40625rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.40625rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.40625rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.40625rem !important; }
  .p-md-5 {
    padding: 2.8125rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2.8125rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2.8125rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2.8125rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.23438rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.23438rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.23438rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.23438rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.23438rem !important; }
  .m-lg-2 {
    margin: 0.46875rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.46875rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.46875rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.46875rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.46875rem !important; }
  .m-lg-3 {
    margin: 0.9375rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.9375rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.9375rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.9375rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.9375rem !important; }
  .m-lg-4 {
    margin: 1.40625rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.40625rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.40625rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.40625rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.40625rem !important; }
  .m-lg-5 {
    margin: 2.8125rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2.8125rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2.8125rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2.8125rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.23438rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.23438rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.23438rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.23438rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.23438rem !important; }
  .p-lg-2 {
    padding: 0.46875rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.46875rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.46875rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.46875rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.46875rem !important; }
  .p-lg-3 {
    padding: 0.9375rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.9375rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.9375rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.9375rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.9375rem !important; }
  .p-lg-4 {
    padding: 1.40625rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.40625rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.40625rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.40625rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.40625rem !important; }
  .p-lg-5 {
    padding: 2.8125rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2.8125rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2.8125rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2.8125rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.23438rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.23438rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.23438rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.23438rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.23438rem !important; }
  .m-xl-2 {
    margin: 0.46875rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.46875rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.46875rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.46875rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.46875rem !important; }
  .m-xl-3 {
    margin: 0.9375rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.9375rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.9375rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.9375rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.9375rem !important; }
  .m-xl-4 {
    margin: 1.40625rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.40625rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.40625rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.40625rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.40625rem !important; }
  .m-xl-5 {
    margin: 2.8125rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2.8125rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2.8125rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2.8125rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.23438rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.23438rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.23438rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.23438rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.23438rem !important; }
  .p-xl-2 {
    padding: 0.46875rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.46875rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.46875rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.46875rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.46875rem !important; }
  .p-xl-3 {
    padding: 0.9375rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.9375rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.9375rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.9375rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.9375rem !important; }
  .p-xl-4 {
    padding: 1.40625rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.40625rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.40625rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.40625rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.40625rem !important; }
  .p-xl-5 {
    padding: 2.8125rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2.8125rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2.8125rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2.8125rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 360px) {
  .text-xs-left {
    text-align: left !important; }
  .text-xs-right {
    text-align: right !important; }
  .text-xs-center {
    text-align: center !important; } }

@media (min-width: 480px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #ffc107 !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #d39e00 !important; }

.text-secondary {
  color: #000 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: black !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #01A8A2 !important; }

a.text-info:focus, a.text-info:hover {
  color: #017571 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #d9534f !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #c9302c !important; }

.text-light {
  color: #e9ecef !important; }

a.text-light:focus, a.text-light:hover {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10042;
  overflow: hidden;
  position: fixed;
  background: #212529;
  opacity: 0.99; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 10045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 10044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 10046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 5px;
  top: 2px;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 20px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/* nunito-sans-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/nunito-sans-v15-latin-300.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* nunito-sans-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/nunito-sans-v15-latin-regular.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* nunito-sans-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/nunito-sans-v15-latin-italic.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* nunito-sans-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/nunito-sans-v15-latin-500.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* nunito-sans-500italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/nunito-sans-v15-latin-500italic.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* nunito-sans-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/nunito-sans-v15-latin-600.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* nunito-sans-600italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/nunito-sans-v15-latin-600italic.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* nunito-sans-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/nunito-sans-v15-latin-700.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* nunito-sans-700italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/nunito-sans-v15-latin-700italic.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

body {
  background-color: #eaeaea; }

.img-sidebar-header {
  position: relative;
  margin-bottom: -9px;
  margin-bottom: -0.5625rem;
  z-index: 2; }

.note-float-left {
  margin-right: 20px;
  margin-right: 1.25rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  height: auto !important; }

.note-float-right {
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  height: auto !important; }

.content-distance {
  margin-bottom: 70px;
  margin-bottom: 4.375rem; }

.cursor-default {
  cursor: default !important; }

.cursor-pointer {
  cursor: pointer !important; }

.cursor-help {
  cursor: help !important; }

.text-gray-100 {
  color: #f8f9fa !important; }

.text-gray-200 {
  color: #e9ecef !important; }

.text-gray-300 {
  color: #dee2e6 !important; }

.text-gray-400 {
  color: #ced4da !important; }

.text-gray-500 {
  color: #adb5bd !important; }

.text-gray-600 {
  color: #868e96 !important; }

.text-gray-700 {
  color: #495057 !important; }

.text-gray-800 {
  color: #343a40 !important; }

.text-gray-900 {
  color: #212529 !important; }

.text-yellow {
  color: #ffc107 !important; }

.text-blue {
  color: #00ace9 !important; }

.text-red {
  color: #d9534f !important; }

.text-green {
  color: #28a745 !important; }

.bg-gray-100 {
  background: #f8f9fa !important; }

.bg-gray-200 {
  background: #e9ecef !important; }

.bg-gray-300 {
  background: #dee2e6 !important; }

.bg-gray-400 {
  background: #ced4da !important; }

.bg-gray-500 {
  background: #adb5bd !important; }

.bg-gray-600 {
  background: #868e96 !important; }

.bg-gray-700 {
  background: #495057 !important; }

.bg-gray-800 {
  background: #343a40 !important; }

.bg-gray-900 {
  background: #212529 !important; }

.bg-yellow {
  background: #ffc107 !important; }

.bg-blue {
  background: #00ace9 !important; }

.bg-red {
  background: #d9534f !important; }

h1 .h1 {
  font-size: 26px;
  font-size: 1.625rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  font-weight: 600; }

h2, .h2 {
  font-size: 22px;
  font-size: 1.375rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem; }

h3, .h3 {
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem; }

hr {
  border-color: #adb5bd; }

a {
  color: #000; }
  a:hover {
    text-decoration: underline; }
  a.text-decoration-none {
    text-decoration: none !important; }

.section-content-white a {
  text-decoration: underline; }

.section-content-white .content-landingpage a {
  color: #00ace9; }

/* grid layout, 3 columns */
.wrapper {
  display: grid;
  /* 1 fraction */
  grid-template-columns: 1fr 1fr 1fr; }

.box {
  background-color: #868e96;
  border: 1px solid black; }

.navbar-dark {
  padding: 0;
  background-color: #000; }
  .navbar-dark .navbar-brand {
    padding: 0; }
    .navbar-dark .navbar-brand img {
      padding: 2px 0;
      padding: 0.125rem 0;
      height: 40px; }
  .navbar-dark .navbar-nav .nav-item {
    padding: 5px;
    padding: 0.3125rem;
    border: solid #343a40;
    border-width: 0 1px 0 0; }
    .navbar-dark .navbar-nav .nav-item:first-of-type {
      border-width: 0 1px 0 1px; }
    .navbar-dark .navbar-nav .nav-item.active {
      background-color: #343a40; }
  .navbar-dark .navbar-nav .nav-link {
    color: #fff; }

.btn, .pagination li span,
.pagination li a {
  text-decoration: none !important; }

.btn-primary {
  color: #fff; }
  .btn-primary:hover {
    color: #fff; }

.pagination a {
  text-decoration: none; }

.control-label sup {
  font-size: 100%;
  color: #d9534f;
  top: -2px;
  top: -0.125rem; }

.form-control {
  border: 1px solid #868e96; }
  .form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }

.section-video-preview {
  display: block;
  text-decoration: none !important;
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }
  .section-video-preview .card {
    border-radius: 10px;
    background-color: #000; }
  .section-video-preview .card-image-container {
    position: relative;
    border: solid #5d5d5d;
    border-width: 0 0 1px 0; }
    .section-video-preview .card-image-container img {
      border-radius: 10px 10px 0 0; }
    .section-video-preview .card-image-container:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.1;
      border-radius: 10px 10px 0 0; }
    .section-video-preview .card-image-container:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: FontAwesome;
      color: #fff;
      font-size: 50px;
      font-size: 3.125rem;
      content: "\f16a"; }
  .section-video-preview .card-body .card-text {
    color: white;
    font-weight: 600; }
  .section-video-preview:hover .card-image-container:before {
    opacity: 0.5; }

.section-home-hero .section-home-hero-content {
  background-color: #7b7f98; }

@media (min-width: 992px) {
  .section-home-hero .section-home-hero-bg-image {
    background: url("../images/bg-home-hero-desktop-v2.jpg") no-repeat;
    height: calc(100vh - 81px);
    background-position-y: -70px;
    background-size: cover;
    min-height: 850px; } }

.section-home-hero .section-home-hero-arrow {
  position: relative;
  height: 40px; }
  .section-home-hero .section-home-hero-arrow:after {
    top: 0;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #7b7f98;
    border-width: 40px;
    margin-left: -40px; }

.section-home-hero .home-youtube-box {
  text-align: center;
  margin-top: 50px;
  margin-top: 3.125rem;
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }
  .section-home-hero .home-youtube-box p {
    color: white;
    font-size: 14px;
    font-size: 0.875rem; }

.section-home-hero .section-tiles {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  padding-bottom: 1.875rem; }
  .section-home-hero .section-tiles .tile-box {
    width: calc(100% - 8px);
    color: white;
    background-color: rgba(255, 255, 255, 0.18);
    height: 230px;
    margin: 4px 4px;
    margin: 0.25rem 0.25rem;
    border-radius: 10px;
    border-radius: 0.625rem; }
    @media (min-width: 350px) {
      .section-home-hero .section-tiles .tile-box {
        width: calc(50% - 8px); } }
    @media (min-width: 992px) {
      .section-home-hero .section-tiles .tile-box {
        width: calc(25% - 8px); } }
    @media (min-width: 1200px) {
      .section-home-hero .section-tiles .tile-box {
        width: calc(12.5% - 8px); } }
    .section-home-hero .section-tiles .tile-box img {
      transition: transform 0.2s ease-in; }
    .section-home-hero .section-tiles .tile-box p {
      margin-bottom: 0;
      text-transform: uppercase; }
    .section-home-hero .section-tiles .tile-box:hover {
      background-color: #01a8a2;
      text-decoration: none; }
      .section-home-hero .section-tiles .tile-box:hover img {
        transform: scaleX(-1); }
      .section-home-hero .section-tiles .tile-box:hover p {
        color: #fff;
        text-decoration: none; }

.section-home-hero .tile-old-logo {
  color: #fff;
  background-color: #212529;
  border: 1px solid #fff;
  height: 370px; }

.section-home-hero .tile-old-box {
  color: white;
  background-color: #212529;
  border: 1px solid #fff;
  height: 185px; }

.section-content-black {
  padding: 30px 0;
  padding: 1.875rem 0;
  min-height: 400px;
  background-color: #000; }

.section-content-white {
  min-height: 400px;
  border-radius: 10px;
  border-radius: 0.625rem; }
  .section-content-white .content-inner {
    position: relative;
    padding: 20px;
    padding: 1.25rem;
    background-color: white; }
    .section-content-white .content-inner .content-headline {
      font-size: 24px;
      font-size: 1.5rem; }
    .section-content-white .content-inner .content-calendar {
      padding-bottom: 20px;
      padding-bottom: 1.25rem; }
      .section-content-white .content-inner .content-calendar i {
        margin-right: 5px;
        margin-right: 0.3125rem; }
      .section-content-white .content-inner .content-calendar span {
        font-size: 14px;
        font-size: 0.875rem;
        font-weight: 300; }
    .section-content-white .content-inner .content-teaser {
      font-size: 18px;
      font-size: 1.125rem; }
  .section-content-white .content-inner-expanded {
    padding: 70px 20px 20px 20px;
    padding: 4.375rem 1.25rem 1.25rem 1.25rem;
    background-color: white;
    border-radius: 0 0 10px 10px; }
  .section-content-white.has-image a {
    color: white;
    text-decoration: underline; }
    .section-content-white.has-image a:hover {
      color: white; }
  .section-content-white.has-image .news-image-box {
    position: relative; }
    .section-content-white.has-image .news-image-box:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), black); }
    .section-content-white.has-image .news-image-box img {
      border-radius: 10px 10px 0 0;
      border-radius: 0.625rem 0.625rem 0 0; }
  .section-content-white.has-image .content-inner {
    margin-top: -30px;
    margin-top: -1.875rem;
    background-color: #000;
    color: white;
    padding-bottom: 10px;
    padding-bottom: 0.625rem; }
    .section-content-white.has-image .content-inner:after {
      content: "";
      position: absolute;
      bottom: -50px;
      left: 0;
      width: 100%;
      height: 50px;
      background-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0)); }

.section-news-overview {
  border-radius: 10px;
  border-radius: 0.625rem; }
  .section-news-overview .news-content-box {
    padding: 20px;
    padding: 1.25rem;
    position: relative; }
    .section-news-overview .news-content-box .news-headline a:hover {
      text-decoration: underline;
      color: #000; }
    .section-news-overview .news-content-box .news-calendar {
      padding-bottom: 20px;
      padding-bottom: 1.25rem; }
      .section-news-overview .news-content-box .news-calendar i {
        margin-right: 5px;
        margin-right: 0.3125rem; }
      .section-news-overview .news-content-box .news-calendar span {
        font-size: 14px;
        font-size: 0.875rem;
        font-weight: 300; }
    .section-news-overview .news-content-box .news-teaser {
      font-size: 14px;
      font-size: 0.875rem;
      margin-bottom: 10px;
      margin-bottom: 0.625rem; }
  .section-news-overview.has-image {
    background-color: #000;
    color: white; }
    .section-news-overview.has-image a {
      color: white;
      text-decoration: underline; }
      .section-news-overview.has-image a:hover {
        color: white; }
    .section-news-overview.has-image .news-image-box {
      position: relative; }
      .section-news-overview.has-image .news-image-box:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), black); }
      .section-news-overview.has-image .news-image-box img {
        border-radius: 10px 10px 0 0;
        border-radius: 0.625rem 0.625rem 0 0; }
    .section-news-overview.has-image .news-content-box {
      margin-top: -30px;
      margin-top: -1.875rem;
      padding-top: 0; }
      .section-news-overview.has-image .news-content-box .news-headline a {
        color: white; }
      .section-news-overview.has-image .news-content-box .news-button {
        text-align: right; }
  .section-news-overview:not(.has-image) {
    background-color: white; }

.scrolling-text-outer {
  background-color: #f317ac;
  margin: 50px 0 25px 0;
  margin: 3.125rem 0 1.5625rem 0;
  border-radius: 10px; }
  @media (min-width: 992px) {
    .scrolling-text-outer {
      margin: 0 0 25px 0;
      margin: 0 0 1.5625rem 0; } }
  .scrolling-text-outer .scrolling-text-inner {
    margin: 0 20px;
    margin: 0 1.25rem;
    position: relative;
    overflow: hidden;
    height: 39px; }
    @media (min-width: 768px) {
      .scrolling-text-outer .scrolling-text-inner {
        height: 39px; } }
    .scrolling-text-outer .scrolling-text-inner .scrolling-text {
      position: absolute;
      overflow: auto;
      font-size: 14px;
      font-size: 0.875rem; }
      @media (min-width: 768px) {
        .scrolling-text-outer .scrolling-text-inner .scrolling-text {
          font-size: 16px;
          font-size: 1rem; } }
      .scrolling-text-outer .scrolling-text-inner .scrolling-text div {
        padding: 10px;
        float: left;
        color: white; }
        .scrolling-text-outer .scrolling-text-inner .scrolling-text div a {
          color: white;
          text-decoration: underline; }

.scrolling-sidebar-text {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  padding: 10px;
  padding: 0.625rem;
  border-radius: 10px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  color: white; }
  .scrolling-sidebar-text a {
    color: white;
    text-decoration: underline; }
    .scrolling-sidebar-text a:hover, .scrolling-sidebar-text a:active {
      text-decoration: none; }

footer {
  background-size: cover;
  margin: 40px 0 0 0;
  margin: 2.5rem 0 0 0;
  padding: 50px 0;
  padding: 3.125rem 0;
  color: #fff;
  background: #7b7f98 url("../images/bg-footer-desktop.png") no-repeat center top;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 300; }
  @media (max-width: 1199.99px) {
    footer {
      background: #3c3e4b url("../images/bg-footer-mobile.jpg") no-repeat;
      background-size: contain;
      background-position: center bottom; } }
  footer a {
    color: #fff;
    text-decoration: none; }
    footer a:hover, footer a:focus {
      color: #fff;
      text-decoration: none; }
  footer ul.list-pipe li {
    font-size: 14px;
    font-size: 0.875rem; }
    @media (min-width: 992px) {
      footer ul.list-pipe li {
        font-size: 16px;
        font-size: 1rem; } }
    footer ul.list-pipe li:first-of-type:before {
      content: "";
      margin-right: 0; }
    footer ul.list-pipe li:before {
      content: "|";
      margin-right: 5px;
      margin-right: 0.3125rem; }
  footer ul.list-social-icons a {
    color: white;
    font-size: 40px;
    font-size: 2.5rem; }

.header {
  margin-top: 40px;
  margin-top: 2.5rem; }
  @media (min-width: 992px) {
    .header {
      margin-top: 0; }
      .header .fixed-top {
        position: relative; } }

.text-normal {
  font-weight: 400; }

.text-semibold {
  font-weight: 600; }

.text-bold {
  font-weight: 700; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-nowrap {
  white-space: nowrap; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.mg-0 {
  margin: 0; }

.mgt-0 {
  margin-top: 0; }

.mgb-0 {
  margin-bottom: 0; }

.mgl-0 {
  margin-left: 0; }

.mgr-0 {
  margin-right: 0; }

.pd-0 {
  padding: 0; }

.pdt-0 {
  padding-top: 0; }

.pdb-0 {
  padding-bottom: 0; }

.pdl-0 {
  padding-left: 0; }

.pdr-0 {
  padding-right: 0; }

.mg-5 {
  margin: 5px;
  margin: 0.3125rem; }

.mg-5-0 {
  margin: 5px 0;
  margin: 0.3125rem 0; }

.mg-0-5 {
  margin: 0 5px;
  margin: 0 0.3125rem; }

.mgt-5 {
  margin-top: 5px;
  margin-top: 0.3125rem; }

.mgb-5 {
  margin-bottom: 5px;
  margin-bottom: 0.3125rem; }

.mgl-5 {
  margin-left: 5px;
  margin-left: 0.3125rem; }

.mgr-5 {
  margin-right: 5px;
  margin-right: 0.3125rem; }

.pd-5 {
  padding: 5px;
  padding: 0.3125rem; }

.pd-5-0 {
  padding: 5px 0;
  padding: 0.3125rem 0; }

.pd-0-5 {
  padding: 0 5px;
  padding: 0 0.3125rem; }

.pdt-5 {
  padding-top: 5px;
  padding-top: 0.3125rem; }

.pdb-5 {
  padding-bottom: 5px;
  padding-bottom: 0.3125rem; }

.pdl-5 {
  padding-left: 5px;
  padding-left: 0.3125rem; }

.pdr-5 {
  padding-right: 5px;
  padding-right: 0.3125rem; }

.mg-10 {
  margin: 10px;
  margin: 0.625rem; }

.mg-10-0 {
  margin: 10px 0;
  margin: 0.625rem 0; }

.mg-0-10 {
  margin: 0 10px;
  margin: 0 0.625rem; }

.mgt-10 {
  margin-top: 10px;
  margin-top: 0.625rem; }

.mgb-10 {
  margin-bottom: 10px;
  margin-bottom: 0.625rem; }

.mgl-10 {
  margin-left: 10px;
  margin-left: 0.625rem; }

.mgr-10 {
  margin-right: 10px;
  margin-right: 0.625rem; }

.pd-10 {
  padding: 10px;
  padding: 0.625rem; }

.pd-10-0 {
  padding: 10px 0;
  padding: 0.625rem 0; }

.pd-0-10 {
  padding: 0 10px;
  padding: 0 0.625rem; }

.pdt-10 {
  padding-top: 10px;
  padding-top: 0.625rem; }

.pdb-10 {
  padding-bottom: 10px;
  padding-bottom: 0.625rem; }

.pdl-10 {
  padding-left: 10px;
  padding-left: 0.625rem; }

.pdr-10 {
  padding-right: 10px;
  padding-right: 0.625rem; }

.mg-15 {
  margin: 15px;
  margin: 0.9375rem; }

.mg-15-0 {
  margin: 15px 0;
  margin: 0.9375rem 0; }

.mg-0-15 {
  margin: 0 15px;
  margin: 0 0.9375rem; }

.mgt-15 {
  margin-top: 15px;
  margin-top: 0.9375rem; }

.mgb-15 {
  margin-bottom: 15px;
  margin-bottom: 0.9375rem; }

.mgl-15 {
  margin-left: 15px;
  margin-left: 0.9375rem; }

.mgr-15 {
  margin-right: 15px;
  margin-right: 0.9375rem; }

.pd-15 {
  padding: 15px;
  padding: 0.9375rem; }

.pd-15-0 {
  padding: 15px 0;
  padding: 0.9375rem 0; }

.pd-0-15 {
  padding: 0 15px;
  padding: 0 0.9375rem; }

.pdt-15 {
  padding-top: 15px;
  padding-top: 0.9375rem; }

.pdb-15 {
  padding-bottom: 15px;
  padding-bottom: 0.9375rem; }

.pdl-15 {
  padding-left: 15px;
  padding-left: 0.9375rem; }

.pdr-15 {
  padding-right: 15px;
  padding-right: 0.9375rem; }

.mg-20 {
  margin: 20px;
  margin: 1.25rem; }

.mg-20-0 {
  margin: 20px 0;
  margin: 1.25rem 0; }

.mg-0-20 {
  margin: 0 20px;
  margin: 0 1.25rem; }

.mgt-20 {
  margin-top: 20px;
  margin-top: 1.25rem; }

.mgb-20 {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

.mgl-20 {
  margin-left: 20px;
  margin-left: 1.25rem; }

.mgr-20 {
  margin-right: 20px;
  margin-right: 1.25rem; }

.pd-20 {
  padding: 20px;
  padding: 1.25rem; }

.pd-20-0 {
  padding: 20px 0;
  padding: 1.25rem 0; }

.pd-0-20 {
  padding: 0 20px;
  padding: 0 1.25rem; }

.pdt-20 {
  padding-top: 20px;
  padding-top: 1.25rem; }

.pdb-20 {
  padding-bottom: 20px;
  padding-bottom: 1.25rem; }

.pdl-20 {
  padding-left: 20px;
  padding-left: 1.25rem; }

.pdr-20 {
  padding-right: 20px;
  padding-right: 1.25rem; }

.mg-25 {
  margin: 25px;
  margin: 1.5625rem; }

.mg-25-0 {
  margin: 25px 0;
  margin: 1.5625rem 0; }

.mg-0-25 {
  margin: 0 25px;
  margin: 0 1.5625rem; }

.mgt-25 {
  margin-top: 25px;
  margin-top: 1.5625rem; }

.mgb-25 {
  margin-bottom: 25px;
  margin-bottom: 1.5625rem; }

.mgl-25 {
  margin-left: 25px;
  margin-left: 1.5625rem; }

.mgr-25 {
  margin-right: 25px;
  margin-right: 1.5625rem; }

.pd-25 {
  padding: 25px;
  padding: 1.5625rem; }

.pd-25-0 {
  padding: 25px 0;
  padding: 1.5625rem 0; }

.pd-0-25 {
  padding: 0 25px;
  padding: 0 1.5625rem; }

.pdt-25 {
  padding-top: 25px;
  padding-top: 1.5625rem; }

.pdb-25 {
  padding-bottom: 25px;
  padding-bottom: 1.5625rem; }

.pdl-25 {
  padding-left: 25px;
  padding-left: 1.5625rem; }

.pdr-25 {
  padding-right: 25px;
  padding-right: 1.5625rem; }

.mg-30 {
  margin: 30px;
  margin: 1.875rem; }

.mg-30-0 {
  margin: 30px 0;
  margin: 1.875rem 0; }

.mg-0-30 {
  margin: 0 30px;
  margin: 0 1.875rem; }

.mgt-30 {
  margin-top: 30px;
  margin-top: 1.875rem; }

.mgb-30 {
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }

.mgl-30 {
  margin-left: 30px;
  margin-left: 1.875rem; }

.mgr-30 {
  margin-right: 30px;
  margin-right: 1.875rem; }

.pd-30 {
  padding: 30px;
  padding: 1.875rem; }

.pd-30-0 {
  padding: 30px 0;
  padding: 1.875rem 0; }

.pd-0-30 {
  padding: 0 30px;
  padding: 0 1.875rem; }

.pdt-30 {
  padding-top: 30px;
  padding-top: 1.875rem; }

.pdb-30 {
  padding-bottom: 30px;
  padding-bottom: 1.875rem; }

.pdl-30 {
  padding-left: 30px;
  padding-left: 1.875rem; }

.pdr-30 {
  padding-right: 30px;
  padding-right: 1.875rem; }

.mg-35 {
  margin: 35px;
  margin: 2.1875rem; }

.mg-35-0 {
  margin: 35px 0;
  margin: 2.1875rem 0; }

.mg-0-35 {
  margin: 0 35px;
  margin: 0 2.1875rem; }

.mgt-35 {
  margin-top: 35px;
  margin-top: 2.1875rem; }

.mgb-35 {
  margin-bottom: 35px;
  margin-bottom: 2.1875rem; }

.mgl-35 {
  margin-left: 35px;
  margin-left: 2.1875rem; }

.mgr-35 {
  margin-right: 35px;
  margin-right: 2.1875rem; }

.pd-35 {
  padding: 35px;
  padding: 2.1875rem; }

.pd-35-0 {
  padding: 35px 0;
  padding: 2.1875rem 0; }

.pd-0-35 {
  padding: 0 35px;
  padding: 0 2.1875rem; }

.pdt-35 {
  padding-top: 35px;
  padding-top: 2.1875rem; }

.pdb-35 {
  padding-bottom: 35px;
  padding-bottom: 2.1875rem; }

.pdl-35 {
  padding-left: 35px;
  padding-left: 2.1875rem; }

.pdr-35 {
  padding-right: 35px;
  padding-right: 2.1875rem; }

.mg-40 {
  margin: 40px;
  margin: 2.5rem; }

.mg-40-0 {
  margin: 40px 0;
  margin: 2.5rem 0; }

.mg-0-40 {
  margin: 0 40px;
  margin: 0 2.5rem; }

.mgt-40 {
  margin-top: 40px;
  margin-top: 2.5rem; }

.mgb-40 {
  margin-bottom: 40px;
  margin-bottom: 2.5rem; }

.mgl-40 {
  margin-left: 40px;
  margin-left: 2.5rem; }

.mgr-40 {
  margin-right: 40px;
  margin-right: 2.5rem; }

.pd-40 {
  padding: 40px;
  padding: 2.5rem; }

.pd-40-0 {
  padding: 40px 0;
  padding: 2.5rem 0; }

.pd-0-40 {
  padding: 0 40px;
  padding: 0 2.5rem; }

.pdt-40 {
  padding-top: 40px;
  padding-top: 2.5rem; }

.pdb-40 {
  padding-bottom: 40px;
  padding-bottom: 2.5rem; }

.pdl-40 {
  padding-left: 40px;
  padding-left: 2.5rem; }

.pdr-40 {
  padding-right: 40px;
  padding-right: 2.5rem; }

.mg-45 {
  margin: 45px;
  margin: 2.8125rem; }

.mg-45-0 {
  margin: 45px 0;
  margin: 2.8125rem 0; }

.mg-0-45 {
  margin: 0 45px;
  margin: 0 2.8125rem; }

.mgt-45 {
  margin-top: 45px;
  margin-top: 2.8125rem; }

.mgb-45 {
  margin-bottom: 45px;
  margin-bottom: 2.8125rem; }

.mgl-45 {
  margin-left: 45px;
  margin-left: 2.8125rem; }

.mgr-45 {
  margin-right: 45px;
  margin-right: 2.8125rem; }

.pd-45 {
  padding: 45px;
  padding: 2.8125rem; }

.pd-45-0 {
  padding: 45px 0;
  padding: 2.8125rem 0; }

.pd-0-45 {
  padding: 0 45px;
  padding: 0 2.8125rem; }

.pdt-45 {
  padding-top: 45px;
  padding-top: 2.8125rem; }

.pdb-45 {
  padding-bottom: 45px;
  padding-bottom: 2.8125rem; }

.pdl-45 {
  padding-left: 45px;
  padding-left: 2.8125rem; }

.pdr-45 {
  padding-right: 45px;
  padding-right: 2.8125rem; }

.mg-50 {
  margin: 50px;
  margin: 3.125rem; }

.mg-50-0 {
  margin: 50px 0;
  margin: 3.125rem 0; }

.mg-0-50 {
  margin: 0 50px;
  margin: 0 3.125rem; }

.mgt-50 {
  margin-top: 50px;
  margin-top: 3.125rem; }

.mgb-50 {
  margin-bottom: 50px;
  margin-bottom: 3.125rem; }

.mgl-50 {
  margin-left: 50px;
  margin-left: 3.125rem; }

.mgr-50 {
  margin-right: 50px;
  margin-right: 3.125rem; }

.pd-50 {
  padding: 50px;
  padding: 3.125rem; }

.pd-50-0 {
  padding: 50px 0;
  padding: 3.125rem 0; }

.pd-0-50 {
  padding: 0 50px;
  padding: 0 3.125rem; }

.pdt-50 {
  padding-top: 50px;
  padding-top: 3.125rem; }

.pdb-50 {
  padding-bottom: 50px;
  padding-bottom: 3.125rem; }

.pdl-50 {
  padding-left: 50px;
  padding-left: 3.125rem; }

.pdr-50 {
  padding-right: 50px;
  padding-right: 3.125rem; }

.mg-55 {
  margin: 55px;
  margin: 3.4375rem; }

.mg-55-0 {
  margin: 55px 0;
  margin: 3.4375rem 0; }

.mg-0-55 {
  margin: 0 55px;
  margin: 0 3.4375rem; }

.mgt-55 {
  margin-top: 55px;
  margin-top: 3.4375rem; }

.mgb-55 {
  margin-bottom: 55px;
  margin-bottom: 3.4375rem; }

.mgl-55 {
  margin-left: 55px;
  margin-left: 3.4375rem; }

.mgr-55 {
  margin-right: 55px;
  margin-right: 3.4375rem; }

.pd-55 {
  padding: 55px;
  padding: 3.4375rem; }

.pd-55-0 {
  padding: 55px 0;
  padding: 3.4375rem 0; }

.pd-0-55 {
  padding: 0 55px;
  padding: 0 3.4375rem; }

.pdt-55 {
  padding-top: 55px;
  padding-top: 3.4375rem; }

.pdb-55 {
  padding-bottom: 55px;
  padding-bottom: 3.4375rem; }

.pdl-55 {
  padding-left: 55px;
  padding-left: 3.4375rem; }

.pdr-55 {
  padding-right: 55px;
  padding-right: 3.4375rem; }

.mg-60 {
  margin: 60px;
  margin: 3.75rem; }

.mg-60-0 {
  margin: 60px 0;
  margin: 3.75rem 0; }

.mg-0-60 {
  margin: 0 60px;
  margin: 0 3.75rem; }

.mgt-60 {
  margin-top: 60px;
  margin-top: 3.75rem; }

.mgb-60 {
  margin-bottom: 60px;
  margin-bottom: 3.75rem; }

.mgl-60 {
  margin-left: 60px;
  margin-left: 3.75rem; }

.mgr-60 {
  margin-right: 60px;
  margin-right: 3.75rem; }

.pd-60 {
  padding: 60px;
  padding: 3.75rem; }

.pd-60-0 {
  padding: 60px 0;
  padding: 3.75rem 0; }

.pd-0-60 {
  padding: 0 60px;
  padding: 0 3.75rem; }

.pdt-60 {
  padding-top: 60px;
  padding-top: 3.75rem; }

.pdb-60 {
  padding-bottom: 60px;
  padding-bottom: 3.75rem; }

.pdl-60 {
  padding-left: 60px;
  padding-left: 3.75rem; }

.pdr-60 {
  padding-right: 60px;
  padding-right: 3.75rem; }

.mg-65 {
  margin: 65px;
  margin: 4.0625rem; }

.mg-65-0 {
  margin: 65px 0;
  margin: 4.0625rem 0; }

.mg-0-65 {
  margin: 0 65px;
  margin: 0 4.0625rem; }

.mgt-65 {
  margin-top: 65px;
  margin-top: 4.0625rem; }

.mgb-65 {
  margin-bottom: 65px;
  margin-bottom: 4.0625rem; }

.mgl-65 {
  margin-left: 65px;
  margin-left: 4.0625rem; }

.mgr-65 {
  margin-right: 65px;
  margin-right: 4.0625rem; }

.pd-65 {
  padding: 65px;
  padding: 4.0625rem; }

.pd-65-0 {
  padding: 65px 0;
  padding: 4.0625rem 0; }

.pd-0-65 {
  padding: 0 65px;
  padding: 0 4.0625rem; }

.pdt-65 {
  padding-top: 65px;
  padding-top: 4.0625rem; }

.pdb-65 {
  padding-bottom: 65px;
  padding-bottom: 4.0625rem; }

.pdl-65 {
  padding-left: 65px;
  padding-left: 4.0625rem; }

.pdr-65 {
  padding-right: 65px;
  padding-right: 4.0625rem; }

.mg-70 {
  margin: 70px;
  margin: 4.375rem; }

.mg-70-0 {
  margin: 70px 0;
  margin: 4.375rem 0; }

.mg-0-70 {
  margin: 0 70px;
  margin: 0 4.375rem; }

.mgt-70 {
  margin-top: 70px;
  margin-top: 4.375rem; }

.mgb-70 {
  margin-bottom: 70px;
  margin-bottom: 4.375rem; }

.mgl-70 {
  margin-left: 70px;
  margin-left: 4.375rem; }

.mgr-70 {
  margin-right: 70px;
  margin-right: 4.375rem; }

.pd-70 {
  padding: 70px;
  padding: 4.375rem; }

.pd-70-0 {
  padding: 70px 0;
  padding: 4.375rem 0; }

.pd-0-70 {
  padding: 0 70px;
  padding: 0 4.375rem; }

.pdt-70 {
  padding-top: 70px;
  padding-top: 4.375rem; }

.pdb-70 {
  padding-bottom: 70px;
  padding-bottom: 4.375rem; }

.pdl-70 {
  padding-left: 70px;
  padding-left: 4.375rem; }

.pdr-70 {
  padding-right: 70px;
  padding-right: 4.375rem; }

.mg-75 {
  margin: 75px;
  margin: 4.6875rem; }

.mg-75-0 {
  margin: 75px 0;
  margin: 4.6875rem 0; }

.mg-0-75 {
  margin: 0 75px;
  margin: 0 4.6875rem; }

.mgt-75 {
  margin-top: 75px;
  margin-top: 4.6875rem; }

.mgb-75 {
  margin-bottom: 75px;
  margin-bottom: 4.6875rem; }

.mgl-75 {
  margin-left: 75px;
  margin-left: 4.6875rem; }

.mgr-75 {
  margin-right: 75px;
  margin-right: 4.6875rem; }

.pd-75 {
  padding: 75px;
  padding: 4.6875rem; }

.pd-75-0 {
  padding: 75px 0;
  padding: 4.6875rem 0; }

.pd-0-75 {
  padding: 0 75px;
  padding: 0 4.6875rem; }

.pdt-75 {
  padding-top: 75px;
  padding-top: 4.6875rem; }

.pdb-75 {
  padding-bottom: 75px;
  padding-bottom: 4.6875rem; }

.pdl-75 {
  padding-left: 75px;
  padding-left: 4.6875rem; }

.pdr-75 {
  padding-right: 75px;
  padding-right: 4.6875rem; }

.mg-80 {
  margin: 80px;
  margin: 5rem; }

.mg-80-0 {
  margin: 80px 0;
  margin: 5rem 0; }

.mg-0-80 {
  margin: 0 80px;
  margin: 0 5rem; }

.mgt-80 {
  margin-top: 80px;
  margin-top: 5rem; }

.mgb-80 {
  margin-bottom: 80px;
  margin-bottom: 5rem; }

.mgl-80 {
  margin-left: 80px;
  margin-left: 5rem; }

.mgr-80 {
  margin-right: 80px;
  margin-right: 5rem; }

.pd-80 {
  padding: 80px;
  padding: 5rem; }

.pd-80-0 {
  padding: 80px 0;
  padding: 5rem 0; }

.pd-0-80 {
  padding: 0 80px;
  padding: 0 5rem; }

.pdt-80 {
  padding-top: 80px;
  padding-top: 5rem; }

.pdb-80 {
  padding-bottom: 80px;
  padding-bottom: 5rem; }

.pdl-80 {
  padding-left: 80px;
  padding-left: 5rem; }

.pdr-80 {
  padding-right: 80px;
  padding-right: 5rem; }

.mg-85 {
  margin: 85px;
  margin: 5.3125rem; }

.mg-85-0 {
  margin: 85px 0;
  margin: 5.3125rem 0; }

.mg-0-85 {
  margin: 0 85px;
  margin: 0 5.3125rem; }

.mgt-85 {
  margin-top: 85px;
  margin-top: 5.3125rem; }

.mgb-85 {
  margin-bottom: 85px;
  margin-bottom: 5.3125rem; }

.mgl-85 {
  margin-left: 85px;
  margin-left: 5.3125rem; }

.mgr-85 {
  margin-right: 85px;
  margin-right: 5.3125rem; }

.pd-85 {
  padding: 85px;
  padding: 5.3125rem; }

.pd-85-0 {
  padding: 85px 0;
  padding: 5.3125rem 0; }

.pd-0-85 {
  padding: 0 85px;
  padding: 0 5.3125rem; }

.pdt-85 {
  padding-top: 85px;
  padding-top: 5.3125rem; }

.pdb-85 {
  padding-bottom: 85px;
  padding-bottom: 5.3125rem; }

.pdl-85 {
  padding-left: 85px;
  padding-left: 5.3125rem; }

.pdr-85 {
  padding-right: 85px;
  padding-right: 5.3125rem; }

.mg-90 {
  margin: 90px;
  margin: 5.625rem; }

.mg-90-0 {
  margin: 90px 0;
  margin: 5.625rem 0; }

.mg-0-90 {
  margin: 0 90px;
  margin: 0 5.625rem; }

.mgt-90 {
  margin-top: 90px;
  margin-top: 5.625rem; }

.mgb-90 {
  margin-bottom: 90px;
  margin-bottom: 5.625rem; }

.mgl-90 {
  margin-left: 90px;
  margin-left: 5.625rem; }

.mgr-90 {
  margin-right: 90px;
  margin-right: 5.625rem; }

.pd-90 {
  padding: 90px;
  padding: 5.625rem; }

.pd-90-0 {
  padding: 90px 0;
  padding: 5.625rem 0; }

.pd-0-90 {
  padding: 0 90px;
  padding: 0 5.625rem; }

.pdt-90 {
  padding-top: 90px;
  padding-top: 5.625rem; }

.pdb-90 {
  padding-bottom: 90px;
  padding-bottom: 5.625rem; }

.pdl-90 {
  padding-left: 90px;
  padding-left: 5.625rem; }

.pdr-90 {
  padding-right: 90px;
  padding-right: 5.625rem; }

.mg-95 {
  margin: 95px;
  margin: 5.9375rem; }

.mg-95-0 {
  margin: 95px 0;
  margin: 5.9375rem 0; }

.mg-0-95 {
  margin: 0 95px;
  margin: 0 5.9375rem; }

.mgt-95 {
  margin-top: 95px;
  margin-top: 5.9375rem; }

.mgb-95 {
  margin-bottom: 95px;
  margin-bottom: 5.9375rem; }

.mgl-95 {
  margin-left: 95px;
  margin-left: 5.9375rem; }

.mgr-95 {
  margin-right: 95px;
  margin-right: 5.9375rem; }

.pd-95 {
  padding: 95px;
  padding: 5.9375rem; }

.pd-95-0 {
  padding: 95px 0;
  padding: 5.9375rem 0; }

.pd-0-95 {
  padding: 0 95px;
  padding: 0 5.9375rem; }

.pdt-95 {
  padding-top: 95px;
  padding-top: 5.9375rem; }

.pdb-95 {
  padding-bottom: 95px;
  padding-bottom: 5.9375rem; }

.pdl-95 {
  padding-left: 95px;
  padding-left: 5.9375rem; }

.pdr-95 {
  padding-right: 95px;
  padding-right: 5.9375rem; }

.mg-100 {
  margin: 100px;
  margin: 6.25rem; }

.mg-100-0 {
  margin: 100px 0;
  margin: 6.25rem 0; }

.mg-0-100 {
  margin: 0 100px;
  margin: 0 6.25rem; }

.mgt-100 {
  margin-top: 100px;
  margin-top: 6.25rem; }

.mgb-100 {
  margin-bottom: 100px;
  margin-bottom: 6.25rem; }

.mgl-100 {
  margin-left: 100px;
  margin-left: 6.25rem; }

.mgr-100 {
  margin-right: 100px;
  margin-right: 6.25rem; }

.pd-100 {
  padding: 100px;
  padding: 6.25rem; }

.pd-100-0 {
  padding: 100px 0;
  padding: 6.25rem 0; }

.pd-0-100 {
  padding: 0 100px;
  padding: 0 6.25rem; }

.pdt-100 {
  padding-top: 100px;
  padding-top: 6.25rem; }

.pdb-100 {
  padding-bottom: 100px;
  padding-bottom: 6.25rem; }

.pdl-100 {
  padding-left: 100px;
  padding-left: 6.25rem; }

.pdr-100 {
  padding-right: 100px;
  padding-right: 6.25rem; }

.mg-105 {
  margin: 105px;
  margin: 6.5625rem; }

.mg-105-0 {
  margin: 105px 0;
  margin: 6.5625rem 0; }

.mg-0-105 {
  margin: 0 105px;
  margin: 0 6.5625rem; }

.mgt-105 {
  margin-top: 105px;
  margin-top: 6.5625rem; }

.mgb-105 {
  margin-bottom: 105px;
  margin-bottom: 6.5625rem; }

.mgl-105 {
  margin-left: 105px;
  margin-left: 6.5625rem; }

.mgr-105 {
  margin-right: 105px;
  margin-right: 6.5625rem; }

.pd-105 {
  padding: 105px;
  padding: 6.5625rem; }

.pd-105-0 {
  padding: 105px 0;
  padding: 6.5625rem 0; }

.pd-0-105 {
  padding: 0 105px;
  padding: 0 6.5625rem; }

.pdt-105 {
  padding-top: 105px;
  padding-top: 6.5625rem; }

.pdb-105 {
  padding-bottom: 105px;
  padding-bottom: 6.5625rem; }

.pdl-105 {
  padding-left: 105px;
  padding-left: 6.5625rem; }

.pdr-105 {
  padding-right: 105px;
  padding-right: 6.5625rem; }

.mg-110 {
  margin: 110px;
  margin: 6.875rem; }

.mg-110-0 {
  margin: 110px 0;
  margin: 6.875rem 0; }

.mg-0-110 {
  margin: 0 110px;
  margin: 0 6.875rem; }

.mgt-110 {
  margin-top: 110px;
  margin-top: 6.875rem; }

.mgb-110 {
  margin-bottom: 110px;
  margin-bottom: 6.875rem; }

.mgl-110 {
  margin-left: 110px;
  margin-left: 6.875rem; }

.mgr-110 {
  margin-right: 110px;
  margin-right: 6.875rem; }

.pd-110 {
  padding: 110px;
  padding: 6.875rem; }

.pd-110-0 {
  padding: 110px 0;
  padding: 6.875rem 0; }

.pd-0-110 {
  padding: 0 110px;
  padding: 0 6.875rem; }

.pdt-110 {
  padding-top: 110px;
  padding-top: 6.875rem; }

.pdb-110 {
  padding-bottom: 110px;
  padding-bottom: 6.875rem; }

.pdl-110 {
  padding-left: 110px;
  padding-left: 6.875rem; }

.pdr-110 {
  padding-right: 110px;
  padding-right: 6.875rem; }

.mg-115 {
  margin: 115px;
  margin: 7.1875rem; }

.mg-115-0 {
  margin: 115px 0;
  margin: 7.1875rem 0; }

.mg-0-115 {
  margin: 0 115px;
  margin: 0 7.1875rem; }

.mgt-115 {
  margin-top: 115px;
  margin-top: 7.1875rem; }

.mgb-115 {
  margin-bottom: 115px;
  margin-bottom: 7.1875rem; }

.mgl-115 {
  margin-left: 115px;
  margin-left: 7.1875rem; }

.mgr-115 {
  margin-right: 115px;
  margin-right: 7.1875rem; }

.pd-115 {
  padding: 115px;
  padding: 7.1875rem; }

.pd-115-0 {
  padding: 115px 0;
  padding: 7.1875rem 0; }

.pd-0-115 {
  padding: 0 115px;
  padding: 0 7.1875rem; }

.pdt-115 {
  padding-top: 115px;
  padding-top: 7.1875rem; }

.pdb-115 {
  padding-bottom: 115px;
  padding-bottom: 7.1875rem; }

.pdl-115 {
  padding-left: 115px;
  padding-left: 7.1875rem; }

.pdr-115 {
  padding-right: 115px;
  padding-right: 7.1875rem; }

.mg-120 {
  margin: 120px;
  margin: 7.5rem; }

.mg-120-0 {
  margin: 120px 0;
  margin: 7.5rem 0; }

.mg-0-120 {
  margin: 0 120px;
  margin: 0 7.5rem; }

.mgt-120 {
  margin-top: 120px;
  margin-top: 7.5rem; }

.mgb-120 {
  margin-bottom: 120px;
  margin-bottom: 7.5rem; }

.mgl-120 {
  margin-left: 120px;
  margin-left: 7.5rem; }

.mgr-120 {
  margin-right: 120px;
  margin-right: 7.5rem; }

.pd-120 {
  padding: 120px;
  padding: 7.5rem; }

.pd-120-0 {
  padding: 120px 0;
  padding: 7.5rem 0; }

.pd-0-120 {
  padding: 0 120px;
  padding: 0 7.5rem; }

.pdt-120 {
  padding-top: 120px;
  padding-top: 7.5rem; }

.pdb-120 {
  padding-bottom: 120px;
  padding-bottom: 7.5rem; }

.pdl-120 {
  padding-left: 120px;
  padding-left: 7.5rem; }

.pdr-120 {
  padding-right: 120px;
  padding-right: 7.5rem; }

.mg-125 {
  margin: 125px;
  margin: 7.8125rem; }

.mg-125-0 {
  margin: 125px 0;
  margin: 7.8125rem 0; }

.mg-0-125 {
  margin: 0 125px;
  margin: 0 7.8125rem; }

.mgt-125 {
  margin-top: 125px;
  margin-top: 7.8125rem; }

.mgb-125 {
  margin-bottom: 125px;
  margin-bottom: 7.8125rem; }

.mgl-125 {
  margin-left: 125px;
  margin-left: 7.8125rem; }

.mgr-125 {
  margin-right: 125px;
  margin-right: 7.8125rem; }

.pd-125 {
  padding: 125px;
  padding: 7.8125rem; }

.pd-125-0 {
  padding: 125px 0;
  padding: 7.8125rem 0; }

.pd-0-125 {
  padding: 0 125px;
  padding: 0 7.8125rem; }

.pdt-125 {
  padding-top: 125px;
  padding-top: 7.8125rem; }

.pdb-125 {
  padding-bottom: 125px;
  padding-bottom: 7.8125rem; }

.pdl-125 {
  padding-left: 125px;
  padding-left: 7.8125rem; }

.pdr-125 {
  padding-right: 125px;
  padding-right: 7.8125rem; }

.mg-130 {
  margin: 130px;
  margin: 8.125rem; }

.mg-130-0 {
  margin: 130px 0;
  margin: 8.125rem 0; }

.mg-0-130 {
  margin: 0 130px;
  margin: 0 8.125rem; }

.mgt-130 {
  margin-top: 130px;
  margin-top: 8.125rem; }

.mgb-130 {
  margin-bottom: 130px;
  margin-bottom: 8.125rem; }

.mgl-130 {
  margin-left: 130px;
  margin-left: 8.125rem; }

.mgr-130 {
  margin-right: 130px;
  margin-right: 8.125rem; }

.pd-130 {
  padding: 130px;
  padding: 8.125rem; }

.pd-130-0 {
  padding: 130px 0;
  padding: 8.125rem 0; }

.pd-0-130 {
  padding: 0 130px;
  padding: 0 8.125rem; }

.pdt-130 {
  padding-top: 130px;
  padding-top: 8.125rem; }

.pdb-130 {
  padding-bottom: 130px;
  padding-bottom: 8.125rem; }

.pdl-130 {
  padding-left: 130px;
  padding-left: 8.125rem; }

.pdr-130 {
  padding-right: 130px;
  padding-right: 8.125rem; }

.mg-135 {
  margin: 135px;
  margin: 8.4375rem; }

.mg-135-0 {
  margin: 135px 0;
  margin: 8.4375rem 0; }

.mg-0-135 {
  margin: 0 135px;
  margin: 0 8.4375rem; }

.mgt-135 {
  margin-top: 135px;
  margin-top: 8.4375rem; }

.mgb-135 {
  margin-bottom: 135px;
  margin-bottom: 8.4375rem; }

.mgl-135 {
  margin-left: 135px;
  margin-left: 8.4375rem; }

.mgr-135 {
  margin-right: 135px;
  margin-right: 8.4375rem; }

.pd-135 {
  padding: 135px;
  padding: 8.4375rem; }

.pd-135-0 {
  padding: 135px 0;
  padding: 8.4375rem 0; }

.pd-0-135 {
  padding: 0 135px;
  padding: 0 8.4375rem; }

.pdt-135 {
  padding-top: 135px;
  padding-top: 8.4375rem; }

.pdb-135 {
  padding-bottom: 135px;
  padding-bottom: 8.4375rem; }

.pdl-135 {
  padding-left: 135px;
  padding-left: 8.4375rem; }

.pdr-135 {
  padding-right: 135px;
  padding-right: 8.4375rem; }

.mg-140 {
  margin: 140px;
  margin: 8.75rem; }

.mg-140-0 {
  margin: 140px 0;
  margin: 8.75rem 0; }

.mg-0-140 {
  margin: 0 140px;
  margin: 0 8.75rem; }

.mgt-140 {
  margin-top: 140px;
  margin-top: 8.75rem; }

.mgb-140 {
  margin-bottom: 140px;
  margin-bottom: 8.75rem; }

.mgl-140 {
  margin-left: 140px;
  margin-left: 8.75rem; }

.mgr-140 {
  margin-right: 140px;
  margin-right: 8.75rem; }

.pd-140 {
  padding: 140px;
  padding: 8.75rem; }

.pd-140-0 {
  padding: 140px 0;
  padding: 8.75rem 0; }

.pd-0-140 {
  padding: 0 140px;
  padding: 0 8.75rem; }

.pdt-140 {
  padding-top: 140px;
  padding-top: 8.75rem; }

.pdb-140 {
  padding-bottom: 140px;
  padding-bottom: 8.75rem; }

.pdl-140 {
  padding-left: 140px;
  padding-left: 8.75rem; }

.pdr-140 {
  padding-right: 140px;
  padding-right: 8.75rem; }

.mg-145 {
  margin: 145px;
  margin: 9.0625rem; }

.mg-145-0 {
  margin: 145px 0;
  margin: 9.0625rem 0; }

.mg-0-145 {
  margin: 0 145px;
  margin: 0 9.0625rem; }

.mgt-145 {
  margin-top: 145px;
  margin-top: 9.0625rem; }

.mgb-145 {
  margin-bottom: 145px;
  margin-bottom: 9.0625rem; }

.mgl-145 {
  margin-left: 145px;
  margin-left: 9.0625rem; }

.mgr-145 {
  margin-right: 145px;
  margin-right: 9.0625rem; }

.pd-145 {
  padding: 145px;
  padding: 9.0625rem; }

.pd-145-0 {
  padding: 145px 0;
  padding: 9.0625rem 0; }

.pd-0-145 {
  padding: 0 145px;
  padding: 0 9.0625rem; }

.pdt-145 {
  padding-top: 145px;
  padding-top: 9.0625rem; }

.pdb-145 {
  padding-bottom: 145px;
  padding-bottom: 9.0625rem; }

.pdl-145 {
  padding-left: 145px;
  padding-left: 9.0625rem; }

.pdr-145 {
  padding-right: 145px;
  padding-right: 9.0625rem; }

.mg-150 {
  margin: 150px;
  margin: 9.375rem; }

.mg-150-0 {
  margin: 150px 0;
  margin: 9.375rem 0; }

.mg-0-150 {
  margin: 0 150px;
  margin: 0 9.375rem; }

.mgt-150 {
  margin-top: 150px;
  margin-top: 9.375rem; }

.mgb-150 {
  margin-bottom: 150px;
  margin-bottom: 9.375rem; }

.mgl-150 {
  margin-left: 150px;
  margin-left: 9.375rem; }

.mgr-150 {
  margin-right: 150px;
  margin-right: 9.375rem; }

.pd-150 {
  padding: 150px;
  padding: 9.375rem; }

.pd-150-0 {
  padding: 150px 0;
  padding: 9.375rem 0; }

.pd-0-150 {
  padding: 0 150px;
  padding: 0 9.375rem; }

.pdt-150 {
  padding-top: 150px;
  padding-top: 9.375rem; }

.pdb-150 {
  padding-bottom: 150px;
  padding-bottom: 9.375rem; }

.pdl-150 {
  padding-left: 150px;
  padding-left: 9.375rem; }

.pdr-150 {
  padding-right: 150px;
  padding-right: 9.375rem; }

@media (min-width: 480px) {
  .text-sm-normal {
    font-weight: 400; }
  .text-sm-semibold {
    font-weight: 600; }
  .text-sm-bold {
    font-weight: 700; }
  .text-sm-left {
    text-align: left; }
  .text-sm-right {
    text-align: right; }
  .text-sm-center {
    text-align: center; }
  .text-sm-justify {
    text-align: justify; }
  .text-sm-nowrap {
    white-space: nowrap; }
  .text-sm-lowercase {
    text-transform: lowercase; }
  .text-sm-uppercase {
    text-transform: uppercase; }
  .mg-sm-0 {
    margin: 0; }
  .mgt-sm-0 {
    margin-top: 0; }
  .mgb-sm-0 {
    margin-bottom: 0; }
  .mgl-sm-0 {
    margin-left: 0; }
  .mgr-sm-0 {
    margin-right: 0; }
  .pd-sm-0 {
    padding: 0; }
  .pdt-sm-0 {
    padding-top: 0; }
  .pdb-sm-0 {
    padding-bottom: 0; }
  .pdl-sm-0 {
    padding-left: 0; }
  .pdr-sm-0 {
    padding-right: 0; }
  .mg-sm-5 {
    margin: 5px;
    margin: 0.3125rem; }
  .mg-sm-5-0 {
    margin: 5px 0;
    margin: 0.3125rem 0; }
  .mg-sm-0-5 {
    margin: 0 5px;
    margin: 0 0.3125rem; }
  .mgt-sm-5 {
    margin-top: 5px;
    margin-top: 0.3125rem; }
  .mgb-sm-5 {
    margin-bottom: 5px;
    margin-bottom: 0.3125rem; }
  .mgl-sm-5 {
    margin-left: 5px;
    margin-left: 0.3125rem; }
  .mgr-sm-5 {
    margin-right: 5px;
    margin-right: 0.3125rem; }
  .pd-sm-5 {
    padding: 5px;
    padding: 0.3125rem; }
  .pd-sm-5-0 {
    padding: 5px 0;
    padding: 0.3125rem 0; }
  .pd-sm-0-5 {
    padding: 0 5px;
    padding: 0 0.3125rem; }
  .pdt-sm-5 {
    padding-top: 5px;
    padding-top: 0.3125rem; }
  .pdb-sm-5 {
    padding-bottom: 5px;
    padding-bottom: 0.3125rem; }
  .pdl-sm-5 {
    padding-left: 5px;
    padding-left: 0.3125rem; }
  .pdr-sm-5 {
    padding-right: 5px;
    padding-right: 0.3125rem; }
  .mg-sm-10 {
    margin: 10px;
    margin: 0.625rem; }
  .mg-sm-10-0 {
    margin: 10px 0;
    margin: 0.625rem 0; }
  .mg-sm-0-10 {
    margin: 0 10px;
    margin: 0 0.625rem; }
  .mgt-sm-10 {
    margin-top: 10px;
    margin-top: 0.625rem; }
  .mgb-sm-10 {
    margin-bottom: 10px;
    margin-bottom: 0.625rem; }
  .mgl-sm-10 {
    margin-left: 10px;
    margin-left: 0.625rem; }
  .mgr-sm-10 {
    margin-right: 10px;
    margin-right: 0.625rem; }
  .pd-sm-10 {
    padding: 10px;
    padding: 0.625rem; }
  .pd-sm-10-0 {
    padding: 10px 0;
    padding: 0.625rem 0; }
  .pd-sm-0-10 {
    padding: 0 10px;
    padding: 0 0.625rem; }
  .pdt-sm-10 {
    padding-top: 10px;
    padding-top: 0.625rem; }
  .pdb-sm-10 {
    padding-bottom: 10px;
    padding-bottom: 0.625rem; }
  .pdl-sm-10 {
    padding-left: 10px;
    padding-left: 0.625rem; }
  .pdr-sm-10 {
    padding-right: 10px;
    padding-right: 0.625rem; }
  .mg-sm-15 {
    margin: 15px;
    margin: 0.9375rem; }
  .mg-sm-15-0 {
    margin: 15px 0;
    margin: 0.9375rem 0; }
  .mg-sm-0-15 {
    margin: 0 15px;
    margin: 0 0.9375rem; }
  .mgt-sm-15 {
    margin-top: 15px;
    margin-top: 0.9375rem; }
  .mgb-sm-15 {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem; }
  .mgl-sm-15 {
    margin-left: 15px;
    margin-left: 0.9375rem; }
  .mgr-sm-15 {
    margin-right: 15px;
    margin-right: 0.9375rem; }
  .pd-sm-15 {
    padding: 15px;
    padding: 0.9375rem; }
  .pd-sm-15-0 {
    padding: 15px 0;
    padding: 0.9375rem 0; }
  .pd-sm-0-15 {
    padding: 0 15px;
    padding: 0 0.9375rem; }
  .pdt-sm-15 {
    padding-top: 15px;
    padding-top: 0.9375rem; }
  .pdb-sm-15 {
    padding-bottom: 15px;
    padding-bottom: 0.9375rem; }
  .pdl-sm-15 {
    padding-left: 15px;
    padding-left: 0.9375rem; }
  .pdr-sm-15 {
    padding-right: 15px;
    padding-right: 0.9375rem; }
  .mg-sm-20 {
    margin: 20px;
    margin: 1.25rem; }
  .mg-sm-20-0 {
    margin: 20px 0;
    margin: 1.25rem 0; }
  .mg-sm-0-20 {
    margin: 0 20px;
    margin: 0 1.25rem; }
  .mgt-sm-20 {
    margin-top: 20px;
    margin-top: 1.25rem; }
  .mgb-sm-20 {
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  .mgl-sm-20 {
    margin-left: 20px;
    margin-left: 1.25rem; }
  .mgr-sm-20 {
    margin-right: 20px;
    margin-right: 1.25rem; }
  .pd-sm-20 {
    padding: 20px;
    padding: 1.25rem; }
  .pd-sm-20-0 {
    padding: 20px 0;
    padding: 1.25rem 0; }
  .pd-sm-0-20 {
    padding: 0 20px;
    padding: 0 1.25rem; }
  .pdt-sm-20 {
    padding-top: 20px;
    padding-top: 1.25rem; }
  .pdb-sm-20 {
    padding-bottom: 20px;
    padding-bottom: 1.25rem; }
  .pdl-sm-20 {
    padding-left: 20px;
    padding-left: 1.25rem; }
  .pdr-sm-20 {
    padding-right: 20px;
    padding-right: 1.25rem; }
  .mg-sm-25 {
    margin: 25px;
    margin: 1.5625rem; }
  .mg-sm-25-0 {
    margin: 25px 0;
    margin: 1.5625rem 0; }
  .mg-sm-0-25 {
    margin: 0 25px;
    margin: 0 1.5625rem; }
  .mgt-sm-25 {
    margin-top: 25px;
    margin-top: 1.5625rem; }
  .mgb-sm-25 {
    margin-bottom: 25px;
    margin-bottom: 1.5625rem; }
  .mgl-sm-25 {
    margin-left: 25px;
    margin-left: 1.5625rem; }
  .mgr-sm-25 {
    margin-right: 25px;
    margin-right: 1.5625rem; }
  .pd-sm-25 {
    padding: 25px;
    padding: 1.5625rem; }
  .pd-sm-25-0 {
    padding: 25px 0;
    padding: 1.5625rem 0; }
  .pd-sm-0-25 {
    padding: 0 25px;
    padding: 0 1.5625rem; }
  .pdt-sm-25 {
    padding-top: 25px;
    padding-top: 1.5625rem; }
  .pdb-sm-25 {
    padding-bottom: 25px;
    padding-bottom: 1.5625rem; }
  .pdl-sm-25 {
    padding-left: 25px;
    padding-left: 1.5625rem; }
  .pdr-sm-25 {
    padding-right: 25px;
    padding-right: 1.5625rem; }
  .mg-sm-30 {
    margin: 30px;
    margin: 1.875rem; }
  .mg-sm-30-0 {
    margin: 30px 0;
    margin: 1.875rem 0; }
  .mg-sm-0-30 {
    margin: 0 30px;
    margin: 0 1.875rem; }
  .mgt-sm-30 {
    margin-top: 30px;
    margin-top: 1.875rem; }
  .mgb-sm-30 {
    margin-bottom: 30px;
    margin-bottom: 1.875rem; }
  .mgl-sm-30 {
    margin-left: 30px;
    margin-left: 1.875rem; }
  .mgr-sm-30 {
    margin-right: 30px;
    margin-right: 1.875rem; }
  .pd-sm-30 {
    padding: 30px;
    padding: 1.875rem; }
  .pd-sm-30-0 {
    padding: 30px 0;
    padding: 1.875rem 0; }
  .pd-sm-0-30 {
    padding: 0 30px;
    padding: 0 1.875rem; }
  .pdt-sm-30 {
    padding-top: 30px;
    padding-top: 1.875rem; }
  .pdb-sm-30 {
    padding-bottom: 30px;
    padding-bottom: 1.875rem; }
  .pdl-sm-30 {
    padding-left: 30px;
    padding-left: 1.875rem; }
  .pdr-sm-30 {
    padding-right: 30px;
    padding-right: 1.875rem; }
  .mg-sm-35 {
    margin: 35px;
    margin: 2.1875rem; }
  .mg-sm-35-0 {
    margin: 35px 0;
    margin: 2.1875rem 0; }
  .mg-sm-0-35 {
    margin: 0 35px;
    margin: 0 2.1875rem; }
  .mgt-sm-35 {
    margin-top: 35px;
    margin-top: 2.1875rem; }
  .mgb-sm-35 {
    margin-bottom: 35px;
    margin-bottom: 2.1875rem; }
  .mgl-sm-35 {
    margin-left: 35px;
    margin-left: 2.1875rem; }
  .mgr-sm-35 {
    margin-right: 35px;
    margin-right: 2.1875rem; }
  .pd-sm-35 {
    padding: 35px;
    padding: 2.1875rem; }
  .pd-sm-35-0 {
    padding: 35px 0;
    padding: 2.1875rem 0; }
  .pd-sm-0-35 {
    padding: 0 35px;
    padding: 0 2.1875rem; }
  .pdt-sm-35 {
    padding-top: 35px;
    padding-top: 2.1875rem; }
  .pdb-sm-35 {
    padding-bottom: 35px;
    padding-bottom: 2.1875rem; }
  .pdl-sm-35 {
    padding-left: 35px;
    padding-left: 2.1875rem; }
  .pdr-sm-35 {
    padding-right: 35px;
    padding-right: 2.1875rem; }
  .mg-sm-40 {
    margin: 40px;
    margin: 2.5rem; }
  .mg-sm-40-0 {
    margin: 40px 0;
    margin: 2.5rem 0; }
  .mg-sm-0-40 {
    margin: 0 40px;
    margin: 0 2.5rem; }
  .mgt-sm-40 {
    margin-top: 40px;
    margin-top: 2.5rem; }
  .mgb-sm-40 {
    margin-bottom: 40px;
    margin-bottom: 2.5rem; }
  .mgl-sm-40 {
    margin-left: 40px;
    margin-left: 2.5rem; }
  .mgr-sm-40 {
    margin-right: 40px;
    margin-right: 2.5rem; }
  .pd-sm-40 {
    padding: 40px;
    padding: 2.5rem; }
  .pd-sm-40-0 {
    padding: 40px 0;
    padding: 2.5rem 0; }
  .pd-sm-0-40 {
    padding: 0 40px;
    padding: 0 2.5rem; }
  .pdt-sm-40 {
    padding-top: 40px;
    padding-top: 2.5rem; }
  .pdb-sm-40 {
    padding-bottom: 40px;
    padding-bottom: 2.5rem; }
  .pdl-sm-40 {
    padding-left: 40px;
    padding-left: 2.5rem; }
  .pdr-sm-40 {
    padding-right: 40px;
    padding-right: 2.5rem; }
  .mg-sm-45 {
    margin: 45px;
    margin: 2.8125rem; }
  .mg-sm-45-0 {
    margin: 45px 0;
    margin: 2.8125rem 0; }
  .mg-sm-0-45 {
    margin: 0 45px;
    margin: 0 2.8125rem; }
  .mgt-sm-45 {
    margin-top: 45px;
    margin-top: 2.8125rem; }
  .mgb-sm-45 {
    margin-bottom: 45px;
    margin-bottom: 2.8125rem; }
  .mgl-sm-45 {
    margin-left: 45px;
    margin-left: 2.8125rem; }
  .mgr-sm-45 {
    margin-right: 45px;
    margin-right: 2.8125rem; }
  .pd-sm-45 {
    padding: 45px;
    padding: 2.8125rem; }
  .pd-sm-45-0 {
    padding: 45px 0;
    padding: 2.8125rem 0; }
  .pd-sm-0-45 {
    padding: 0 45px;
    padding: 0 2.8125rem; }
  .pdt-sm-45 {
    padding-top: 45px;
    padding-top: 2.8125rem; }
  .pdb-sm-45 {
    padding-bottom: 45px;
    padding-bottom: 2.8125rem; }
  .pdl-sm-45 {
    padding-left: 45px;
    padding-left: 2.8125rem; }
  .pdr-sm-45 {
    padding-right: 45px;
    padding-right: 2.8125rem; }
  .mg-sm-50 {
    margin: 50px;
    margin: 3.125rem; }
  .mg-sm-50-0 {
    margin: 50px 0;
    margin: 3.125rem 0; }
  .mg-sm-0-50 {
    margin: 0 50px;
    margin: 0 3.125rem; }
  .mgt-sm-50 {
    margin-top: 50px;
    margin-top: 3.125rem; }
  .mgb-sm-50 {
    margin-bottom: 50px;
    margin-bottom: 3.125rem; }
  .mgl-sm-50 {
    margin-left: 50px;
    margin-left: 3.125rem; }
  .mgr-sm-50 {
    margin-right: 50px;
    margin-right: 3.125rem; }
  .pd-sm-50 {
    padding: 50px;
    padding: 3.125rem; }
  .pd-sm-50-0 {
    padding: 50px 0;
    padding: 3.125rem 0; }
  .pd-sm-0-50 {
    padding: 0 50px;
    padding: 0 3.125rem; }
  .pdt-sm-50 {
    padding-top: 50px;
    padding-top: 3.125rem; }
  .pdb-sm-50 {
    padding-bottom: 50px;
    padding-bottom: 3.125rem; }
  .pdl-sm-50 {
    padding-left: 50px;
    padding-left: 3.125rem; }
  .pdr-sm-50 {
    padding-right: 50px;
    padding-right: 3.125rem; }
  .mg-sm-55 {
    margin: 55px;
    margin: 3.4375rem; }
  .mg-sm-55-0 {
    margin: 55px 0;
    margin: 3.4375rem 0; }
  .mg-sm-0-55 {
    margin: 0 55px;
    margin: 0 3.4375rem; }
  .mgt-sm-55 {
    margin-top: 55px;
    margin-top: 3.4375rem; }
  .mgb-sm-55 {
    margin-bottom: 55px;
    margin-bottom: 3.4375rem; }
  .mgl-sm-55 {
    margin-left: 55px;
    margin-left: 3.4375rem; }
  .mgr-sm-55 {
    margin-right: 55px;
    margin-right: 3.4375rem; }
  .pd-sm-55 {
    padding: 55px;
    padding: 3.4375rem; }
  .pd-sm-55-0 {
    padding: 55px 0;
    padding: 3.4375rem 0; }
  .pd-sm-0-55 {
    padding: 0 55px;
    padding: 0 3.4375rem; }
  .pdt-sm-55 {
    padding-top: 55px;
    padding-top: 3.4375rem; }
  .pdb-sm-55 {
    padding-bottom: 55px;
    padding-bottom: 3.4375rem; }
  .pdl-sm-55 {
    padding-left: 55px;
    padding-left: 3.4375rem; }
  .pdr-sm-55 {
    padding-right: 55px;
    padding-right: 3.4375rem; }
  .mg-sm-60 {
    margin: 60px;
    margin: 3.75rem; }
  .mg-sm-60-0 {
    margin: 60px 0;
    margin: 3.75rem 0; }
  .mg-sm-0-60 {
    margin: 0 60px;
    margin: 0 3.75rem; }
  .mgt-sm-60 {
    margin-top: 60px;
    margin-top: 3.75rem; }
  .mgb-sm-60 {
    margin-bottom: 60px;
    margin-bottom: 3.75rem; }
  .mgl-sm-60 {
    margin-left: 60px;
    margin-left: 3.75rem; }
  .mgr-sm-60 {
    margin-right: 60px;
    margin-right: 3.75rem; }
  .pd-sm-60 {
    padding: 60px;
    padding: 3.75rem; }
  .pd-sm-60-0 {
    padding: 60px 0;
    padding: 3.75rem 0; }
  .pd-sm-0-60 {
    padding: 0 60px;
    padding: 0 3.75rem; }
  .pdt-sm-60 {
    padding-top: 60px;
    padding-top: 3.75rem; }
  .pdb-sm-60 {
    padding-bottom: 60px;
    padding-bottom: 3.75rem; }
  .pdl-sm-60 {
    padding-left: 60px;
    padding-left: 3.75rem; }
  .pdr-sm-60 {
    padding-right: 60px;
    padding-right: 3.75rem; }
  .mg-sm-65 {
    margin: 65px;
    margin: 4.0625rem; }
  .mg-sm-65-0 {
    margin: 65px 0;
    margin: 4.0625rem 0; }
  .mg-sm-0-65 {
    margin: 0 65px;
    margin: 0 4.0625rem; }
  .mgt-sm-65 {
    margin-top: 65px;
    margin-top: 4.0625rem; }
  .mgb-sm-65 {
    margin-bottom: 65px;
    margin-bottom: 4.0625rem; }
  .mgl-sm-65 {
    margin-left: 65px;
    margin-left: 4.0625rem; }
  .mgr-sm-65 {
    margin-right: 65px;
    margin-right: 4.0625rem; }
  .pd-sm-65 {
    padding: 65px;
    padding: 4.0625rem; }
  .pd-sm-65-0 {
    padding: 65px 0;
    padding: 4.0625rem 0; }
  .pd-sm-0-65 {
    padding: 0 65px;
    padding: 0 4.0625rem; }
  .pdt-sm-65 {
    padding-top: 65px;
    padding-top: 4.0625rem; }
  .pdb-sm-65 {
    padding-bottom: 65px;
    padding-bottom: 4.0625rem; }
  .pdl-sm-65 {
    padding-left: 65px;
    padding-left: 4.0625rem; }
  .pdr-sm-65 {
    padding-right: 65px;
    padding-right: 4.0625rem; }
  .mg-sm-70 {
    margin: 70px;
    margin: 4.375rem; }
  .mg-sm-70-0 {
    margin: 70px 0;
    margin: 4.375rem 0; }
  .mg-sm-0-70 {
    margin: 0 70px;
    margin: 0 4.375rem; }
  .mgt-sm-70 {
    margin-top: 70px;
    margin-top: 4.375rem; }
  .mgb-sm-70 {
    margin-bottom: 70px;
    margin-bottom: 4.375rem; }
  .mgl-sm-70 {
    margin-left: 70px;
    margin-left: 4.375rem; }
  .mgr-sm-70 {
    margin-right: 70px;
    margin-right: 4.375rem; }
  .pd-sm-70 {
    padding: 70px;
    padding: 4.375rem; }
  .pd-sm-70-0 {
    padding: 70px 0;
    padding: 4.375rem 0; }
  .pd-sm-0-70 {
    padding: 0 70px;
    padding: 0 4.375rem; }
  .pdt-sm-70 {
    padding-top: 70px;
    padding-top: 4.375rem; }
  .pdb-sm-70 {
    padding-bottom: 70px;
    padding-bottom: 4.375rem; }
  .pdl-sm-70 {
    padding-left: 70px;
    padding-left: 4.375rem; }
  .pdr-sm-70 {
    padding-right: 70px;
    padding-right: 4.375rem; }
  .mg-sm-75 {
    margin: 75px;
    margin: 4.6875rem; }
  .mg-sm-75-0 {
    margin: 75px 0;
    margin: 4.6875rem 0; }
  .mg-sm-0-75 {
    margin: 0 75px;
    margin: 0 4.6875rem; }
  .mgt-sm-75 {
    margin-top: 75px;
    margin-top: 4.6875rem; }
  .mgb-sm-75 {
    margin-bottom: 75px;
    margin-bottom: 4.6875rem; }
  .mgl-sm-75 {
    margin-left: 75px;
    margin-left: 4.6875rem; }
  .mgr-sm-75 {
    margin-right: 75px;
    margin-right: 4.6875rem; }
  .pd-sm-75 {
    padding: 75px;
    padding: 4.6875rem; }
  .pd-sm-75-0 {
    padding: 75px 0;
    padding: 4.6875rem 0; }
  .pd-sm-0-75 {
    padding: 0 75px;
    padding: 0 4.6875rem; }
  .pdt-sm-75 {
    padding-top: 75px;
    padding-top: 4.6875rem; }
  .pdb-sm-75 {
    padding-bottom: 75px;
    padding-bottom: 4.6875rem; }
  .pdl-sm-75 {
    padding-left: 75px;
    padding-left: 4.6875rem; }
  .pdr-sm-75 {
    padding-right: 75px;
    padding-right: 4.6875rem; }
  .mg-sm-80 {
    margin: 80px;
    margin: 5rem; }
  .mg-sm-80-0 {
    margin: 80px 0;
    margin: 5rem 0; }
  .mg-sm-0-80 {
    margin: 0 80px;
    margin: 0 5rem; }
  .mgt-sm-80 {
    margin-top: 80px;
    margin-top: 5rem; }
  .mgb-sm-80 {
    margin-bottom: 80px;
    margin-bottom: 5rem; }
  .mgl-sm-80 {
    margin-left: 80px;
    margin-left: 5rem; }
  .mgr-sm-80 {
    margin-right: 80px;
    margin-right: 5rem; }
  .pd-sm-80 {
    padding: 80px;
    padding: 5rem; }
  .pd-sm-80-0 {
    padding: 80px 0;
    padding: 5rem 0; }
  .pd-sm-0-80 {
    padding: 0 80px;
    padding: 0 5rem; }
  .pdt-sm-80 {
    padding-top: 80px;
    padding-top: 5rem; }
  .pdb-sm-80 {
    padding-bottom: 80px;
    padding-bottom: 5rem; }
  .pdl-sm-80 {
    padding-left: 80px;
    padding-left: 5rem; }
  .pdr-sm-80 {
    padding-right: 80px;
    padding-right: 5rem; }
  .mg-sm-85 {
    margin: 85px;
    margin: 5.3125rem; }
  .mg-sm-85-0 {
    margin: 85px 0;
    margin: 5.3125rem 0; }
  .mg-sm-0-85 {
    margin: 0 85px;
    margin: 0 5.3125rem; }
  .mgt-sm-85 {
    margin-top: 85px;
    margin-top: 5.3125rem; }
  .mgb-sm-85 {
    margin-bottom: 85px;
    margin-bottom: 5.3125rem; }
  .mgl-sm-85 {
    margin-left: 85px;
    margin-left: 5.3125rem; }
  .mgr-sm-85 {
    margin-right: 85px;
    margin-right: 5.3125rem; }
  .pd-sm-85 {
    padding: 85px;
    padding: 5.3125rem; }
  .pd-sm-85-0 {
    padding: 85px 0;
    padding: 5.3125rem 0; }
  .pd-sm-0-85 {
    padding: 0 85px;
    padding: 0 5.3125rem; }
  .pdt-sm-85 {
    padding-top: 85px;
    padding-top: 5.3125rem; }
  .pdb-sm-85 {
    padding-bottom: 85px;
    padding-bottom: 5.3125rem; }
  .pdl-sm-85 {
    padding-left: 85px;
    padding-left: 5.3125rem; }
  .pdr-sm-85 {
    padding-right: 85px;
    padding-right: 5.3125rem; }
  .mg-sm-90 {
    margin: 90px;
    margin: 5.625rem; }
  .mg-sm-90-0 {
    margin: 90px 0;
    margin: 5.625rem 0; }
  .mg-sm-0-90 {
    margin: 0 90px;
    margin: 0 5.625rem; }
  .mgt-sm-90 {
    margin-top: 90px;
    margin-top: 5.625rem; }
  .mgb-sm-90 {
    margin-bottom: 90px;
    margin-bottom: 5.625rem; }
  .mgl-sm-90 {
    margin-left: 90px;
    margin-left: 5.625rem; }
  .mgr-sm-90 {
    margin-right: 90px;
    margin-right: 5.625rem; }
  .pd-sm-90 {
    padding: 90px;
    padding: 5.625rem; }
  .pd-sm-90-0 {
    padding: 90px 0;
    padding: 5.625rem 0; }
  .pd-sm-0-90 {
    padding: 0 90px;
    padding: 0 5.625rem; }
  .pdt-sm-90 {
    padding-top: 90px;
    padding-top: 5.625rem; }
  .pdb-sm-90 {
    padding-bottom: 90px;
    padding-bottom: 5.625rem; }
  .pdl-sm-90 {
    padding-left: 90px;
    padding-left: 5.625rem; }
  .pdr-sm-90 {
    padding-right: 90px;
    padding-right: 5.625rem; }
  .mg-sm-95 {
    margin: 95px;
    margin: 5.9375rem; }
  .mg-sm-95-0 {
    margin: 95px 0;
    margin: 5.9375rem 0; }
  .mg-sm-0-95 {
    margin: 0 95px;
    margin: 0 5.9375rem; }
  .mgt-sm-95 {
    margin-top: 95px;
    margin-top: 5.9375rem; }
  .mgb-sm-95 {
    margin-bottom: 95px;
    margin-bottom: 5.9375rem; }
  .mgl-sm-95 {
    margin-left: 95px;
    margin-left: 5.9375rem; }
  .mgr-sm-95 {
    margin-right: 95px;
    margin-right: 5.9375rem; }
  .pd-sm-95 {
    padding: 95px;
    padding: 5.9375rem; }
  .pd-sm-95-0 {
    padding: 95px 0;
    padding: 5.9375rem 0; }
  .pd-sm-0-95 {
    padding: 0 95px;
    padding: 0 5.9375rem; }
  .pdt-sm-95 {
    padding-top: 95px;
    padding-top: 5.9375rem; }
  .pdb-sm-95 {
    padding-bottom: 95px;
    padding-bottom: 5.9375rem; }
  .pdl-sm-95 {
    padding-left: 95px;
    padding-left: 5.9375rem; }
  .pdr-sm-95 {
    padding-right: 95px;
    padding-right: 5.9375rem; }
  .mg-sm-100 {
    margin: 100px;
    margin: 6.25rem; }
  .mg-sm-100-0 {
    margin: 100px 0;
    margin: 6.25rem 0; }
  .mg-sm-0-100 {
    margin: 0 100px;
    margin: 0 6.25rem; }
  .mgt-sm-100 {
    margin-top: 100px;
    margin-top: 6.25rem; }
  .mgb-sm-100 {
    margin-bottom: 100px;
    margin-bottom: 6.25rem; }
  .mgl-sm-100 {
    margin-left: 100px;
    margin-left: 6.25rem; }
  .mgr-sm-100 {
    margin-right: 100px;
    margin-right: 6.25rem; }
  .pd-sm-100 {
    padding: 100px;
    padding: 6.25rem; }
  .pd-sm-100-0 {
    padding: 100px 0;
    padding: 6.25rem 0; }
  .pd-sm-0-100 {
    padding: 0 100px;
    padding: 0 6.25rem; }
  .pdt-sm-100 {
    padding-top: 100px;
    padding-top: 6.25rem; }
  .pdb-sm-100 {
    padding-bottom: 100px;
    padding-bottom: 6.25rem; }
  .pdl-sm-100 {
    padding-left: 100px;
    padding-left: 6.25rem; }
  .pdr-sm-100 {
    padding-right: 100px;
    padding-right: 6.25rem; }
  .mg-sm-105 {
    margin: 105px;
    margin: 6.5625rem; }
  .mg-sm-105-0 {
    margin: 105px 0;
    margin: 6.5625rem 0; }
  .mg-sm-0-105 {
    margin: 0 105px;
    margin: 0 6.5625rem; }
  .mgt-sm-105 {
    margin-top: 105px;
    margin-top: 6.5625rem; }
  .mgb-sm-105 {
    margin-bottom: 105px;
    margin-bottom: 6.5625rem; }
  .mgl-sm-105 {
    margin-left: 105px;
    margin-left: 6.5625rem; }
  .mgr-sm-105 {
    margin-right: 105px;
    margin-right: 6.5625rem; }
  .pd-sm-105 {
    padding: 105px;
    padding: 6.5625rem; }
  .pd-sm-105-0 {
    padding: 105px 0;
    padding: 6.5625rem 0; }
  .pd-sm-0-105 {
    padding: 0 105px;
    padding: 0 6.5625rem; }
  .pdt-sm-105 {
    padding-top: 105px;
    padding-top: 6.5625rem; }
  .pdb-sm-105 {
    padding-bottom: 105px;
    padding-bottom: 6.5625rem; }
  .pdl-sm-105 {
    padding-left: 105px;
    padding-left: 6.5625rem; }
  .pdr-sm-105 {
    padding-right: 105px;
    padding-right: 6.5625rem; }
  .mg-sm-110 {
    margin: 110px;
    margin: 6.875rem; }
  .mg-sm-110-0 {
    margin: 110px 0;
    margin: 6.875rem 0; }
  .mg-sm-0-110 {
    margin: 0 110px;
    margin: 0 6.875rem; }
  .mgt-sm-110 {
    margin-top: 110px;
    margin-top: 6.875rem; }
  .mgb-sm-110 {
    margin-bottom: 110px;
    margin-bottom: 6.875rem; }
  .mgl-sm-110 {
    margin-left: 110px;
    margin-left: 6.875rem; }
  .mgr-sm-110 {
    margin-right: 110px;
    margin-right: 6.875rem; }
  .pd-sm-110 {
    padding: 110px;
    padding: 6.875rem; }
  .pd-sm-110-0 {
    padding: 110px 0;
    padding: 6.875rem 0; }
  .pd-sm-0-110 {
    padding: 0 110px;
    padding: 0 6.875rem; }
  .pdt-sm-110 {
    padding-top: 110px;
    padding-top: 6.875rem; }
  .pdb-sm-110 {
    padding-bottom: 110px;
    padding-bottom: 6.875rem; }
  .pdl-sm-110 {
    padding-left: 110px;
    padding-left: 6.875rem; }
  .pdr-sm-110 {
    padding-right: 110px;
    padding-right: 6.875rem; }
  .mg-sm-115 {
    margin: 115px;
    margin: 7.1875rem; }
  .mg-sm-115-0 {
    margin: 115px 0;
    margin: 7.1875rem 0; }
  .mg-sm-0-115 {
    margin: 0 115px;
    margin: 0 7.1875rem; }
  .mgt-sm-115 {
    margin-top: 115px;
    margin-top: 7.1875rem; }
  .mgb-sm-115 {
    margin-bottom: 115px;
    margin-bottom: 7.1875rem; }
  .mgl-sm-115 {
    margin-left: 115px;
    margin-left: 7.1875rem; }
  .mgr-sm-115 {
    margin-right: 115px;
    margin-right: 7.1875rem; }
  .pd-sm-115 {
    padding: 115px;
    padding: 7.1875rem; }
  .pd-sm-115-0 {
    padding: 115px 0;
    padding: 7.1875rem 0; }
  .pd-sm-0-115 {
    padding: 0 115px;
    padding: 0 7.1875rem; }
  .pdt-sm-115 {
    padding-top: 115px;
    padding-top: 7.1875rem; }
  .pdb-sm-115 {
    padding-bottom: 115px;
    padding-bottom: 7.1875rem; }
  .pdl-sm-115 {
    padding-left: 115px;
    padding-left: 7.1875rem; }
  .pdr-sm-115 {
    padding-right: 115px;
    padding-right: 7.1875rem; }
  .mg-sm-120 {
    margin: 120px;
    margin: 7.5rem; }
  .mg-sm-120-0 {
    margin: 120px 0;
    margin: 7.5rem 0; }
  .mg-sm-0-120 {
    margin: 0 120px;
    margin: 0 7.5rem; }
  .mgt-sm-120 {
    margin-top: 120px;
    margin-top: 7.5rem; }
  .mgb-sm-120 {
    margin-bottom: 120px;
    margin-bottom: 7.5rem; }
  .mgl-sm-120 {
    margin-left: 120px;
    margin-left: 7.5rem; }
  .mgr-sm-120 {
    margin-right: 120px;
    margin-right: 7.5rem; }
  .pd-sm-120 {
    padding: 120px;
    padding: 7.5rem; }
  .pd-sm-120-0 {
    padding: 120px 0;
    padding: 7.5rem 0; }
  .pd-sm-0-120 {
    padding: 0 120px;
    padding: 0 7.5rem; }
  .pdt-sm-120 {
    padding-top: 120px;
    padding-top: 7.5rem; }
  .pdb-sm-120 {
    padding-bottom: 120px;
    padding-bottom: 7.5rem; }
  .pdl-sm-120 {
    padding-left: 120px;
    padding-left: 7.5rem; }
  .pdr-sm-120 {
    padding-right: 120px;
    padding-right: 7.5rem; }
  .mg-sm-125 {
    margin: 125px;
    margin: 7.8125rem; }
  .mg-sm-125-0 {
    margin: 125px 0;
    margin: 7.8125rem 0; }
  .mg-sm-0-125 {
    margin: 0 125px;
    margin: 0 7.8125rem; }
  .mgt-sm-125 {
    margin-top: 125px;
    margin-top: 7.8125rem; }
  .mgb-sm-125 {
    margin-bottom: 125px;
    margin-bottom: 7.8125rem; }
  .mgl-sm-125 {
    margin-left: 125px;
    margin-left: 7.8125rem; }
  .mgr-sm-125 {
    margin-right: 125px;
    margin-right: 7.8125rem; }
  .pd-sm-125 {
    padding: 125px;
    padding: 7.8125rem; }
  .pd-sm-125-0 {
    padding: 125px 0;
    padding: 7.8125rem 0; }
  .pd-sm-0-125 {
    padding: 0 125px;
    padding: 0 7.8125rem; }
  .pdt-sm-125 {
    padding-top: 125px;
    padding-top: 7.8125rem; }
  .pdb-sm-125 {
    padding-bottom: 125px;
    padding-bottom: 7.8125rem; }
  .pdl-sm-125 {
    padding-left: 125px;
    padding-left: 7.8125rem; }
  .pdr-sm-125 {
    padding-right: 125px;
    padding-right: 7.8125rem; }
  .mg-sm-130 {
    margin: 130px;
    margin: 8.125rem; }
  .mg-sm-130-0 {
    margin: 130px 0;
    margin: 8.125rem 0; }
  .mg-sm-0-130 {
    margin: 0 130px;
    margin: 0 8.125rem; }
  .mgt-sm-130 {
    margin-top: 130px;
    margin-top: 8.125rem; }
  .mgb-sm-130 {
    margin-bottom: 130px;
    margin-bottom: 8.125rem; }
  .mgl-sm-130 {
    margin-left: 130px;
    margin-left: 8.125rem; }
  .mgr-sm-130 {
    margin-right: 130px;
    margin-right: 8.125rem; }
  .pd-sm-130 {
    padding: 130px;
    padding: 8.125rem; }
  .pd-sm-130-0 {
    padding: 130px 0;
    padding: 8.125rem 0; }
  .pd-sm-0-130 {
    padding: 0 130px;
    padding: 0 8.125rem; }
  .pdt-sm-130 {
    padding-top: 130px;
    padding-top: 8.125rem; }
  .pdb-sm-130 {
    padding-bottom: 130px;
    padding-bottom: 8.125rem; }
  .pdl-sm-130 {
    padding-left: 130px;
    padding-left: 8.125rem; }
  .pdr-sm-130 {
    padding-right: 130px;
    padding-right: 8.125rem; }
  .mg-sm-135 {
    margin: 135px;
    margin: 8.4375rem; }
  .mg-sm-135-0 {
    margin: 135px 0;
    margin: 8.4375rem 0; }
  .mg-sm-0-135 {
    margin: 0 135px;
    margin: 0 8.4375rem; }
  .mgt-sm-135 {
    margin-top: 135px;
    margin-top: 8.4375rem; }
  .mgb-sm-135 {
    margin-bottom: 135px;
    margin-bottom: 8.4375rem; }
  .mgl-sm-135 {
    margin-left: 135px;
    margin-left: 8.4375rem; }
  .mgr-sm-135 {
    margin-right: 135px;
    margin-right: 8.4375rem; }
  .pd-sm-135 {
    padding: 135px;
    padding: 8.4375rem; }
  .pd-sm-135-0 {
    padding: 135px 0;
    padding: 8.4375rem 0; }
  .pd-sm-0-135 {
    padding: 0 135px;
    padding: 0 8.4375rem; }
  .pdt-sm-135 {
    padding-top: 135px;
    padding-top: 8.4375rem; }
  .pdb-sm-135 {
    padding-bottom: 135px;
    padding-bottom: 8.4375rem; }
  .pdl-sm-135 {
    padding-left: 135px;
    padding-left: 8.4375rem; }
  .pdr-sm-135 {
    padding-right: 135px;
    padding-right: 8.4375rem; }
  .mg-sm-140 {
    margin: 140px;
    margin: 8.75rem; }
  .mg-sm-140-0 {
    margin: 140px 0;
    margin: 8.75rem 0; }
  .mg-sm-0-140 {
    margin: 0 140px;
    margin: 0 8.75rem; }
  .mgt-sm-140 {
    margin-top: 140px;
    margin-top: 8.75rem; }
  .mgb-sm-140 {
    margin-bottom: 140px;
    margin-bottom: 8.75rem; }
  .mgl-sm-140 {
    margin-left: 140px;
    margin-left: 8.75rem; }
  .mgr-sm-140 {
    margin-right: 140px;
    margin-right: 8.75rem; }
  .pd-sm-140 {
    padding: 140px;
    padding: 8.75rem; }
  .pd-sm-140-0 {
    padding: 140px 0;
    padding: 8.75rem 0; }
  .pd-sm-0-140 {
    padding: 0 140px;
    padding: 0 8.75rem; }
  .pdt-sm-140 {
    padding-top: 140px;
    padding-top: 8.75rem; }
  .pdb-sm-140 {
    padding-bottom: 140px;
    padding-bottom: 8.75rem; }
  .pdl-sm-140 {
    padding-left: 140px;
    padding-left: 8.75rem; }
  .pdr-sm-140 {
    padding-right: 140px;
    padding-right: 8.75rem; }
  .mg-sm-145 {
    margin: 145px;
    margin: 9.0625rem; }
  .mg-sm-145-0 {
    margin: 145px 0;
    margin: 9.0625rem 0; }
  .mg-sm-0-145 {
    margin: 0 145px;
    margin: 0 9.0625rem; }
  .mgt-sm-145 {
    margin-top: 145px;
    margin-top: 9.0625rem; }
  .mgb-sm-145 {
    margin-bottom: 145px;
    margin-bottom: 9.0625rem; }
  .mgl-sm-145 {
    margin-left: 145px;
    margin-left: 9.0625rem; }
  .mgr-sm-145 {
    margin-right: 145px;
    margin-right: 9.0625rem; }
  .pd-sm-145 {
    padding: 145px;
    padding: 9.0625rem; }
  .pd-sm-145-0 {
    padding: 145px 0;
    padding: 9.0625rem 0; }
  .pd-sm-0-145 {
    padding: 0 145px;
    padding: 0 9.0625rem; }
  .pdt-sm-145 {
    padding-top: 145px;
    padding-top: 9.0625rem; }
  .pdb-sm-145 {
    padding-bottom: 145px;
    padding-bottom: 9.0625rem; }
  .pdl-sm-145 {
    padding-left: 145px;
    padding-left: 9.0625rem; }
  .pdr-sm-145 {
    padding-right: 145px;
    padding-right: 9.0625rem; }
  .mg-sm-150 {
    margin: 150px;
    margin: 9.375rem; }
  .mg-sm-150-0 {
    margin: 150px 0;
    margin: 9.375rem 0; }
  .mg-sm-0-150 {
    margin: 0 150px;
    margin: 0 9.375rem; }
  .mgt-sm-150 {
    margin-top: 150px;
    margin-top: 9.375rem; }
  .mgb-sm-150 {
    margin-bottom: 150px;
    margin-bottom: 9.375rem; }
  .mgl-sm-150 {
    margin-left: 150px;
    margin-left: 9.375rem; }
  .mgr-sm-150 {
    margin-right: 150px;
    margin-right: 9.375rem; }
  .pd-sm-150 {
    padding: 150px;
    padding: 9.375rem; }
  .pd-sm-150-0 {
    padding: 150px 0;
    padding: 9.375rem 0; }
  .pd-sm-0-150 {
    padding: 0 150px;
    padding: 0 9.375rem; }
  .pdt-sm-150 {
    padding-top: 150px;
    padding-top: 9.375rem; }
  .pdb-sm-150 {
    padding-bottom: 150px;
    padding-bottom: 9.375rem; }
  .pdl-sm-150 {
    padding-left: 150px;
    padding-left: 9.375rem; }
  .pdr-sm-150 {
    padding-right: 150px;
    padding-right: 9.375rem; } }

@media (min-width: 768px) {
  .text-md-normal {
    font-weight: 400; }
  .text-md-semibold {
    font-weight: 600; }
  .text-md-bold {
    font-weight: 700; }
  .text-md-right {
    text-align: right; }
  .text-md-center {
    text-align: center; }
  .text-md-justify {
    text-align: justify; }
  .text-md-nowrap {
    white-space: nowrap; }
  .text-md-lowercase {
    text-transform: lowercase; }
  .text-md-uppercase {
    text-transform: uppercase; }
  .mg-md-0 {
    margin: 0; }
  .mgt-md-0 {
    margin-top: 0; }
  .mgb-md-0 {
    margin-bottom: 0; }
  .mgl-md-0 {
    margin-left: 0; }
  .mgr-md-0 {
    margin-right: 0; }
  .pd-0md- {
    padding: 0; }
  .pdt-md-0 {
    padding-top: 0; }
  .pdb-md-0 {
    padding-bottom: 0; }
  .pdl-md-0 {
    padding-left: 0; }
  .pdr-md-0 {
    padding-right: 0; }
  .mg-md-5 {
    margin: 5px;
    margin: 0.3125rem; }
  .mg-md-5-0 {
    margin: 5px 0;
    margin: 0.3125rem 0; }
  .mg-md-0-5 {
    margin: 0 5px;
    margin: 0 0.3125rem; }
  .mgt-md-5 {
    margin-top: 5px;
    margin-top: 0.3125rem; }
  .mgb-md-5 {
    margin-bottom: 5px;
    margin-bottom: 0.3125rem; }
  .mgl-md-5 {
    margin-left: 5px;
    margin-left: 0.3125rem; }
  .mgr-md-5 {
    margin-right: 5px;
    margin-right: 0.3125rem; }
  .pd-md-5 {
    padding: 5px;
    padding: 0.3125rem; }
  .pd-md-5-0 {
    padding: 5px 0;
    padding: 0.3125rem 0; }
  .pd-md-0-5 {
    padding: 0 5px;
    padding: 0 0.3125rem; }
  .md-pdt-5 {
    padding-top: 5px;
    padding-top: 0.3125rem; }
  .pdb-md-5 {
    padding-bottom: 5px;
    padding-bottom: 0.3125rem; }
  .pdl-md-5 {
    padding-left: 5px;
    padding-left: 0.3125rem; }
  .pdr-md-5 {
    padding-right: 5px;
    padding-right: 0.3125rem; }
  .mg-md-10 {
    margin: 10px;
    margin: 0.625rem; }
  .mg-md-10-0 {
    margin: 10px 0;
    margin: 0.625rem 0; }
  .mg-md-0-10 {
    margin: 0 10px;
    margin: 0 0.625rem; }
  .mgt-md-10 {
    margin-top: 10px;
    margin-top: 0.625rem; }
  .mgb-md-10 {
    margin-bottom: 10px;
    margin-bottom: 0.625rem; }
  .mgl-md-10 {
    margin-left: 10px;
    margin-left: 0.625rem; }
  .mgr-md-10 {
    margin-right: 10px;
    margin-right: 0.625rem; }
  .pd-md-10 {
    padding: 10px;
    padding: 0.625rem; }
  .pd-md-10-0 {
    padding: 10px 0;
    padding: 0.625rem 0; }
  .pd-md-0-10 {
    padding: 0 10px;
    padding: 0 0.625rem; }
  .md-pdt-10 {
    padding-top: 10px;
    padding-top: 0.625rem; }
  .pdb-md-10 {
    padding-bottom: 10px;
    padding-bottom: 0.625rem; }
  .pdl-md-10 {
    padding-left: 10px;
    padding-left: 0.625rem; }
  .pdr-md-10 {
    padding-right: 10px;
    padding-right: 0.625rem; }
  .mg-md-15 {
    margin: 15px;
    margin: 0.9375rem; }
  .mg-md-15-0 {
    margin: 15px 0;
    margin: 0.9375rem 0; }
  .mg-md-0-15 {
    margin: 0 15px;
    margin: 0 0.9375rem; }
  .mgt-md-15 {
    margin-top: 15px;
    margin-top: 0.9375rem; }
  .mgb-md-15 {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem; }
  .mgl-md-15 {
    margin-left: 15px;
    margin-left: 0.9375rem; }
  .mgr-md-15 {
    margin-right: 15px;
    margin-right: 0.9375rem; }
  .pd-md-15 {
    padding: 15px;
    padding: 0.9375rem; }
  .pd-md-15-0 {
    padding: 15px 0;
    padding: 0.9375rem 0; }
  .pd-md-0-15 {
    padding: 0 15px;
    padding: 0 0.9375rem; }
  .md-pdt-15 {
    padding-top: 15px;
    padding-top: 0.9375rem; }
  .pdb-md-15 {
    padding-bottom: 15px;
    padding-bottom: 0.9375rem; }
  .pdl-md-15 {
    padding-left: 15px;
    padding-left: 0.9375rem; }
  .pdr-md-15 {
    padding-right: 15px;
    padding-right: 0.9375rem; }
  .mg-md-20 {
    margin: 20px;
    margin: 1.25rem; }
  .mg-md-20-0 {
    margin: 20px 0;
    margin: 1.25rem 0; }
  .mg-md-0-20 {
    margin: 0 20px;
    margin: 0 1.25rem; }
  .mgt-md-20 {
    margin-top: 20px;
    margin-top: 1.25rem; }
  .mgb-md-20 {
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  .mgl-md-20 {
    margin-left: 20px;
    margin-left: 1.25rem; }
  .mgr-md-20 {
    margin-right: 20px;
    margin-right: 1.25rem; }
  .pd-md-20 {
    padding: 20px;
    padding: 1.25rem; }
  .pd-md-20-0 {
    padding: 20px 0;
    padding: 1.25rem 0; }
  .pd-md-0-20 {
    padding: 0 20px;
    padding: 0 1.25rem; }
  .md-pdt-20 {
    padding-top: 20px;
    padding-top: 1.25rem; }
  .pdb-md-20 {
    padding-bottom: 20px;
    padding-bottom: 1.25rem; }
  .pdl-md-20 {
    padding-left: 20px;
    padding-left: 1.25rem; }
  .pdr-md-20 {
    padding-right: 20px;
    padding-right: 1.25rem; }
  .mg-md-25 {
    margin: 25px;
    margin: 1.5625rem; }
  .mg-md-25-0 {
    margin: 25px 0;
    margin: 1.5625rem 0; }
  .mg-md-0-25 {
    margin: 0 25px;
    margin: 0 1.5625rem; }
  .mgt-md-25 {
    margin-top: 25px;
    margin-top: 1.5625rem; }
  .mgb-md-25 {
    margin-bottom: 25px;
    margin-bottom: 1.5625rem; }
  .mgl-md-25 {
    margin-left: 25px;
    margin-left: 1.5625rem; }
  .mgr-md-25 {
    margin-right: 25px;
    margin-right: 1.5625rem; }
  .pd-md-25 {
    padding: 25px;
    padding: 1.5625rem; }
  .pd-md-25-0 {
    padding: 25px 0;
    padding: 1.5625rem 0; }
  .pd-md-0-25 {
    padding: 0 25px;
    padding: 0 1.5625rem; }
  .md-pdt-25 {
    padding-top: 25px;
    padding-top: 1.5625rem; }
  .pdb-md-25 {
    padding-bottom: 25px;
    padding-bottom: 1.5625rem; }
  .pdl-md-25 {
    padding-left: 25px;
    padding-left: 1.5625rem; }
  .pdr-md-25 {
    padding-right: 25px;
    padding-right: 1.5625rem; }
  .mg-md-30 {
    margin: 30px;
    margin: 1.875rem; }
  .mg-md-30-0 {
    margin: 30px 0;
    margin: 1.875rem 0; }
  .mg-md-0-30 {
    margin: 0 30px;
    margin: 0 1.875rem; }
  .mgt-md-30 {
    margin-top: 30px;
    margin-top: 1.875rem; }
  .mgb-md-30 {
    margin-bottom: 30px;
    margin-bottom: 1.875rem; }
  .mgl-md-30 {
    margin-left: 30px;
    margin-left: 1.875rem; }
  .mgr-md-30 {
    margin-right: 30px;
    margin-right: 1.875rem; }
  .pd-md-30 {
    padding: 30px;
    padding: 1.875rem; }
  .pd-md-30-0 {
    padding: 30px 0;
    padding: 1.875rem 0; }
  .pd-md-0-30 {
    padding: 0 30px;
    padding: 0 1.875rem; }
  .md-pdt-30 {
    padding-top: 30px;
    padding-top: 1.875rem; }
  .pdb-md-30 {
    padding-bottom: 30px;
    padding-bottom: 1.875rem; }
  .pdl-md-30 {
    padding-left: 30px;
    padding-left: 1.875rem; }
  .pdr-md-30 {
    padding-right: 30px;
    padding-right: 1.875rem; }
  .mg-md-35 {
    margin: 35px;
    margin: 2.1875rem; }
  .mg-md-35-0 {
    margin: 35px 0;
    margin: 2.1875rem 0; }
  .mg-md-0-35 {
    margin: 0 35px;
    margin: 0 2.1875rem; }
  .mgt-md-35 {
    margin-top: 35px;
    margin-top: 2.1875rem; }
  .mgb-md-35 {
    margin-bottom: 35px;
    margin-bottom: 2.1875rem; }
  .mgl-md-35 {
    margin-left: 35px;
    margin-left: 2.1875rem; }
  .mgr-md-35 {
    margin-right: 35px;
    margin-right: 2.1875rem; }
  .pd-md-35 {
    padding: 35px;
    padding: 2.1875rem; }
  .pd-md-35-0 {
    padding: 35px 0;
    padding: 2.1875rem 0; }
  .pd-md-0-35 {
    padding: 0 35px;
    padding: 0 2.1875rem; }
  .md-pdt-35 {
    padding-top: 35px;
    padding-top: 2.1875rem; }
  .pdb-md-35 {
    padding-bottom: 35px;
    padding-bottom: 2.1875rem; }
  .pdl-md-35 {
    padding-left: 35px;
    padding-left: 2.1875rem; }
  .pdr-md-35 {
    padding-right: 35px;
    padding-right: 2.1875rem; }
  .mg-md-40 {
    margin: 40px;
    margin: 2.5rem; }
  .mg-md-40-0 {
    margin: 40px 0;
    margin: 2.5rem 0; }
  .mg-md-0-40 {
    margin: 0 40px;
    margin: 0 2.5rem; }
  .mgt-md-40 {
    margin-top: 40px;
    margin-top: 2.5rem; }
  .mgb-md-40 {
    margin-bottom: 40px;
    margin-bottom: 2.5rem; }
  .mgl-md-40 {
    margin-left: 40px;
    margin-left: 2.5rem; }
  .mgr-md-40 {
    margin-right: 40px;
    margin-right: 2.5rem; }
  .pd-md-40 {
    padding: 40px;
    padding: 2.5rem; }
  .pd-md-40-0 {
    padding: 40px 0;
    padding: 2.5rem 0; }
  .pd-md-0-40 {
    padding: 0 40px;
    padding: 0 2.5rem; }
  .md-pdt-40 {
    padding-top: 40px;
    padding-top: 2.5rem; }
  .pdb-md-40 {
    padding-bottom: 40px;
    padding-bottom: 2.5rem; }
  .pdl-md-40 {
    padding-left: 40px;
    padding-left: 2.5rem; }
  .pdr-md-40 {
    padding-right: 40px;
    padding-right: 2.5rem; }
  .mg-md-45 {
    margin: 45px;
    margin: 2.8125rem; }
  .mg-md-45-0 {
    margin: 45px 0;
    margin: 2.8125rem 0; }
  .mg-md-0-45 {
    margin: 0 45px;
    margin: 0 2.8125rem; }
  .mgt-md-45 {
    margin-top: 45px;
    margin-top: 2.8125rem; }
  .mgb-md-45 {
    margin-bottom: 45px;
    margin-bottom: 2.8125rem; }
  .mgl-md-45 {
    margin-left: 45px;
    margin-left: 2.8125rem; }
  .mgr-md-45 {
    margin-right: 45px;
    margin-right: 2.8125rem; }
  .pd-md-45 {
    padding: 45px;
    padding: 2.8125rem; }
  .pd-md-45-0 {
    padding: 45px 0;
    padding: 2.8125rem 0; }
  .pd-md-0-45 {
    padding: 0 45px;
    padding: 0 2.8125rem; }
  .md-pdt-45 {
    padding-top: 45px;
    padding-top: 2.8125rem; }
  .pdb-md-45 {
    padding-bottom: 45px;
    padding-bottom: 2.8125rem; }
  .pdl-md-45 {
    padding-left: 45px;
    padding-left: 2.8125rem; }
  .pdr-md-45 {
    padding-right: 45px;
    padding-right: 2.8125rem; }
  .mg-md-50 {
    margin: 50px;
    margin: 3.125rem; }
  .mg-md-50-0 {
    margin: 50px 0;
    margin: 3.125rem 0; }
  .mg-md-0-50 {
    margin: 0 50px;
    margin: 0 3.125rem; }
  .mgt-md-50 {
    margin-top: 50px;
    margin-top: 3.125rem; }
  .mgb-md-50 {
    margin-bottom: 50px;
    margin-bottom: 3.125rem; }
  .mgl-md-50 {
    margin-left: 50px;
    margin-left: 3.125rem; }
  .mgr-md-50 {
    margin-right: 50px;
    margin-right: 3.125rem; }
  .pd-md-50 {
    padding: 50px;
    padding: 3.125rem; }
  .pd-md-50-0 {
    padding: 50px 0;
    padding: 3.125rem 0; }
  .pd-md-0-50 {
    padding: 0 50px;
    padding: 0 3.125rem; }
  .md-pdt-50 {
    padding-top: 50px;
    padding-top: 3.125rem; }
  .pdb-md-50 {
    padding-bottom: 50px;
    padding-bottom: 3.125rem; }
  .pdl-md-50 {
    padding-left: 50px;
    padding-left: 3.125rem; }
  .pdr-md-50 {
    padding-right: 50px;
    padding-right: 3.125rem; }
  .mg-md-55 {
    margin: 55px;
    margin: 3.4375rem; }
  .mg-md-55-0 {
    margin: 55px 0;
    margin: 3.4375rem 0; }
  .mg-md-0-55 {
    margin: 0 55px;
    margin: 0 3.4375rem; }
  .mgt-md-55 {
    margin-top: 55px;
    margin-top: 3.4375rem; }
  .mgb-md-55 {
    margin-bottom: 55px;
    margin-bottom: 3.4375rem; }
  .mgl-md-55 {
    margin-left: 55px;
    margin-left: 3.4375rem; }
  .mgr-md-55 {
    margin-right: 55px;
    margin-right: 3.4375rem; }
  .pd-md-55 {
    padding: 55px;
    padding: 3.4375rem; }
  .pd-md-55-0 {
    padding: 55px 0;
    padding: 3.4375rem 0; }
  .pd-md-0-55 {
    padding: 0 55px;
    padding: 0 3.4375rem; }
  .md-pdt-55 {
    padding-top: 55px;
    padding-top: 3.4375rem; }
  .pdb-md-55 {
    padding-bottom: 55px;
    padding-bottom: 3.4375rem; }
  .pdl-md-55 {
    padding-left: 55px;
    padding-left: 3.4375rem; }
  .pdr-md-55 {
    padding-right: 55px;
    padding-right: 3.4375rem; }
  .mg-md-60 {
    margin: 60px;
    margin: 3.75rem; }
  .mg-md-60-0 {
    margin: 60px 0;
    margin: 3.75rem 0; }
  .mg-md-0-60 {
    margin: 0 60px;
    margin: 0 3.75rem; }
  .mgt-md-60 {
    margin-top: 60px;
    margin-top: 3.75rem; }
  .mgb-md-60 {
    margin-bottom: 60px;
    margin-bottom: 3.75rem; }
  .mgl-md-60 {
    margin-left: 60px;
    margin-left: 3.75rem; }
  .mgr-md-60 {
    margin-right: 60px;
    margin-right: 3.75rem; }
  .pd-md-60 {
    padding: 60px;
    padding: 3.75rem; }
  .pd-md-60-0 {
    padding: 60px 0;
    padding: 3.75rem 0; }
  .pd-md-0-60 {
    padding: 0 60px;
    padding: 0 3.75rem; }
  .md-pdt-60 {
    padding-top: 60px;
    padding-top: 3.75rem; }
  .pdb-md-60 {
    padding-bottom: 60px;
    padding-bottom: 3.75rem; }
  .pdl-md-60 {
    padding-left: 60px;
    padding-left: 3.75rem; }
  .pdr-md-60 {
    padding-right: 60px;
    padding-right: 3.75rem; }
  .mg-md-65 {
    margin: 65px;
    margin: 4.0625rem; }
  .mg-md-65-0 {
    margin: 65px 0;
    margin: 4.0625rem 0; }
  .mg-md-0-65 {
    margin: 0 65px;
    margin: 0 4.0625rem; }
  .mgt-md-65 {
    margin-top: 65px;
    margin-top: 4.0625rem; }
  .mgb-md-65 {
    margin-bottom: 65px;
    margin-bottom: 4.0625rem; }
  .mgl-md-65 {
    margin-left: 65px;
    margin-left: 4.0625rem; }
  .mgr-md-65 {
    margin-right: 65px;
    margin-right: 4.0625rem; }
  .pd-md-65 {
    padding: 65px;
    padding: 4.0625rem; }
  .pd-md-65-0 {
    padding: 65px 0;
    padding: 4.0625rem 0; }
  .pd-md-0-65 {
    padding: 0 65px;
    padding: 0 4.0625rem; }
  .md-pdt-65 {
    padding-top: 65px;
    padding-top: 4.0625rem; }
  .pdb-md-65 {
    padding-bottom: 65px;
    padding-bottom: 4.0625rem; }
  .pdl-md-65 {
    padding-left: 65px;
    padding-left: 4.0625rem; }
  .pdr-md-65 {
    padding-right: 65px;
    padding-right: 4.0625rem; }
  .mg-md-70 {
    margin: 70px;
    margin: 4.375rem; }
  .mg-md-70-0 {
    margin: 70px 0;
    margin: 4.375rem 0; }
  .mg-md-0-70 {
    margin: 0 70px;
    margin: 0 4.375rem; }
  .mgt-md-70 {
    margin-top: 70px;
    margin-top: 4.375rem; }
  .mgb-md-70 {
    margin-bottom: 70px;
    margin-bottom: 4.375rem; }
  .mgl-md-70 {
    margin-left: 70px;
    margin-left: 4.375rem; }
  .mgr-md-70 {
    margin-right: 70px;
    margin-right: 4.375rem; }
  .pd-md-70 {
    padding: 70px;
    padding: 4.375rem; }
  .pd-md-70-0 {
    padding: 70px 0;
    padding: 4.375rem 0; }
  .pd-md-0-70 {
    padding: 0 70px;
    padding: 0 4.375rem; }
  .md-pdt-70 {
    padding-top: 70px;
    padding-top: 4.375rem; }
  .pdb-md-70 {
    padding-bottom: 70px;
    padding-bottom: 4.375rem; }
  .pdl-md-70 {
    padding-left: 70px;
    padding-left: 4.375rem; }
  .pdr-md-70 {
    padding-right: 70px;
    padding-right: 4.375rem; }
  .mg-md-75 {
    margin: 75px;
    margin: 4.6875rem; }
  .mg-md-75-0 {
    margin: 75px 0;
    margin: 4.6875rem 0; }
  .mg-md-0-75 {
    margin: 0 75px;
    margin: 0 4.6875rem; }
  .mgt-md-75 {
    margin-top: 75px;
    margin-top: 4.6875rem; }
  .mgb-md-75 {
    margin-bottom: 75px;
    margin-bottom: 4.6875rem; }
  .mgl-md-75 {
    margin-left: 75px;
    margin-left: 4.6875rem; }
  .mgr-md-75 {
    margin-right: 75px;
    margin-right: 4.6875rem; }
  .pd-md-75 {
    padding: 75px;
    padding: 4.6875rem; }
  .pd-md-75-0 {
    padding: 75px 0;
    padding: 4.6875rem 0; }
  .pd-md-0-75 {
    padding: 0 75px;
    padding: 0 4.6875rem; }
  .md-pdt-75 {
    padding-top: 75px;
    padding-top: 4.6875rem; }
  .pdb-md-75 {
    padding-bottom: 75px;
    padding-bottom: 4.6875rem; }
  .pdl-md-75 {
    padding-left: 75px;
    padding-left: 4.6875rem; }
  .pdr-md-75 {
    padding-right: 75px;
    padding-right: 4.6875rem; }
  .mg-md-80 {
    margin: 80px;
    margin: 5rem; }
  .mg-md-80-0 {
    margin: 80px 0;
    margin: 5rem 0; }
  .mg-md-0-80 {
    margin: 0 80px;
    margin: 0 5rem; }
  .mgt-md-80 {
    margin-top: 80px;
    margin-top: 5rem; }
  .mgb-md-80 {
    margin-bottom: 80px;
    margin-bottom: 5rem; }
  .mgl-md-80 {
    margin-left: 80px;
    margin-left: 5rem; }
  .mgr-md-80 {
    margin-right: 80px;
    margin-right: 5rem; }
  .pd-md-80 {
    padding: 80px;
    padding: 5rem; }
  .pd-md-80-0 {
    padding: 80px 0;
    padding: 5rem 0; }
  .pd-md-0-80 {
    padding: 0 80px;
    padding: 0 5rem; }
  .md-pdt-80 {
    padding-top: 80px;
    padding-top: 5rem; }
  .pdb-md-80 {
    padding-bottom: 80px;
    padding-bottom: 5rem; }
  .pdl-md-80 {
    padding-left: 80px;
    padding-left: 5rem; }
  .pdr-md-80 {
    padding-right: 80px;
    padding-right: 5rem; }
  .mg-md-85 {
    margin: 85px;
    margin: 5.3125rem; }
  .mg-md-85-0 {
    margin: 85px 0;
    margin: 5.3125rem 0; }
  .mg-md-0-85 {
    margin: 0 85px;
    margin: 0 5.3125rem; }
  .mgt-md-85 {
    margin-top: 85px;
    margin-top: 5.3125rem; }
  .mgb-md-85 {
    margin-bottom: 85px;
    margin-bottom: 5.3125rem; }
  .mgl-md-85 {
    margin-left: 85px;
    margin-left: 5.3125rem; }
  .mgr-md-85 {
    margin-right: 85px;
    margin-right: 5.3125rem; }
  .pd-md-85 {
    padding: 85px;
    padding: 5.3125rem; }
  .pd-md-85-0 {
    padding: 85px 0;
    padding: 5.3125rem 0; }
  .pd-md-0-85 {
    padding: 0 85px;
    padding: 0 5.3125rem; }
  .md-pdt-85 {
    padding-top: 85px;
    padding-top: 5.3125rem; }
  .pdb-md-85 {
    padding-bottom: 85px;
    padding-bottom: 5.3125rem; }
  .pdl-md-85 {
    padding-left: 85px;
    padding-left: 5.3125rem; }
  .pdr-md-85 {
    padding-right: 85px;
    padding-right: 5.3125rem; }
  .mg-md-90 {
    margin: 90px;
    margin: 5.625rem; }
  .mg-md-90-0 {
    margin: 90px 0;
    margin: 5.625rem 0; }
  .mg-md-0-90 {
    margin: 0 90px;
    margin: 0 5.625rem; }
  .mgt-md-90 {
    margin-top: 90px;
    margin-top: 5.625rem; }
  .mgb-md-90 {
    margin-bottom: 90px;
    margin-bottom: 5.625rem; }
  .mgl-md-90 {
    margin-left: 90px;
    margin-left: 5.625rem; }
  .mgr-md-90 {
    margin-right: 90px;
    margin-right: 5.625rem; }
  .pd-md-90 {
    padding: 90px;
    padding: 5.625rem; }
  .pd-md-90-0 {
    padding: 90px 0;
    padding: 5.625rem 0; }
  .pd-md-0-90 {
    padding: 0 90px;
    padding: 0 5.625rem; }
  .md-pdt-90 {
    padding-top: 90px;
    padding-top: 5.625rem; }
  .pdb-md-90 {
    padding-bottom: 90px;
    padding-bottom: 5.625rem; }
  .pdl-md-90 {
    padding-left: 90px;
    padding-left: 5.625rem; }
  .pdr-md-90 {
    padding-right: 90px;
    padding-right: 5.625rem; }
  .mg-md-95 {
    margin: 95px;
    margin: 5.9375rem; }
  .mg-md-95-0 {
    margin: 95px 0;
    margin: 5.9375rem 0; }
  .mg-md-0-95 {
    margin: 0 95px;
    margin: 0 5.9375rem; }
  .mgt-md-95 {
    margin-top: 95px;
    margin-top: 5.9375rem; }
  .mgb-md-95 {
    margin-bottom: 95px;
    margin-bottom: 5.9375rem; }
  .mgl-md-95 {
    margin-left: 95px;
    margin-left: 5.9375rem; }
  .mgr-md-95 {
    margin-right: 95px;
    margin-right: 5.9375rem; }
  .pd-md-95 {
    padding: 95px;
    padding: 5.9375rem; }
  .pd-md-95-0 {
    padding: 95px 0;
    padding: 5.9375rem 0; }
  .pd-md-0-95 {
    padding: 0 95px;
    padding: 0 5.9375rem; }
  .md-pdt-95 {
    padding-top: 95px;
    padding-top: 5.9375rem; }
  .pdb-md-95 {
    padding-bottom: 95px;
    padding-bottom: 5.9375rem; }
  .pdl-md-95 {
    padding-left: 95px;
    padding-left: 5.9375rem; }
  .pdr-md-95 {
    padding-right: 95px;
    padding-right: 5.9375rem; }
  .mg-md-100 {
    margin: 100px;
    margin: 6.25rem; }
  .mg-md-100-0 {
    margin: 100px 0;
    margin: 6.25rem 0; }
  .mg-md-0-100 {
    margin: 0 100px;
    margin: 0 6.25rem; }
  .mgt-md-100 {
    margin-top: 100px;
    margin-top: 6.25rem; }
  .mgb-md-100 {
    margin-bottom: 100px;
    margin-bottom: 6.25rem; }
  .mgl-md-100 {
    margin-left: 100px;
    margin-left: 6.25rem; }
  .mgr-md-100 {
    margin-right: 100px;
    margin-right: 6.25rem; }
  .pd-md-100 {
    padding: 100px;
    padding: 6.25rem; }
  .pd-md-100-0 {
    padding: 100px 0;
    padding: 6.25rem 0; }
  .pd-md-0-100 {
    padding: 0 100px;
    padding: 0 6.25rem; }
  .md-pdt-100 {
    padding-top: 100px;
    padding-top: 6.25rem; }
  .pdb-md-100 {
    padding-bottom: 100px;
    padding-bottom: 6.25rem; }
  .pdl-md-100 {
    padding-left: 100px;
    padding-left: 6.25rem; }
  .pdr-md-100 {
    padding-right: 100px;
    padding-right: 6.25rem; }
  .mg-md-105 {
    margin: 105px;
    margin: 6.5625rem; }
  .mg-md-105-0 {
    margin: 105px 0;
    margin: 6.5625rem 0; }
  .mg-md-0-105 {
    margin: 0 105px;
    margin: 0 6.5625rem; }
  .mgt-md-105 {
    margin-top: 105px;
    margin-top: 6.5625rem; }
  .mgb-md-105 {
    margin-bottom: 105px;
    margin-bottom: 6.5625rem; }
  .mgl-md-105 {
    margin-left: 105px;
    margin-left: 6.5625rem; }
  .mgr-md-105 {
    margin-right: 105px;
    margin-right: 6.5625rem; }
  .pd-md-105 {
    padding: 105px;
    padding: 6.5625rem; }
  .pd-md-105-0 {
    padding: 105px 0;
    padding: 6.5625rem 0; }
  .pd-md-0-105 {
    padding: 0 105px;
    padding: 0 6.5625rem; }
  .md-pdt-105 {
    padding-top: 105px;
    padding-top: 6.5625rem; }
  .pdb-md-105 {
    padding-bottom: 105px;
    padding-bottom: 6.5625rem; }
  .pdl-md-105 {
    padding-left: 105px;
    padding-left: 6.5625rem; }
  .pdr-md-105 {
    padding-right: 105px;
    padding-right: 6.5625rem; }
  .mg-md-110 {
    margin: 110px;
    margin: 6.875rem; }
  .mg-md-110-0 {
    margin: 110px 0;
    margin: 6.875rem 0; }
  .mg-md-0-110 {
    margin: 0 110px;
    margin: 0 6.875rem; }
  .mgt-md-110 {
    margin-top: 110px;
    margin-top: 6.875rem; }
  .mgb-md-110 {
    margin-bottom: 110px;
    margin-bottom: 6.875rem; }
  .mgl-md-110 {
    margin-left: 110px;
    margin-left: 6.875rem; }
  .mgr-md-110 {
    margin-right: 110px;
    margin-right: 6.875rem; }
  .pd-md-110 {
    padding: 110px;
    padding: 6.875rem; }
  .pd-md-110-0 {
    padding: 110px 0;
    padding: 6.875rem 0; }
  .pd-md-0-110 {
    padding: 0 110px;
    padding: 0 6.875rem; }
  .md-pdt-110 {
    padding-top: 110px;
    padding-top: 6.875rem; }
  .pdb-md-110 {
    padding-bottom: 110px;
    padding-bottom: 6.875rem; }
  .pdl-md-110 {
    padding-left: 110px;
    padding-left: 6.875rem; }
  .pdr-md-110 {
    padding-right: 110px;
    padding-right: 6.875rem; }
  .mg-md-115 {
    margin: 115px;
    margin: 7.1875rem; }
  .mg-md-115-0 {
    margin: 115px 0;
    margin: 7.1875rem 0; }
  .mg-md-0-115 {
    margin: 0 115px;
    margin: 0 7.1875rem; }
  .mgt-md-115 {
    margin-top: 115px;
    margin-top: 7.1875rem; }
  .mgb-md-115 {
    margin-bottom: 115px;
    margin-bottom: 7.1875rem; }
  .mgl-md-115 {
    margin-left: 115px;
    margin-left: 7.1875rem; }
  .mgr-md-115 {
    margin-right: 115px;
    margin-right: 7.1875rem; }
  .pd-md-115 {
    padding: 115px;
    padding: 7.1875rem; }
  .pd-md-115-0 {
    padding: 115px 0;
    padding: 7.1875rem 0; }
  .pd-md-0-115 {
    padding: 0 115px;
    padding: 0 7.1875rem; }
  .md-pdt-115 {
    padding-top: 115px;
    padding-top: 7.1875rem; }
  .pdb-md-115 {
    padding-bottom: 115px;
    padding-bottom: 7.1875rem; }
  .pdl-md-115 {
    padding-left: 115px;
    padding-left: 7.1875rem; }
  .pdr-md-115 {
    padding-right: 115px;
    padding-right: 7.1875rem; }
  .mg-md-120 {
    margin: 120px;
    margin: 7.5rem; }
  .mg-md-120-0 {
    margin: 120px 0;
    margin: 7.5rem 0; }
  .mg-md-0-120 {
    margin: 0 120px;
    margin: 0 7.5rem; }
  .mgt-md-120 {
    margin-top: 120px;
    margin-top: 7.5rem; }
  .mgb-md-120 {
    margin-bottom: 120px;
    margin-bottom: 7.5rem; }
  .mgl-md-120 {
    margin-left: 120px;
    margin-left: 7.5rem; }
  .mgr-md-120 {
    margin-right: 120px;
    margin-right: 7.5rem; }
  .pd-md-120 {
    padding: 120px;
    padding: 7.5rem; }
  .pd-md-120-0 {
    padding: 120px 0;
    padding: 7.5rem 0; }
  .pd-md-0-120 {
    padding: 0 120px;
    padding: 0 7.5rem; }
  .md-pdt-120 {
    padding-top: 120px;
    padding-top: 7.5rem; }
  .pdb-md-120 {
    padding-bottom: 120px;
    padding-bottom: 7.5rem; }
  .pdl-md-120 {
    padding-left: 120px;
    padding-left: 7.5rem; }
  .pdr-md-120 {
    padding-right: 120px;
    padding-right: 7.5rem; }
  .mg-md-125 {
    margin: 125px;
    margin: 7.8125rem; }
  .mg-md-125-0 {
    margin: 125px 0;
    margin: 7.8125rem 0; }
  .mg-md-0-125 {
    margin: 0 125px;
    margin: 0 7.8125rem; }
  .mgt-md-125 {
    margin-top: 125px;
    margin-top: 7.8125rem; }
  .mgb-md-125 {
    margin-bottom: 125px;
    margin-bottom: 7.8125rem; }
  .mgl-md-125 {
    margin-left: 125px;
    margin-left: 7.8125rem; }
  .mgr-md-125 {
    margin-right: 125px;
    margin-right: 7.8125rem; }
  .pd-md-125 {
    padding: 125px;
    padding: 7.8125rem; }
  .pd-md-125-0 {
    padding: 125px 0;
    padding: 7.8125rem 0; }
  .pd-md-0-125 {
    padding: 0 125px;
    padding: 0 7.8125rem; }
  .md-pdt-125 {
    padding-top: 125px;
    padding-top: 7.8125rem; }
  .pdb-md-125 {
    padding-bottom: 125px;
    padding-bottom: 7.8125rem; }
  .pdl-md-125 {
    padding-left: 125px;
    padding-left: 7.8125rem; }
  .pdr-md-125 {
    padding-right: 125px;
    padding-right: 7.8125rem; }
  .mg-md-130 {
    margin: 130px;
    margin: 8.125rem; }
  .mg-md-130-0 {
    margin: 130px 0;
    margin: 8.125rem 0; }
  .mg-md-0-130 {
    margin: 0 130px;
    margin: 0 8.125rem; }
  .mgt-md-130 {
    margin-top: 130px;
    margin-top: 8.125rem; }
  .mgb-md-130 {
    margin-bottom: 130px;
    margin-bottom: 8.125rem; }
  .mgl-md-130 {
    margin-left: 130px;
    margin-left: 8.125rem; }
  .mgr-md-130 {
    margin-right: 130px;
    margin-right: 8.125rem; }
  .pd-md-130 {
    padding: 130px;
    padding: 8.125rem; }
  .pd-md-130-0 {
    padding: 130px 0;
    padding: 8.125rem 0; }
  .pd-md-0-130 {
    padding: 0 130px;
    padding: 0 8.125rem; }
  .md-pdt-130 {
    padding-top: 130px;
    padding-top: 8.125rem; }
  .pdb-md-130 {
    padding-bottom: 130px;
    padding-bottom: 8.125rem; }
  .pdl-md-130 {
    padding-left: 130px;
    padding-left: 8.125rem; }
  .pdr-md-130 {
    padding-right: 130px;
    padding-right: 8.125rem; }
  .mg-md-135 {
    margin: 135px;
    margin: 8.4375rem; }
  .mg-md-135-0 {
    margin: 135px 0;
    margin: 8.4375rem 0; }
  .mg-md-0-135 {
    margin: 0 135px;
    margin: 0 8.4375rem; }
  .mgt-md-135 {
    margin-top: 135px;
    margin-top: 8.4375rem; }
  .mgb-md-135 {
    margin-bottom: 135px;
    margin-bottom: 8.4375rem; }
  .mgl-md-135 {
    margin-left: 135px;
    margin-left: 8.4375rem; }
  .mgr-md-135 {
    margin-right: 135px;
    margin-right: 8.4375rem; }
  .pd-md-135 {
    padding: 135px;
    padding: 8.4375rem; }
  .pd-md-135-0 {
    padding: 135px 0;
    padding: 8.4375rem 0; }
  .pd-md-0-135 {
    padding: 0 135px;
    padding: 0 8.4375rem; }
  .md-pdt-135 {
    padding-top: 135px;
    padding-top: 8.4375rem; }
  .pdb-md-135 {
    padding-bottom: 135px;
    padding-bottom: 8.4375rem; }
  .pdl-md-135 {
    padding-left: 135px;
    padding-left: 8.4375rem; }
  .pdr-md-135 {
    padding-right: 135px;
    padding-right: 8.4375rem; }
  .mg-md-140 {
    margin: 140px;
    margin: 8.75rem; }
  .mg-md-140-0 {
    margin: 140px 0;
    margin: 8.75rem 0; }
  .mg-md-0-140 {
    margin: 0 140px;
    margin: 0 8.75rem; }
  .mgt-md-140 {
    margin-top: 140px;
    margin-top: 8.75rem; }
  .mgb-md-140 {
    margin-bottom: 140px;
    margin-bottom: 8.75rem; }
  .mgl-md-140 {
    margin-left: 140px;
    margin-left: 8.75rem; }
  .mgr-md-140 {
    margin-right: 140px;
    margin-right: 8.75rem; }
  .pd-md-140 {
    padding: 140px;
    padding: 8.75rem; }
  .pd-md-140-0 {
    padding: 140px 0;
    padding: 8.75rem 0; }
  .pd-md-0-140 {
    padding: 0 140px;
    padding: 0 8.75rem; }
  .md-pdt-140 {
    padding-top: 140px;
    padding-top: 8.75rem; }
  .pdb-md-140 {
    padding-bottom: 140px;
    padding-bottom: 8.75rem; }
  .pdl-md-140 {
    padding-left: 140px;
    padding-left: 8.75rem; }
  .pdr-md-140 {
    padding-right: 140px;
    padding-right: 8.75rem; }
  .mg-md-145 {
    margin: 145px;
    margin: 9.0625rem; }
  .mg-md-145-0 {
    margin: 145px 0;
    margin: 9.0625rem 0; }
  .mg-md-0-145 {
    margin: 0 145px;
    margin: 0 9.0625rem; }
  .mgt-md-145 {
    margin-top: 145px;
    margin-top: 9.0625rem; }
  .mgb-md-145 {
    margin-bottom: 145px;
    margin-bottom: 9.0625rem; }
  .mgl-md-145 {
    margin-left: 145px;
    margin-left: 9.0625rem; }
  .mgr-md-145 {
    margin-right: 145px;
    margin-right: 9.0625rem; }
  .pd-md-145 {
    padding: 145px;
    padding: 9.0625rem; }
  .pd-md-145-0 {
    padding: 145px 0;
    padding: 9.0625rem 0; }
  .pd-md-0-145 {
    padding: 0 145px;
    padding: 0 9.0625rem; }
  .md-pdt-145 {
    padding-top: 145px;
    padding-top: 9.0625rem; }
  .pdb-md-145 {
    padding-bottom: 145px;
    padding-bottom: 9.0625rem; }
  .pdl-md-145 {
    padding-left: 145px;
    padding-left: 9.0625rem; }
  .pdr-md-145 {
    padding-right: 145px;
    padding-right: 9.0625rem; }
  .mg-md-150 {
    margin: 150px;
    margin: 9.375rem; }
  .mg-md-150-0 {
    margin: 150px 0;
    margin: 9.375rem 0; }
  .mg-md-0-150 {
    margin: 0 150px;
    margin: 0 9.375rem; }
  .mgt-md-150 {
    margin-top: 150px;
    margin-top: 9.375rem; }
  .mgb-md-150 {
    margin-bottom: 150px;
    margin-bottom: 9.375rem; }
  .mgl-md-150 {
    margin-left: 150px;
    margin-left: 9.375rem; }
  .mgr-md-150 {
    margin-right: 150px;
    margin-right: 9.375rem; }
  .pd-md-150 {
    padding: 150px;
    padding: 9.375rem; }
  .pd-md-150-0 {
    padding: 150px 0;
    padding: 9.375rem 0; }
  .pd-md-0-150 {
    padding: 0 150px;
    padding: 0 9.375rem; }
  .md-pdt-150 {
    padding-top: 150px;
    padding-top: 9.375rem; }
  .pdb-md-150 {
    padding-bottom: 150px;
    padding-bottom: 9.375rem; }
  .pdl-md-150 {
    padding-left: 150px;
    padding-left: 9.375rem; }
  .pdr-md-150 {
    padding-right: 150px;
    padding-right: 9.375rem; } }

@media (min-width: 992px) {
  .text-lg-normal {
    font-weight: 400; }
  .text-lg-semibold {
    font-weight: 600; }
  .text-lg-bold {
    font-weight: 700; }
  .text-lg-right {
    text-align: right; }
  .text-lg-center {
    text-align: center; }
  .text-lg-justify {
    text-align: justify; }
  .text-lg-nowrap {
    white-space: nowrap; }
  .text-lg-lowercase {
    text-transform: lowercase; }
  .text-lg-uppercase {
    text-transform: uppercase; }
  .mg-lg-0 {
    margin: 0; }
  .mgt-lg-0 {
    margin-top: 0; }
  .mgb-lg-0 {
    margin-bottom: 0; }
  .mgl-lg-0 {
    margin-left: 0; }
  .mgr-lg-0 {
    margin-right: 0; }
  .pd-lg-0 {
    padding: 0; }
  .pdt-lg-0 {
    padding-top: 0; }
  .pdb-lg-0 {
    padding-bottom: 0; }
  .pdl-lg-0 {
    padding-left: 0; }
  .pdr-lg-0 {
    padding-right: 0; }
  .mg-lg-5 {
    margin: 5px;
    margin: 0.3125rem; }
  .mg-lg-5-0 {
    margin: 5px 0;
    margin: 0.3125rem 0; }
  .mg-lg-0-5 {
    margin: 0 5px;
    margin: 0 0.3125rem; }
  .mgt-lg-5 {
    margin-top: 5px;
    margin-top: 0.3125rem; }
  .mgb-lg-5 {
    margin-bottom: 5px;
    margin-bottom: 0.3125rem; }
  .mgl-lg-5 {
    margin-left: 5px;
    margin-left: 5px; }
  .mgr-lg-5 {
    margin-right: 5px;
    margin-right: 0.3125rem; }
  .pd-lg-5 {
    padding: 5px;
    padding: 0.3125rem; }
  .pd-lg-5-0 {
    padding: 5px 0;
    padding: 0.3125rem 0; }
  .pd-lg-0-5 {
    padding: 0 5px;
    padding: 0 0.3125rem; }
  .pdt-lg-5 {
    padding-top: 5px;
    padding-top: 0.3125rem; }
  .pdb-lg-5 {
    padding-bottom: 5px;
    padding-bottom: 0.3125rem; }
  .pdl-lg-5 {
    padding-left: 5px;
    padding-left: 0.3125rem; }
  .pdr-lg-5 {
    padding-right: 5px;
    padding-right: 0.3125rem; }
  .mg-lg-10 {
    margin: 10px;
    margin: 0.625rem; }
  .mg-lg-10-0 {
    margin: 10px 0;
    margin: 0.625rem 0; }
  .mg-lg-0-10 {
    margin: 0 10px;
    margin: 0 0.625rem; }
  .mgt-lg-10 {
    margin-top: 10px;
    margin-top: 0.625rem; }
  .mgb-lg-10 {
    margin-bottom: 10px;
    margin-bottom: 0.625rem; }
  .mgl-lg-10 {
    margin-left: 10px;
    margin-left: 10px; }
  .mgr-lg-10 {
    margin-right: 10px;
    margin-right: 0.625rem; }
  .pd-lg-10 {
    padding: 10px;
    padding: 0.625rem; }
  .pd-lg-10-0 {
    padding: 10px 0;
    padding: 0.625rem 0; }
  .pd-lg-0-10 {
    padding: 0 10px;
    padding: 0 0.625rem; }
  .pdt-lg-10 {
    padding-top: 10px;
    padding-top: 0.625rem; }
  .pdb-lg-10 {
    padding-bottom: 10px;
    padding-bottom: 0.625rem; }
  .pdl-lg-10 {
    padding-left: 10px;
    padding-left: 0.625rem; }
  .pdr-lg-10 {
    padding-right: 10px;
    padding-right: 0.625rem; }
  .mg-lg-15 {
    margin: 15px;
    margin: 0.9375rem; }
  .mg-lg-15-0 {
    margin: 15px 0;
    margin: 0.9375rem 0; }
  .mg-lg-0-15 {
    margin: 0 15px;
    margin: 0 0.9375rem; }
  .mgt-lg-15 {
    margin-top: 15px;
    margin-top: 0.9375rem; }
  .mgb-lg-15 {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem; }
  .mgl-lg-15 {
    margin-left: 15px;
    margin-left: 15px; }
  .mgr-lg-15 {
    margin-right: 15px;
    margin-right: 0.9375rem; }
  .pd-lg-15 {
    padding: 15px;
    padding: 0.9375rem; }
  .pd-lg-15-0 {
    padding: 15px 0;
    padding: 0.9375rem 0; }
  .pd-lg-0-15 {
    padding: 0 15px;
    padding: 0 0.9375rem; }
  .pdt-lg-15 {
    padding-top: 15px;
    padding-top: 0.9375rem; }
  .pdb-lg-15 {
    padding-bottom: 15px;
    padding-bottom: 0.9375rem; }
  .pdl-lg-15 {
    padding-left: 15px;
    padding-left: 0.9375rem; }
  .pdr-lg-15 {
    padding-right: 15px;
    padding-right: 0.9375rem; }
  .mg-lg-20 {
    margin: 20px;
    margin: 1.25rem; }
  .mg-lg-20-0 {
    margin: 20px 0;
    margin: 1.25rem 0; }
  .mg-lg-0-20 {
    margin: 0 20px;
    margin: 0 1.25rem; }
  .mgt-lg-20 {
    margin-top: 20px;
    margin-top: 1.25rem; }
  .mgb-lg-20 {
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  .mgl-lg-20 {
    margin-left: 20px;
    margin-left: 20px; }
  .mgr-lg-20 {
    margin-right: 20px;
    margin-right: 1.25rem; }
  .pd-lg-20 {
    padding: 20px;
    padding: 1.25rem; }
  .pd-lg-20-0 {
    padding: 20px 0;
    padding: 1.25rem 0; }
  .pd-lg-0-20 {
    padding: 0 20px;
    padding: 0 1.25rem; }
  .pdt-lg-20 {
    padding-top: 20px;
    padding-top: 1.25rem; }
  .pdb-lg-20 {
    padding-bottom: 20px;
    padding-bottom: 1.25rem; }
  .pdl-lg-20 {
    padding-left: 20px;
    padding-left: 1.25rem; }
  .pdr-lg-20 {
    padding-right: 20px;
    padding-right: 1.25rem; }
  .mg-lg-25 {
    margin: 25px;
    margin: 1.5625rem; }
  .mg-lg-25-0 {
    margin: 25px 0;
    margin: 1.5625rem 0; }
  .mg-lg-0-25 {
    margin: 0 25px;
    margin: 0 1.5625rem; }
  .mgt-lg-25 {
    margin-top: 25px;
    margin-top: 1.5625rem; }
  .mgb-lg-25 {
    margin-bottom: 25px;
    margin-bottom: 1.5625rem; }
  .mgl-lg-25 {
    margin-left: 25px;
    margin-left: 25px; }
  .mgr-lg-25 {
    margin-right: 25px;
    margin-right: 1.5625rem; }
  .pd-lg-25 {
    padding: 25px;
    padding: 1.5625rem; }
  .pd-lg-25-0 {
    padding: 25px 0;
    padding: 1.5625rem 0; }
  .pd-lg-0-25 {
    padding: 0 25px;
    padding: 0 1.5625rem; }
  .pdt-lg-25 {
    padding-top: 25px;
    padding-top: 1.5625rem; }
  .pdb-lg-25 {
    padding-bottom: 25px;
    padding-bottom: 1.5625rem; }
  .pdl-lg-25 {
    padding-left: 25px;
    padding-left: 1.5625rem; }
  .pdr-lg-25 {
    padding-right: 25px;
    padding-right: 1.5625rem; }
  .mg-lg-30 {
    margin: 30px;
    margin: 1.875rem; }
  .mg-lg-30-0 {
    margin: 30px 0;
    margin: 1.875rem 0; }
  .mg-lg-0-30 {
    margin: 0 30px;
    margin: 0 1.875rem; }
  .mgt-lg-30 {
    margin-top: 30px;
    margin-top: 1.875rem; }
  .mgb-lg-30 {
    margin-bottom: 30px;
    margin-bottom: 1.875rem; }
  .mgl-lg-30 {
    margin-left: 30px;
    margin-left: 30px; }
  .mgr-lg-30 {
    margin-right: 30px;
    margin-right: 1.875rem; }
  .pd-lg-30 {
    padding: 30px;
    padding: 1.875rem; }
  .pd-lg-30-0 {
    padding: 30px 0;
    padding: 1.875rem 0; }
  .pd-lg-0-30 {
    padding: 0 30px;
    padding: 0 1.875rem; }
  .pdt-lg-30 {
    padding-top: 30px;
    padding-top: 1.875rem; }
  .pdb-lg-30 {
    padding-bottom: 30px;
    padding-bottom: 1.875rem; }
  .pdl-lg-30 {
    padding-left: 30px;
    padding-left: 1.875rem; }
  .pdr-lg-30 {
    padding-right: 30px;
    padding-right: 1.875rem; }
  .mg-lg-35 {
    margin: 35px;
    margin: 2.1875rem; }
  .mg-lg-35-0 {
    margin: 35px 0;
    margin: 2.1875rem 0; }
  .mg-lg-0-35 {
    margin: 0 35px;
    margin: 0 2.1875rem; }
  .mgt-lg-35 {
    margin-top: 35px;
    margin-top: 2.1875rem; }
  .mgb-lg-35 {
    margin-bottom: 35px;
    margin-bottom: 2.1875rem; }
  .mgl-lg-35 {
    margin-left: 35px;
    margin-left: 35px; }
  .mgr-lg-35 {
    margin-right: 35px;
    margin-right: 2.1875rem; }
  .pd-lg-35 {
    padding: 35px;
    padding: 2.1875rem; }
  .pd-lg-35-0 {
    padding: 35px 0;
    padding: 2.1875rem 0; }
  .pd-lg-0-35 {
    padding: 0 35px;
    padding: 0 2.1875rem; }
  .pdt-lg-35 {
    padding-top: 35px;
    padding-top: 2.1875rem; }
  .pdb-lg-35 {
    padding-bottom: 35px;
    padding-bottom: 2.1875rem; }
  .pdl-lg-35 {
    padding-left: 35px;
    padding-left: 2.1875rem; }
  .pdr-lg-35 {
    padding-right: 35px;
    padding-right: 2.1875rem; }
  .mg-lg-40 {
    margin: 40px;
    margin: 2.5rem; }
  .mg-lg-40-0 {
    margin: 40px 0;
    margin: 2.5rem 0; }
  .mg-lg-0-40 {
    margin: 0 40px;
    margin: 0 2.5rem; }
  .mgt-lg-40 {
    margin-top: 40px;
    margin-top: 2.5rem; }
  .mgb-lg-40 {
    margin-bottom: 40px;
    margin-bottom: 2.5rem; }
  .mgl-lg-40 {
    margin-left: 40px;
    margin-left: 40px; }
  .mgr-lg-40 {
    margin-right: 40px;
    margin-right: 2.5rem; }
  .pd-lg-40 {
    padding: 40px;
    padding: 2.5rem; }
  .pd-lg-40-0 {
    padding: 40px 0;
    padding: 2.5rem 0; }
  .pd-lg-0-40 {
    padding: 0 40px;
    padding: 0 2.5rem; }
  .pdt-lg-40 {
    padding-top: 40px;
    padding-top: 2.5rem; }
  .pdb-lg-40 {
    padding-bottom: 40px;
    padding-bottom: 2.5rem; }
  .pdl-lg-40 {
    padding-left: 40px;
    padding-left: 2.5rem; }
  .pdr-lg-40 {
    padding-right: 40px;
    padding-right: 2.5rem; }
  .mg-lg-45 {
    margin: 45px;
    margin: 2.8125rem; }
  .mg-lg-45-0 {
    margin: 45px 0;
    margin: 2.8125rem 0; }
  .mg-lg-0-45 {
    margin: 0 45px;
    margin: 0 2.8125rem; }
  .mgt-lg-45 {
    margin-top: 45px;
    margin-top: 2.8125rem; }
  .mgb-lg-45 {
    margin-bottom: 45px;
    margin-bottom: 2.8125rem; }
  .mgl-lg-45 {
    margin-left: 45px;
    margin-left: 45px; }
  .mgr-lg-45 {
    margin-right: 45px;
    margin-right: 2.8125rem; }
  .pd-lg-45 {
    padding: 45px;
    padding: 2.8125rem; }
  .pd-lg-45-0 {
    padding: 45px 0;
    padding: 2.8125rem 0; }
  .pd-lg-0-45 {
    padding: 0 45px;
    padding: 0 2.8125rem; }
  .pdt-lg-45 {
    padding-top: 45px;
    padding-top: 2.8125rem; }
  .pdb-lg-45 {
    padding-bottom: 45px;
    padding-bottom: 2.8125rem; }
  .pdl-lg-45 {
    padding-left: 45px;
    padding-left: 2.8125rem; }
  .pdr-lg-45 {
    padding-right: 45px;
    padding-right: 2.8125rem; }
  .mg-lg-50 {
    margin: 50px;
    margin: 3.125rem; }
  .mg-lg-50-0 {
    margin: 50px 0;
    margin: 3.125rem 0; }
  .mg-lg-0-50 {
    margin: 0 50px;
    margin: 0 3.125rem; }
  .mgt-lg-50 {
    margin-top: 50px;
    margin-top: 3.125rem; }
  .mgb-lg-50 {
    margin-bottom: 50px;
    margin-bottom: 3.125rem; }
  .mgl-lg-50 {
    margin-left: 50px;
    margin-left: 50px; }
  .mgr-lg-50 {
    margin-right: 50px;
    margin-right: 3.125rem; }
  .pd-lg-50 {
    padding: 50px;
    padding: 3.125rem; }
  .pd-lg-50-0 {
    padding: 50px 0;
    padding: 3.125rem 0; }
  .pd-lg-0-50 {
    padding: 0 50px;
    padding: 0 3.125rem; }
  .pdt-lg-50 {
    padding-top: 50px;
    padding-top: 3.125rem; }
  .pdb-lg-50 {
    padding-bottom: 50px;
    padding-bottom: 3.125rem; }
  .pdl-lg-50 {
    padding-left: 50px;
    padding-left: 3.125rem; }
  .pdr-lg-50 {
    padding-right: 50px;
    padding-right: 3.125rem; }
  .mg-lg-55 {
    margin: 55px;
    margin: 3.4375rem; }
  .mg-lg-55-0 {
    margin: 55px 0;
    margin: 3.4375rem 0; }
  .mg-lg-0-55 {
    margin: 0 55px;
    margin: 0 3.4375rem; }
  .mgt-lg-55 {
    margin-top: 55px;
    margin-top: 3.4375rem; }
  .mgb-lg-55 {
    margin-bottom: 55px;
    margin-bottom: 3.4375rem; }
  .mgl-lg-55 {
    margin-left: 55px;
    margin-left: 55px; }
  .mgr-lg-55 {
    margin-right: 55px;
    margin-right: 3.4375rem; }
  .pd-lg-55 {
    padding: 55px;
    padding: 3.4375rem; }
  .pd-lg-55-0 {
    padding: 55px 0;
    padding: 3.4375rem 0; }
  .pd-lg-0-55 {
    padding: 0 55px;
    padding: 0 3.4375rem; }
  .pdt-lg-55 {
    padding-top: 55px;
    padding-top: 3.4375rem; }
  .pdb-lg-55 {
    padding-bottom: 55px;
    padding-bottom: 3.4375rem; }
  .pdl-lg-55 {
    padding-left: 55px;
    padding-left: 3.4375rem; }
  .pdr-lg-55 {
    padding-right: 55px;
    padding-right: 3.4375rem; }
  .mg-lg-60 {
    margin: 60px;
    margin: 3.75rem; }
  .mg-lg-60-0 {
    margin: 60px 0;
    margin: 3.75rem 0; }
  .mg-lg-0-60 {
    margin: 0 60px;
    margin: 0 3.75rem; }
  .mgt-lg-60 {
    margin-top: 60px;
    margin-top: 3.75rem; }
  .mgb-lg-60 {
    margin-bottom: 60px;
    margin-bottom: 3.75rem; }
  .mgl-lg-60 {
    margin-left: 60px;
    margin-left: 60px; }
  .mgr-lg-60 {
    margin-right: 60px;
    margin-right: 3.75rem; }
  .pd-lg-60 {
    padding: 60px;
    padding: 3.75rem; }
  .pd-lg-60-0 {
    padding: 60px 0;
    padding: 3.75rem 0; }
  .pd-lg-0-60 {
    padding: 0 60px;
    padding: 0 3.75rem; }
  .pdt-lg-60 {
    padding-top: 60px;
    padding-top: 3.75rem; }
  .pdb-lg-60 {
    padding-bottom: 60px;
    padding-bottom: 3.75rem; }
  .pdl-lg-60 {
    padding-left: 60px;
    padding-left: 3.75rem; }
  .pdr-lg-60 {
    padding-right: 60px;
    padding-right: 3.75rem; }
  .mg-lg-65 {
    margin: 65px;
    margin: 4.0625rem; }
  .mg-lg-65-0 {
    margin: 65px 0;
    margin: 4.0625rem 0; }
  .mg-lg-0-65 {
    margin: 0 65px;
    margin: 0 4.0625rem; }
  .mgt-lg-65 {
    margin-top: 65px;
    margin-top: 4.0625rem; }
  .mgb-lg-65 {
    margin-bottom: 65px;
    margin-bottom: 4.0625rem; }
  .mgl-lg-65 {
    margin-left: 65px;
    margin-left: 65px; }
  .mgr-lg-65 {
    margin-right: 65px;
    margin-right: 4.0625rem; }
  .pd-lg-65 {
    padding: 65px;
    padding: 4.0625rem; }
  .pd-lg-65-0 {
    padding: 65px 0;
    padding: 4.0625rem 0; }
  .pd-lg-0-65 {
    padding: 0 65px;
    padding: 0 4.0625rem; }
  .pdt-lg-65 {
    padding-top: 65px;
    padding-top: 4.0625rem; }
  .pdb-lg-65 {
    padding-bottom: 65px;
    padding-bottom: 4.0625rem; }
  .pdl-lg-65 {
    padding-left: 65px;
    padding-left: 4.0625rem; }
  .pdr-lg-65 {
    padding-right: 65px;
    padding-right: 4.0625rem; }
  .mg-lg-70 {
    margin: 70px;
    margin: 4.375rem; }
  .mg-lg-70-0 {
    margin: 70px 0;
    margin: 4.375rem 0; }
  .mg-lg-0-70 {
    margin: 0 70px;
    margin: 0 4.375rem; }
  .mgt-lg-70 {
    margin-top: 70px;
    margin-top: 4.375rem; }
  .mgb-lg-70 {
    margin-bottom: 70px;
    margin-bottom: 4.375rem; }
  .mgl-lg-70 {
    margin-left: 70px;
    margin-left: 70px; }
  .mgr-lg-70 {
    margin-right: 70px;
    margin-right: 4.375rem; }
  .pd-lg-70 {
    padding: 70px;
    padding: 4.375rem; }
  .pd-lg-70-0 {
    padding: 70px 0;
    padding: 4.375rem 0; }
  .pd-lg-0-70 {
    padding: 0 70px;
    padding: 0 4.375rem; }
  .pdt-lg-70 {
    padding-top: 70px;
    padding-top: 4.375rem; }
  .pdb-lg-70 {
    padding-bottom: 70px;
    padding-bottom: 4.375rem; }
  .pdl-lg-70 {
    padding-left: 70px;
    padding-left: 4.375rem; }
  .pdr-lg-70 {
    padding-right: 70px;
    padding-right: 4.375rem; }
  .mg-lg-75 {
    margin: 75px;
    margin: 4.6875rem; }
  .mg-lg-75-0 {
    margin: 75px 0;
    margin: 4.6875rem 0; }
  .mg-lg-0-75 {
    margin: 0 75px;
    margin: 0 4.6875rem; }
  .mgt-lg-75 {
    margin-top: 75px;
    margin-top: 4.6875rem; }
  .mgb-lg-75 {
    margin-bottom: 75px;
    margin-bottom: 4.6875rem; }
  .mgl-lg-75 {
    margin-left: 75px;
    margin-left: 75px; }
  .mgr-lg-75 {
    margin-right: 75px;
    margin-right: 4.6875rem; }
  .pd-lg-75 {
    padding: 75px;
    padding: 4.6875rem; }
  .pd-lg-75-0 {
    padding: 75px 0;
    padding: 4.6875rem 0; }
  .pd-lg-0-75 {
    padding: 0 75px;
    padding: 0 4.6875rem; }
  .pdt-lg-75 {
    padding-top: 75px;
    padding-top: 4.6875rem; }
  .pdb-lg-75 {
    padding-bottom: 75px;
    padding-bottom: 4.6875rem; }
  .pdl-lg-75 {
    padding-left: 75px;
    padding-left: 4.6875rem; }
  .pdr-lg-75 {
    padding-right: 75px;
    padding-right: 4.6875rem; }
  .mg-lg-80 {
    margin: 80px;
    margin: 5rem; }
  .mg-lg-80-0 {
    margin: 80px 0;
    margin: 5rem 0; }
  .mg-lg-0-80 {
    margin: 0 80px;
    margin: 0 5rem; }
  .mgt-lg-80 {
    margin-top: 80px;
    margin-top: 5rem; }
  .mgb-lg-80 {
    margin-bottom: 80px;
    margin-bottom: 5rem; }
  .mgl-lg-80 {
    margin-left: 80px;
    margin-left: 80px; }
  .mgr-lg-80 {
    margin-right: 80px;
    margin-right: 5rem; }
  .pd-lg-80 {
    padding: 80px;
    padding: 5rem; }
  .pd-lg-80-0 {
    padding: 80px 0;
    padding: 5rem 0; }
  .pd-lg-0-80 {
    padding: 0 80px;
    padding: 0 5rem; }
  .pdt-lg-80 {
    padding-top: 80px;
    padding-top: 5rem; }
  .pdb-lg-80 {
    padding-bottom: 80px;
    padding-bottom: 5rem; }
  .pdl-lg-80 {
    padding-left: 80px;
    padding-left: 5rem; }
  .pdr-lg-80 {
    padding-right: 80px;
    padding-right: 5rem; }
  .mg-lg-85 {
    margin: 85px;
    margin: 5.3125rem; }
  .mg-lg-85-0 {
    margin: 85px 0;
    margin: 5.3125rem 0; }
  .mg-lg-0-85 {
    margin: 0 85px;
    margin: 0 5.3125rem; }
  .mgt-lg-85 {
    margin-top: 85px;
    margin-top: 5.3125rem; }
  .mgb-lg-85 {
    margin-bottom: 85px;
    margin-bottom: 5.3125rem; }
  .mgl-lg-85 {
    margin-left: 85px;
    margin-left: 85px; }
  .mgr-lg-85 {
    margin-right: 85px;
    margin-right: 5.3125rem; }
  .pd-lg-85 {
    padding: 85px;
    padding: 5.3125rem; }
  .pd-lg-85-0 {
    padding: 85px 0;
    padding: 5.3125rem 0; }
  .pd-lg-0-85 {
    padding: 0 85px;
    padding: 0 5.3125rem; }
  .pdt-lg-85 {
    padding-top: 85px;
    padding-top: 5.3125rem; }
  .pdb-lg-85 {
    padding-bottom: 85px;
    padding-bottom: 5.3125rem; }
  .pdl-lg-85 {
    padding-left: 85px;
    padding-left: 5.3125rem; }
  .pdr-lg-85 {
    padding-right: 85px;
    padding-right: 5.3125rem; }
  .mg-lg-90 {
    margin: 90px;
    margin: 5.625rem; }
  .mg-lg-90-0 {
    margin: 90px 0;
    margin: 5.625rem 0; }
  .mg-lg-0-90 {
    margin: 0 90px;
    margin: 0 5.625rem; }
  .mgt-lg-90 {
    margin-top: 90px;
    margin-top: 5.625rem; }
  .mgb-lg-90 {
    margin-bottom: 90px;
    margin-bottom: 5.625rem; }
  .mgl-lg-90 {
    margin-left: 90px;
    margin-left: 90px; }
  .mgr-lg-90 {
    margin-right: 90px;
    margin-right: 5.625rem; }
  .pd-lg-90 {
    padding: 90px;
    padding: 5.625rem; }
  .pd-lg-90-0 {
    padding: 90px 0;
    padding: 5.625rem 0; }
  .pd-lg-0-90 {
    padding: 0 90px;
    padding: 0 5.625rem; }
  .pdt-lg-90 {
    padding-top: 90px;
    padding-top: 5.625rem; }
  .pdb-lg-90 {
    padding-bottom: 90px;
    padding-bottom: 5.625rem; }
  .pdl-lg-90 {
    padding-left: 90px;
    padding-left: 5.625rem; }
  .pdr-lg-90 {
    padding-right: 90px;
    padding-right: 5.625rem; }
  .mg-lg-95 {
    margin: 95px;
    margin: 5.9375rem; }
  .mg-lg-95-0 {
    margin: 95px 0;
    margin: 5.9375rem 0; }
  .mg-lg-0-95 {
    margin: 0 95px;
    margin: 0 5.9375rem; }
  .mgt-lg-95 {
    margin-top: 95px;
    margin-top: 5.9375rem; }
  .mgb-lg-95 {
    margin-bottom: 95px;
    margin-bottom: 5.9375rem; }
  .mgl-lg-95 {
    margin-left: 95px;
    margin-left: 95px; }
  .mgr-lg-95 {
    margin-right: 95px;
    margin-right: 5.9375rem; }
  .pd-lg-95 {
    padding: 95px;
    padding: 5.9375rem; }
  .pd-lg-95-0 {
    padding: 95px 0;
    padding: 5.9375rem 0; }
  .pd-lg-0-95 {
    padding: 0 95px;
    padding: 0 5.9375rem; }
  .pdt-lg-95 {
    padding-top: 95px;
    padding-top: 5.9375rem; }
  .pdb-lg-95 {
    padding-bottom: 95px;
    padding-bottom: 5.9375rem; }
  .pdl-lg-95 {
    padding-left: 95px;
    padding-left: 5.9375rem; }
  .pdr-lg-95 {
    padding-right: 95px;
    padding-right: 5.9375rem; }
  .mg-lg-100 {
    margin: 100px;
    margin: 6.25rem; }
  .mg-lg-100-0 {
    margin: 100px 0;
    margin: 6.25rem 0; }
  .mg-lg-0-100 {
    margin: 0 100px;
    margin: 0 6.25rem; }
  .mgt-lg-100 {
    margin-top: 100px;
    margin-top: 6.25rem; }
  .mgb-lg-100 {
    margin-bottom: 100px;
    margin-bottom: 6.25rem; }
  .mgl-lg-100 {
    margin-left: 100px;
    margin-left: 100px; }
  .mgr-lg-100 {
    margin-right: 100px;
    margin-right: 6.25rem; }
  .pd-lg-100 {
    padding: 100px;
    padding: 6.25rem; }
  .pd-lg-100-0 {
    padding: 100px 0;
    padding: 6.25rem 0; }
  .pd-lg-0-100 {
    padding: 0 100px;
    padding: 0 6.25rem; }
  .pdt-lg-100 {
    padding-top: 100px;
    padding-top: 6.25rem; }
  .pdb-lg-100 {
    padding-bottom: 100px;
    padding-bottom: 6.25rem; }
  .pdl-lg-100 {
    padding-left: 100px;
    padding-left: 6.25rem; }
  .pdr-lg-100 {
    padding-right: 100px;
    padding-right: 6.25rem; }
  .mg-lg-105 {
    margin: 105px;
    margin: 6.5625rem; }
  .mg-lg-105-0 {
    margin: 105px 0;
    margin: 6.5625rem 0; }
  .mg-lg-0-105 {
    margin: 0 105px;
    margin: 0 6.5625rem; }
  .mgt-lg-105 {
    margin-top: 105px;
    margin-top: 6.5625rem; }
  .mgb-lg-105 {
    margin-bottom: 105px;
    margin-bottom: 6.5625rem; }
  .mgl-lg-105 {
    margin-left: 105px;
    margin-left: 105px; }
  .mgr-lg-105 {
    margin-right: 105px;
    margin-right: 6.5625rem; }
  .pd-lg-105 {
    padding: 105px;
    padding: 6.5625rem; }
  .pd-lg-105-0 {
    padding: 105px 0;
    padding: 6.5625rem 0; }
  .pd-lg-0-105 {
    padding: 0 105px;
    padding: 0 6.5625rem; }
  .pdt-lg-105 {
    padding-top: 105px;
    padding-top: 6.5625rem; }
  .pdb-lg-105 {
    padding-bottom: 105px;
    padding-bottom: 6.5625rem; }
  .pdl-lg-105 {
    padding-left: 105px;
    padding-left: 6.5625rem; }
  .pdr-lg-105 {
    padding-right: 105px;
    padding-right: 6.5625rem; }
  .mg-lg-110 {
    margin: 110px;
    margin: 6.875rem; }
  .mg-lg-110-0 {
    margin: 110px 0;
    margin: 6.875rem 0; }
  .mg-lg-0-110 {
    margin: 0 110px;
    margin: 0 6.875rem; }
  .mgt-lg-110 {
    margin-top: 110px;
    margin-top: 6.875rem; }
  .mgb-lg-110 {
    margin-bottom: 110px;
    margin-bottom: 6.875rem; }
  .mgl-lg-110 {
    margin-left: 110px;
    margin-left: 110px; }
  .mgr-lg-110 {
    margin-right: 110px;
    margin-right: 6.875rem; }
  .pd-lg-110 {
    padding: 110px;
    padding: 6.875rem; }
  .pd-lg-110-0 {
    padding: 110px 0;
    padding: 6.875rem 0; }
  .pd-lg-0-110 {
    padding: 0 110px;
    padding: 0 6.875rem; }
  .pdt-lg-110 {
    padding-top: 110px;
    padding-top: 6.875rem; }
  .pdb-lg-110 {
    padding-bottom: 110px;
    padding-bottom: 6.875rem; }
  .pdl-lg-110 {
    padding-left: 110px;
    padding-left: 6.875rem; }
  .pdr-lg-110 {
    padding-right: 110px;
    padding-right: 6.875rem; }
  .mg-lg-115 {
    margin: 115px;
    margin: 7.1875rem; }
  .mg-lg-115-0 {
    margin: 115px 0;
    margin: 7.1875rem 0; }
  .mg-lg-0-115 {
    margin: 0 115px;
    margin: 0 7.1875rem; }
  .mgt-lg-115 {
    margin-top: 115px;
    margin-top: 7.1875rem; }
  .mgb-lg-115 {
    margin-bottom: 115px;
    margin-bottom: 7.1875rem; }
  .mgl-lg-115 {
    margin-left: 115px;
    margin-left: 115px; }
  .mgr-lg-115 {
    margin-right: 115px;
    margin-right: 7.1875rem; }
  .pd-lg-115 {
    padding: 115px;
    padding: 7.1875rem; }
  .pd-lg-115-0 {
    padding: 115px 0;
    padding: 7.1875rem 0; }
  .pd-lg-0-115 {
    padding: 0 115px;
    padding: 0 7.1875rem; }
  .pdt-lg-115 {
    padding-top: 115px;
    padding-top: 7.1875rem; }
  .pdb-lg-115 {
    padding-bottom: 115px;
    padding-bottom: 7.1875rem; }
  .pdl-lg-115 {
    padding-left: 115px;
    padding-left: 7.1875rem; }
  .pdr-lg-115 {
    padding-right: 115px;
    padding-right: 7.1875rem; }
  .mg-lg-120 {
    margin: 120px;
    margin: 7.5rem; }
  .mg-lg-120-0 {
    margin: 120px 0;
    margin: 7.5rem 0; }
  .mg-lg-0-120 {
    margin: 0 120px;
    margin: 0 7.5rem; }
  .mgt-lg-120 {
    margin-top: 120px;
    margin-top: 7.5rem; }
  .mgb-lg-120 {
    margin-bottom: 120px;
    margin-bottom: 7.5rem; }
  .mgl-lg-120 {
    margin-left: 120px;
    margin-left: 120px; }
  .mgr-lg-120 {
    margin-right: 120px;
    margin-right: 7.5rem; }
  .pd-lg-120 {
    padding: 120px;
    padding: 7.5rem; }
  .pd-lg-120-0 {
    padding: 120px 0;
    padding: 7.5rem 0; }
  .pd-lg-0-120 {
    padding: 0 120px;
    padding: 0 7.5rem; }
  .pdt-lg-120 {
    padding-top: 120px;
    padding-top: 7.5rem; }
  .pdb-lg-120 {
    padding-bottom: 120px;
    padding-bottom: 7.5rem; }
  .pdl-lg-120 {
    padding-left: 120px;
    padding-left: 7.5rem; }
  .pdr-lg-120 {
    padding-right: 120px;
    padding-right: 7.5rem; }
  .mg-lg-125 {
    margin: 125px;
    margin: 7.8125rem; }
  .mg-lg-125-0 {
    margin: 125px 0;
    margin: 7.8125rem 0; }
  .mg-lg-0-125 {
    margin: 0 125px;
    margin: 0 7.8125rem; }
  .mgt-lg-125 {
    margin-top: 125px;
    margin-top: 7.8125rem; }
  .mgb-lg-125 {
    margin-bottom: 125px;
    margin-bottom: 7.8125rem; }
  .mgl-lg-125 {
    margin-left: 125px;
    margin-left: 125px; }
  .mgr-lg-125 {
    margin-right: 125px;
    margin-right: 7.8125rem; }
  .pd-lg-125 {
    padding: 125px;
    padding: 7.8125rem; }
  .pd-lg-125-0 {
    padding: 125px 0;
    padding: 7.8125rem 0; }
  .pd-lg-0-125 {
    padding: 0 125px;
    padding: 0 7.8125rem; }
  .pdt-lg-125 {
    padding-top: 125px;
    padding-top: 7.8125rem; }
  .pdb-lg-125 {
    padding-bottom: 125px;
    padding-bottom: 7.8125rem; }
  .pdl-lg-125 {
    padding-left: 125px;
    padding-left: 7.8125rem; }
  .pdr-lg-125 {
    padding-right: 125px;
    padding-right: 7.8125rem; }
  .mg-lg-130 {
    margin: 130px;
    margin: 8.125rem; }
  .mg-lg-130-0 {
    margin: 130px 0;
    margin: 8.125rem 0; }
  .mg-lg-0-130 {
    margin: 0 130px;
    margin: 0 8.125rem; }
  .mgt-lg-130 {
    margin-top: 130px;
    margin-top: 8.125rem; }
  .mgb-lg-130 {
    margin-bottom: 130px;
    margin-bottom: 8.125rem; }
  .mgl-lg-130 {
    margin-left: 130px;
    margin-left: 130px; }
  .mgr-lg-130 {
    margin-right: 130px;
    margin-right: 8.125rem; }
  .pd-lg-130 {
    padding: 130px;
    padding: 8.125rem; }
  .pd-lg-130-0 {
    padding: 130px 0;
    padding: 8.125rem 0; }
  .pd-lg-0-130 {
    padding: 0 130px;
    padding: 0 8.125rem; }
  .pdt-lg-130 {
    padding-top: 130px;
    padding-top: 8.125rem; }
  .pdb-lg-130 {
    padding-bottom: 130px;
    padding-bottom: 8.125rem; }
  .pdl-lg-130 {
    padding-left: 130px;
    padding-left: 8.125rem; }
  .pdr-lg-130 {
    padding-right: 130px;
    padding-right: 8.125rem; }
  .mg-lg-135 {
    margin: 135px;
    margin: 8.4375rem; }
  .mg-lg-135-0 {
    margin: 135px 0;
    margin: 8.4375rem 0; }
  .mg-lg-0-135 {
    margin: 0 135px;
    margin: 0 8.4375rem; }
  .mgt-lg-135 {
    margin-top: 135px;
    margin-top: 8.4375rem; }
  .mgb-lg-135 {
    margin-bottom: 135px;
    margin-bottom: 8.4375rem; }
  .mgl-lg-135 {
    margin-left: 135px;
    margin-left: 135px; }
  .mgr-lg-135 {
    margin-right: 135px;
    margin-right: 8.4375rem; }
  .pd-lg-135 {
    padding: 135px;
    padding: 8.4375rem; }
  .pd-lg-135-0 {
    padding: 135px 0;
    padding: 8.4375rem 0; }
  .pd-lg-0-135 {
    padding: 0 135px;
    padding: 0 8.4375rem; }
  .pdt-lg-135 {
    padding-top: 135px;
    padding-top: 8.4375rem; }
  .pdb-lg-135 {
    padding-bottom: 135px;
    padding-bottom: 8.4375rem; }
  .pdl-lg-135 {
    padding-left: 135px;
    padding-left: 8.4375rem; }
  .pdr-lg-135 {
    padding-right: 135px;
    padding-right: 8.4375rem; }
  .mg-lg-140 {
    margin: 140px;
    margin: 8.75rem; }
  .mg-lg-140-0 {
    margin: 140px 0;
    margin: 8.75rem 0; }
  .mg-lg-0-140 {
    margin: 0 140px;
    margin: 0 8.75rem; }
  .mgt-lg-140 {
    margin-top: 140px;
    margin-top: 8.75rem; }
  .mgb-lg-140 {
    margin-bottom: 140px;
    margin-bottom: 8.75rem; }
  .mgl-lg-140 {
    margin-left: 140px;
    margin-left: 140px; }
  .mgr-lg-140 {
    margin-right: 140px;
    margin-right: 8.75rem; }
  .pd-lg-140 {
    padding: 140px;
    padding: 8.75rem; }
  .pd-lg-140-0 {
    padding: 140px 0;
    padding: 8.75rem 0; }
  .pd-lg-0-140 {
    padding: 0 140px;
    padding: 0 8.75rem; }
  .pdt-lg-140 {
    padding-top: 140px;
    padding-top: 8.75rem; }
  .pdb-lg-140 {
    padding-bottom: 140px;
    padding-bottom: 8.75rem; }
  .pdl-lg-140 {
    padding-left: 140px;
    padding-left: 8.75rem; }
  .pdr-lg-140 {
    padding-right: 140px;
    padding-right: 8.75rem; }
  .mg-lg-145 {
    margin: 145px;
    margin: 9.0625rem; }
  .mg-lg-145-0 {
    margin: 145px 0;
    margin: 9.0625rem 0; }
  .mg-lg-0-145 {
    margin: 0 145px;
    margin: 0 9.0625rem; }
  .mgt-lg-145 {
    margin-top: 145px;
    margin-top: 9.0625rem; }
  .mgb-lg-145 {
    margin-bottom: 145px;
    margin-bottom: 9.0625rem; }
  .mgl-lg-145 {
    margin-left: 145px;
    margin-left: 145px; }
  .mgr-lg-145 {
    margin-right: 145px;
    margin-right: 9.0625rem; }
  .pd-lg-145 {
    padding: 145px;
    padding: 9.0625rem; }
  .pd-lg-145-0 {
    padding: 145px 0;
    padding: 9.0625rem 0; }
  .pd-lg-0-145 {
    padding: 0 145px;
    padding: 0 9.0625rem; }
  .pdt-lg-145 {
    padding-top: 145px;
    padding-top: 9.0625rem; }
  .pdb-lg-145 {
    padding-bottom: 145px;
    padding-bottom: 9.0625rem; }
  .pdl-lg-145 {
    padding-left: 145px;
    padding-left: 9.0625rem; }
  .pdr-lg-145 {
    padding-right: 145px;
    padding-right: 9.0625rem; }
  .mg-lg-150 {
    margin: 150px;
    margin: 9.375rem; }
  .mg-lg-150-0 {
    margin: 150px 0;
    margin: 9.375rem 0; }
  .mg-lg-0-150 {
    margin: 0 150px;
    margin: 0 9.375rem; }
  .mgt-lg-150 {
    margin-top: 150px;
    margin-top: 9.375rem; }
  .mgb-lg-150 {
    margin-bottom: 150px;
    margin-bottom: 9.375rem; }
  .mgl-lg-150 {
    margin-left: 150px;
    margin-left: 150px; }
  .mgr-lg-150 {
    margin-right: 150px;
    margin-right: 9.375rem; }
  .pd-lg-150 {
    padding: 150px;
    padding: 9.375rem; }
  .pd-lg-150-0 {
    padding: 150px 0;
    padding: 9.375rem 0; }
  .pd-lg-0-150 {
    padding: 0 150px;
    padding: 0 9.375rem; }
  .pdt-lg-150 {
    padding-top: 150px;
    padding-top: 9.375rem; }
  .pdb-lg-150 {
    padding-bottom: 150px;
    padding-bottom: 9.375rem; }
  .pdl-lg-150 {
    padding-left: 150px;
    padding-left: 9.375rem; }
  .pdr-lg-150 {
    padding-right: 150px;
    padding-right: 9.375rem; } }

@media (min-width: 1200px) {
  .text-xl-normal {
    font-weight: 400; }
  .text-xl-semibold {
    font-weight: 600; }
  .text-xl-bold {
    font-weight: 700; }
  .text-xl-right {
    text-align: right; }
  .text-xl-center {
    text-align: center; }
  .text-xl-justify {
    text-align: justify; }
  .text-xl-nowrap {
    white-space: nowrap; }
  .text-xl-lowercase {
    text-transform: lowercase; }
  .text-xl-uppercase {
    text-transform: uppercase; }
  .mg-xl-0 {
    margin: 0; }
  .mgt-xl-0 {
    margin-top: 0; }
  .mgb-xl-0 {
    margin-bottom: 0; }
  .mgl-xl-0 {
    margin-left: 0; }
  .mgr-xl-0 {
    margin-right: 0; }
  .pd-xl-0 {
    padding: 0; }
  .pdt-xl-0 {
    padding-top: 0; }
  .pdb-xl-0 {
    padding-bottom: 0; }
  .pdl-xl-0 {
    padding-left: 0; }
  .pdr-xl-0 {
    padding-right: 0; }
  .mg-xl-5 {
    margin: 5px;
    margin: 0.3125rem; }
  -mg.xl-5-0 {
    margin: 5px 0;
    margin: 0.3125rem 0; }
  .mg-xl-0-5 {
    margin: 0 5px;
    margin: 0 0.3125rem; }
  .mgt-xl-5 {
    margin-top: 5px;
    margin-top: 0.3125rem; }
  .mgb-xl-5 {
    margin-bottom: 5px;
    margin-bottom: 0.3125rem; }
  .mgl-xl-5 {
    margin-left: 5px;
    margin-left: 0.3125rem; }
  .mgr-xl-5 {
    margin-right: 5px;
    margin-right: 0.3125rem; }
  .pd-xl-5 {
    padding: 5px;
    padding: 0.3125rem; }
  .pd-xl-5-0 {
    padding: 5px 0;
    padding: 0.3125rem 0; }
  .pd-xl-0-5 {
    padding: 0 5px;
    padding: 0 0.3125rem; }
  .pdt-xl-5 {
    padding-top: 5px;
    padding-top: 0.3125rem; }
  .pdb-xl-5 {
    padding-bottom: 5px;
    padding-bottom: 0.3125rem; }
  .pdl-xl-5 {
    padding-left: 0.3125rem; }
  .pdr-xl-5 {
    padding-right: 5px;
    padding-right: 0.3125rem; }
  .mg-xl-10 {
    margin: 10px;
    margin: 0.625rem; }
  -mg.xl-10-0 {
    margin: 10px 0;
    margin: 0.625rem 0; }
  .mg-xl-0-10 {
    margin: 0 10px;
    margin: 0 0.625rem; }
  .mgt-xl-10 {
    margin-top: 10px;
    margin-top: 0.625rem; }
  .mgb-xl-10 {
    margin-bottom: 10px;
    margin-bottom: 0.625rem; }
  .mgl-xl-10 {
    margin-left: 10px;
    margin-left: 0.625rem; }
  .mgr-xl-10 {
    margin-right: 10px;
    margin-right: 0.625rem; }
  .pd-xl-10 {
    padding: 10px;
    padding: 0.625rem; }
  .pd-xl-10-0 {
    padding: 10px 0;
    padding: 0.625rem 0; }
  .pd-xl-0-10 {
    padding: 0 10px;
    padding: 0 0.625rem; }
  .pdt-xl-10 {
    padding-top: 10px;
    padding-top: 0.625rem; }
  .pdb-xl-10 {
    padding-bottom: 10px;
    padding-bottom: 0.625rem; }
  .pdl-xl-10 {
    padding-left: 0.625rem; }
  .pdr-xl-10 {
    padding-right: 10px;
    padding-right: 0.625rem; }
  .mg-xl-15 {
    margin: 15px;
    margin: 0.9375rem; }
  -mg.xl-15-0 {
    margin: 15px 0;
    margin: 0.9375rem 0; }
  .mg-xl-0-15 {
    margin: 0 15px;
    margin: 0 0.9375rem; }
  .mgt-xl-15 {
    margin-top: 15px;
    margin-top: 0.9375rem; }
  .mgb-xl-15 {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem; }
  .mgl-xl-15 {
    margin-left: 15px;
    margin-left: 0.9375rem; }
  .mgr-xl-15 {
    margin-right: 15px;
    margin-right: 0.9375rem; }
  .pd-xl-15 {
    padding: 15px;
    padding: 0.9375rem; }
  .pd-xl-15-0 {
    padding: 15px 0;
    padding: 0.9375rem 0; }
  .pd-xl-0-15 {
    padding: 0 15px;
    padding: 0 0.9375rem; }
  .pdt-xl-15 {
    padding-top: 15px;
    padding-top: 0.9375rem; }
  .pdb-xl-15 {
    padding-bottom: 15px;
    padding-bottom: 0.9375rem; }
  .pdl-xl-15 {
    padding-left: 0.9375rem; }
  .pdr-xl-15 {
    padding-right: 15px;
    padding-right: 0.9375rem; }
  .mg-xl-20 {
    margin: 20px;
    margin: 1.25rem; }
  -mg.xl-20-0 {
    margin: 20px 0;
    margin: 1.25rem 0; }
  .mg-xl-0-20 {
    margin: 0 20px;
    margin: 0 1.25rem; }
  .mgt-xl-20 {
    margin-top: 20px;
    margin-top: 1.25rem; }
  .mgb-xl-20 {
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  .mgl-xl-20 {
    margin-left: 20px;
    margin-left: 1.25rem; }
  .mgr-xl-20 {
    margin-right: 20px;
    margin-right: 1.25rem; }
  .pd-xl-20 {
    padding: 20px;
    padding: 1.25rem; }
  .pd-xl-20-0 {
    padding: 20px 0;
    padding: 1.25rem 0; }
  .pd-xl-0-20 {
    padding: 0 20px;
    padding: 0 1.25rem; }
  .pdt-xl-20 {
    padding-top: 20px;
    padding-top: 1.25rem; }
  .pdb-xl-20 {
    padding-bottom: 20px;
    padding-bottom: 1.25rem; }
  .pdl-xl-20 {
    padding-left: 1.25rem; }
  .pdr-xl-20 {
    padding-right: 20px;
    padding-right: 1.25rem; }
  .mg-xl-25 {
    margin: 25px;
    margin: 1.5625rem; }
  -mg.xl-25-0 {
    margin: 25px 0;
    margin: 1.5625rem 0; }
  .mg-xl-0-25 {
    margin: 0 25px;
    margin: 0 1.5625rem; }
  .mgt-xl-25 {
    margin-top: 25px;
    margin-top: 1.5625rem; }
  .mgb-xl-25 {
    margin-bottom: 25px;
    margin-bottom: 1.5625rem; }
  .mgl-xl-25 {
    margin-left: 25px;
    margin-left: 1.5625rem; }
  .mgr-xl-25 {
    margin-right: 25px;
    margin-right: 1.5625rem; }
  .pd-xl-25 {
    padding: 25px;
    padding: 1.5625rem; }
  .pd-xl-25-0 {
    padding: 25px 0;
    padding: 1.5625rem 0; }
  .pd-xl-0-25 {
    padding: 0 25px;
    padding: 0 1.5625rem; }
  .pdt-xl-25 {
    padding-top: 25px;
    padding-top: 1.5625rem; }
  .pdb-xl-25 {
    padding-bottom: 25px;
    padding-bottom: 1.5625rem; }
  .pdl-xl-25 {
    padding-left: 1.5625rem; }
  .pdr-xl-25 {
    padding-right: 25px;
    padding-right: 1.5625rem; }
  .mg-xl-30 {
    margin: 30px;
    margin: 1.875rem; }
  -mg.xl-30-0 {
    margin: 30px 0;
    margin: 1.875rem 0; }
  .mg-xl-0-30 {
    margin: 0 30px;
    margin: 0 1.875rem; }
  .mgt-xl-30 {
    margin-top: 30px;
    margin-top: 1.875rem; }
  .mgb-xl-30 {
    margin-bottom: 30px;
    margin-bottom: 1.875rem; }
  .mgl-xl-30 {
    margin-left: 30px;
    margin-left: 1.875rem; }
  .mgr-xl-30 {
    margin-right: 30px;
    margin-right: 1.875rem; }
  .pd-xl-30 {
    padding: 30px;
    padding: 1.875rem; }
  .pd-xl-30-0 {
    padding: 30px 0;
    padding: 1.875rem 0; }
  .pd-xl-0-30 {
    padding: 0 30px;
    padding: 0 1.875rem; }
  .pdt-xl-30 {
    padding-top: 30px;
    padding-top: 1.875rem; }
  .pdb-xl-30 {
    padding-bottom: 30px;
    padding-bottom: 1.875rem; }
  .pdl-xl-30 {
    padding-left: 1.875rem; }
  .pdr-xl-30 {
    padding-right: 30px;
    padding-right: 1.875rem; }
  .mg-xl-35 {
    margin: 35px;
    margin: 2.1875rem; }
  -mg.xl-35-0 {
    margin: 35px 0;
    margin: 2.1875rem 0; }
  .mg-xl-0-35 {
    margin: 0 35px;
    margin: 0 2.1875rem; }
  .mgt-xl-35 {
    margin-top: 35px;
    margin-top: 2.1875rem; }
  .mgb-xl-35 {
    margin-bottom: 35px;
    margin-bottom: 2.1875rem; }
  .mgl-xl-35 {
    margin-left: 35px;
    margin-left: 2.1875rem; }
  .mgr-xl-35 {
    margin-right: 35px;
    margin-right: 2.1875rem; }
  .pd-xl-35 {
    padding: 35px;
    padding: 2.1875rem; }
  .pd-xl-35-0 {
    padding: 35px 0;
    padding: 2.1875rem 0; }
  .pd-xl-0-35 {
    padding: 0 35px;
    padding: 0 2.1875rem; }
  .pdt-xl-35 {
    padding-top: 35px;
    padding-top: 2.1875rem; }
  .pdb-xl-35 {
    padding-bottom: 35px;
    padding-bottom: 2.1875rem; }
  .pdl-xl-35 {
    padding-left: 2.1875rem; }
  .pdr-xl-35 {
    padding-right: 35px;
    padding-right: 2.1875rem; }
  .mg-xl-40 {
    margin: 40px;
    margin: 2.5rem; }
  -mg.xl-40-0 {
    margin: 40px 0;
    margin: 2.5rem 0; }
  .mg-xl-0-40 {
    margin: 0 40px;
    margin: 0 2.5rem; }
  .mgt-xl-40 {
    margin-top: 40px;
    margin-top: 2.5rem; }
  .mgb-xl-40 {
    margin-bottom: 40px;
    margin-bottom: 2.5rem; }
  .mgl-xl-40 {
    margin-left: 40px;
    margin-left: 2.5rem; }
  .mgr-xl-40 {
    margin-right: 40px;
    margin-right: 2.5rem; }
  .pd-xl-40 {
    padding: 40px;
    padding: 2.5rem; }
  .pd-xl-40-0 {
    padding: 40px 0;
    padding: 2.5rem 0; }
  .pd-xl-0-40 {
    padding: 0 40px;
    padding: 0 2.5rem; }
  .pdt-xl-40 {
    padding-top: 40px;
    padding-top: 2.5rem; }
  .pdb-xl-40 {
    padding-bottom: 40px;
    padding-bottom: 2.5rem; }
  .pdl-xl-40 {
    padding-left: 2.5rem; }
  .pdr-xl-40 {
    padding-right: 40px;
    padding-right: 2.5rem; }
  .mg-xl-45 {
    margin: 45px;
    margin: 2.8125rem; }
  -mg.xl-45-0 {
    margin: 45px 0;
    margin: 2.8125rem 0; }
  .mg-xl-0-45 {
    margin: 0 45px;
    margin: 0 2.8125rem; }
  .mgt-xl-45 {
    margin-top: 45px;
    margin-top: 2.8125rem; }
  .mgb-xl-45 {
    margin-bottom: 45px;
    margin-bottom: 2.8125rem; }
  .mgl-xl-45 {
    margin-left: 45px;
    margin-left: 2.8125rem; }
  .mgr-xl-45 {
    margin-right: 45px;
    margin-right: 2.8125rem; }
  .pd-xl-45 {
    padding: 45px;
    padding: 2.8125rem; }
  .pd-xl-45-0 {
    padding: 45px 0;
    padding: 2.8125rem 0; }
  .pd-xl-0-45 {
    padding: 0 45px;
    padding: 0 2.8125rem; }
  .pdt-xl-45 {
    padding-top: 45px;
    padding-top: 2.8125rem; }
  .pdb-xl-45 {
    padding-bottom: 45px;
    padding-bottom: 2.8125rem; }
  .pdl-xl-45 {
    padding-left: 2.8125rem; }
  .pdr-xl-45 {
    padding-right: 45px;
    padding-right: 2.8125rem; }
  .mg-xl-50 {
    margin: 50px;
    margin: 3.125rem; }
  -mg.xl-50-0 {
    margin: 50px 0;
    margin: 3.125rem 0; }
  .mg-xl-0-50 {
    margin: 0 50px;
    margin: 0 3.125rem; }
  .mgt-xl-50 {
    margin-top: 50px;
    margin-top: 3.125rem; }
  .mgb-xl-50 {
    margin-bottom: 50px;
    margin-bottom: 3.125rem; }
  .mgl-xl-50 {
    margin-left: 50px;
    margin-left: 3.125rem; }
  .mgr-xl-50 {
    margin-right: 50px;
    margin-right: 3.125rem; }
  .pd-xl-50 {
    padding: 50px;
    padding: 3.125rem; }
  .pd-xl-50-0 {
    padding: 50px 0;
    padding: 3.125rem 0; }
  .pd-xl-0-50 {
    padding: 0 50px;
    padding: 0 3.125rem; }
  .pdt-xl-50 {
    padding-top: 50px;
    padding-top: 3.125rem; }
  .pdb-xl-50 {
    padding-bottom: 50px;
    padding-bottom: 3.125rem; }
  .pdl-xl-50 {
    padding-left: 3.125rem; }
  .pdr-xl-50 {
    padding-right: 50px;
    padding-right: 3.125rem; }
  .mg-xl-55 {
    margin: 55px;
    margin: 3.4375rem; }
  -mg.xl-55-0 {
    margin: 55px 0;
    margin: 3.4375rem 0; }
  .mg-xl-0-55 {
    margin: 0 55px;
    margin: 0 3.4375rem; }
  .mgt-xl-55 {
    margin-top: 55px;
    margin-top: 3.4375rem; }
  .mgb-xl-55 {
    margin-bottom: 55px;
    margin-bottom: 3.4375rem; }
  .mgl-xl-55 {
    margin-left: 55px;
    margin-left: 3.4375rem; }
  .mgr-xl-55 {
    margin-right: 55px;
    margin-right: 3.4375rem; }
  .pd-xl-55 {
    padding: 55px;
    padding: 3.4375rem; }
  .pd-xl-55-0 {
    padding: 55px 0;
    padding: 3.4375rem 0; }
  .pd-xl-0-55 {
    padding: 0 55px;
    padding: 0 3.4375rem; }
  .pdt-xl-55 {
    padding-top: 55px;
    padding-top: 3.4375rem; }
  .pdb-xl-55 {
    padding-bottom: 55px;
    padding-bottom: 3.4375rem; }
  .pdl-xl-55 {
    padding-left: 3.4375rem; }
  .pdr-xl-55 {
    padding-right: 55px;
    padding-right: 3.4375rem; }
  .mg-xl-60 {
    margin: 60px;
    margin: 3.75rem; }
  -mg.xl-60-0 {
    margin: 60px 0;
    margin: 3.75rem 0; }
  .mg-xl-0-60 {
    margin: 0 60px;
    margin: 0 3.75rem; }
  .mgt-xl-60 {
    margin-top: 60px;
    margin-top: 3.75rem; }
  .mgb-xl-60 {
    margin-bottom: 60px;
    margin-bottom: 3.75rem; }
  .mgl-xl-60 {
    margin-left: 60px;
    margin-left: 3.75rem; }
  .mgr-xl-60 {
    margin-right: 60px;
    margin-right: 3.75rem; }
  .pd-xl-60 {
    padding: 60px;
    padding: 3.75rem; }
  .pd-xl-60-0 {
    padding: 60px 0;
    padding: 3.75rem 0; }
  .pd-xl-0-60 {
    padding: 0 60px;
    padding: 0 3.75rem; }
  .pdt-xl-60 {
    padding-top: 60px;
    padding-top: 3.75rem; }
  .pdb-xl-60 {
    padding-bottom: 60px;
    padding-bottom: 3.75rem; }
  .pdl-xl-60 {
    padding-left: 3.75rem; }
  .pdr-xl-60 {
    padding-right: 60px;
    padding-right: 3.75rem; }
  .mg-xl-65 {
    margin: 65px;
    margin: 4.0625rem; }
  -mg.xl-65-0 {
    margin: 65px 0;
    margin: 4.0625rem 0; }
  .mg-xl-0-65 {
    margin: 0 65px;
    margin: 0 4.0625rem; }
  .mgt-xl-65 {
    margin-top: 65px;
    margin-top: 4.0625rem; }
  .mgb-xl-65 {
    margin-bottom: 65px;
    margin-bottom: 4.0625rem; }
  .mgl-xl-65 {
    margin-left: 65px;
    margin-left: 4.0625rem; }
  .mgr-xl-65 {
    margin-right: 65px;
    margin-right: 4.0625rem; }
  .pd-xl-65 {
    padding: 65px;
    padding: 4.0625rem; }
  .pd-xl-65-0 {
    padding: 65px 0;
    padding: 4.0625rem 0; }
  .pd-xl-0-65 {
    padding: 0 65px;
    padding: 0 4.0625rem; }
  .pdt-xl-65 {
    padding-top: 65px;
    padding-top: 4.0625rem; }
  .pdb-xl-65 {
    padding-bottom: 65px;
    padding-bottom: 4.0625rem; }
  .pdl-xl-65 {
    padding-left: 4.0625rem; }
  .pdr-xl-65 {
    padding-right: 65px;
    padding-right: 4.0625rem; }
  .mg-xl-70 {
    margin: 70px;
    margin: 4.375rem; }
  -mg.xl-70-0 {
    margin: 70px 0;
    margin: 4.375rem 0; }
  .mg-xl-0-70 {
    margin: 0 70px;
    margin: 0 4.375rem; }
  .mgt-xl-70 {
    margin-top: 70px;
    margin-top: 4.375rem; }
  .mgb-xl-70 {
    margin-bottom: 70px;
    margin-bottom: 4.375rem; }
  .mgl-xl-70 {
    margin-left: 70px;
    margin-left: 4.375rem; }
  .mgr-xl-70 {
    margin-right: 70px;
    margin-right: 4.375rem; }
  .pd-xl-70 {
    padding: 70px;
    padding: 4.375rem; }
  .pd-xl-70-0 {
    padding: 70px 0;
    padding: 4.375rem 0; }
  .pd-xl-0-70 {
    padding: 0 70px;
    padding: 0 4.375rem; }
  .pdt-xl-70 {
    padding-top: 70px;
    padding-top: 4.375rem; }
  .pdb-xl-70 {
    padding-bottom: 70px;
    padding-bottom: 4.375rem; }
  .pdl-xl-70 {
    padding-left: 4.375rem; }
  .pdr-xl-70 {
    padding-right: 70px;
    padding-right: 4.375rem; }
  .mg-xl-75 {
    margin: 75px;
    margin: 4.6875rem; }
  -mg.xl-75-0 {
    margin: 75px 0;
    margin: 4.6875rem 0; }
  .mg-xl-0-75 {
    margin: 0 75px;
    margin: 0 4.6875rem; }
  .mgt-xl-75 {
    margin-top: 75px;
    margin-top: 4.6875rem; }
  .mgb-xl-75 {
    margin-bottom: 75px;
    margin-bottom: 4.6875rem; }
  .mgl-xl-75 {
    margin-left: 75px;
    margin-left: 4.6875rem; }
  .mgr-xl-75 {
    margin-right: 75px;
    margin-right: 4.6875rem; }
  .pd-xl-75 {
    padding: 75px;
    padding: 4.6875rem; }
  .pd-xl-75-0 {
    padding: 75px 0;
    padding: 4.6875rem 0; }
  .pd-xl-0-75 {
    padding: 0 75px;
    padding: 0 4.6875rem; }
  .pdt-xl-75 {
    padding-top: 75px;
    padding-top: 4.6875rem; }
  .pdb-xl-75 {
    padding-bottom: 75px;
    padding-bottom: 4.6875rem; }
  .pdl-xl-75 {
    padding-left: 4.6875rem; }
  .pdr-xl-75 {
    padding-right: 75px;
    padding-right: 4.6875rem; }
  .mg-xl-80 {
    margin: 80px;
    margin: 5rem; }
  -mg.xl-80-0 {
    margin: 80px 0;
    margin: 5rem 0; }
  .mg-xl-0-80 {
    margin: 0 80px;
    margin: 0 5rem; }
  .mgt-xl-80 {
    margin-top: 80px;
    margin-top: 5rem; }
  .mgb-xl-80 {
    margin-bottom: 80px;
    margin-bottom: 5rem; }
  .mgl-xl-80 {
    margin-left: 80px;
    margin-left: 5rem; }
  .mgr-xl-80 {
    margin-right: 80px;
    margin-right: 5rem; }
  .pd-xl-80 {
    padding: 80px;
    padding: 5rem; }
  .pd-xl-80-0 {
    padding: 80px 0;
    padding: 5rem 0; }
  .pd-xl-0-80 {
    padding: 0 80px;
    padding: 0 5rem; }
  .pdt-xl-80 {
    padding-top: 80px;
    padding-top: 5rem; }
  .pdb-xl-80 {
    padding-bottom: 80px;
    padding-bottom: 5rem; }
  .pdl-xl-80 {
    padding-left: 5rem; }
  .pdr-xl-80 {
    padding-right: 80px;
    padding-right: 5rem; }
  .mg-xl-85 {
    margin: 85px;
    margin: 5.3125rem; }
  -mg.xl-85-0 {
    margin: 85px 0;
    margin: 5.3125rem 0; }
  .mg-xl-0-85 {
    margin: 0 85px;
    margin: 0 5.3125rem; }
  .mgt-xl-85 {
    margin-top: 85px;
    margin-top: 5.3125rem; }
  .mgb-xl-85 {
    margin-bottom: 85px;
    margin-bottom: 5.3125rem; }
  .mgl-xl-85 {
    margin-left: 85px;
    margin-left: 5.3125rem; }
  .mgr-xl-85 {
    margin-right: 85px;
    margin-right: 5.3125rem; }
  .pd-xl-85 {
    padding: 85px;
    padding: 5.3125rem; }
  .pd-xl-85-0 {
    padding: 85px 0;
    padding: 5.3125rem 0; }
  .pd-xl-0-85 {
    padding: 0 85px;
    padding: 0 5.3125rem; }
  .pdt-xl-85 {
    padding-top: 85px;
    padding-top: 5.3125rem; }
  .pdb-xl-85 {
    padding-bottom: 85px;
    padding-bottom: 5.3125rem; }
  .pdl-xl-85 {
    padding-left: 5.3125rem; }
  .pdr-xl-85 {
    padding-right: 85px;
    padding-right: 5.3125rem; }
  .mg-xl-90 {
    margin: 90px;
    margin: 5.625rem; }
  -mg.xl-90-0 {
    margin: 90px 0;
    margin: 5.625rem 0; }
  .mg-xl-0-90 {
    margin: 0 90px;
    margin: 0 5.625rem; }
  .mgt-xl-90 {
    margin-top: 90px;
    margin-top: 5.625rem; }
  .mgb-xl-90 {
    margin-bottom: 90px;
    margin-bottom: 5.625rem; }
  .mgl-xl-90 {
    margin-left: 90px;
    margin-left: 5.625rem; }
  .mgr-xl-90 {
    margin-right: 90px;
    margin-right: 5.625rem; }
  .pd-xl-90 {
    padding: 90px;
    padding: 5.625rem; }
  .pd-xl-90-0 {
    padding: 90px 0;
    padding: 5.625rem 0; }
  .pd-xl-0-90 {
    padding: 0 90px;
    padding: 0 5.625rem; }
  .pdt-xl-90 {
    padding-top: 90px;
    padding-top: 5.625rem; }
  .pdb-xl-90 {
    padding-bottom: 90px;
    padding-bottom: 5.625rem; }
  .pdl-xl-90 {
    padding-left: 5.625rem; }
  .pdr-xl-90 {
    padding-right: 90px;
    padding-right: 5.625rem; }
  .mg-xl-95 {
    margin: 95px;
    margin: 5.9375rem; }
  -mg.xl-95-0 {
    margin: 95px 0;
    margin: 5.9375rem 0; }
  .mg-xl-0-95 {
    margin: 0 95px;
    margin: 0 5.9375rem; }
  .mgt-xl-95 {
    margin-top: 95px;
    margin-top: 5.9375rem; }
  .mgb-xl-95 {
    margin-bottom: 95px;
    margin-bottom: 5.9375rem; }
  .mgl-xl-95 {
    margin-left: 95px;
    margin-left: 5.9375rem; }
  .mgr-xl-95 {
    margin-right: 95px;
    margin-right: 5.9375rem; }
  .pd-xl-95 {
    padding: 95px;
    padding: 5.9375rem; }
  .pd-xl-95-0 {
    padding: 95px 0;
    padding: 5.9375rem 0; }
  .pd-xl-0-95 {
    padding: 0 95px;
    padding: 0 5.9375rem; }
  .pdt-xl-95 {
    padding-top: 95px;
    padding-top: 5.9375rem; }
  .pdb-xl-95 {
    padding-bottom: 95px;
    padding-bottom: 5.9375rem; }
  .pdl-xl-95 {
    padding-left: 5.9375rem; }
  .pdr-xl-95 {
    padding-right: 95px;
    padding-right: 5.9375rem; }
  .mg-xl-100 {
    margin: 100px;
    margin: 6.25rem; }
  -mg.xl-100-0 {
    margin: 100px 0;
    margin: 6.25rem 0; }
  .mg-xl-0-100 {
    margin: 0 100px;
    margin: 0 6.25rem; }
  .mgt-xl-100 {
    margin-top: 100px;
    margin-top: 6.25rem; }
  .mgb-xl-100 {
    margin-bottom: 100px;
    margin-bottom: 6.25rem; }
  .mgl-xl-100 {
    margin-left: 100px;
    margin-left: 6.25rem; }
  .mgr-xl-100 {
    margin-right: 100px;
    margin-right: 6.25rem; }
  .pd-xl-100 {
    padding: 100px;
    padding: 6.25rem; }
  .pd-xl-100-0 {
    padding: 100px 0;
    padding: 6.25rem 0; }
  .pd-xl-0-100 {
    padding: 0 100px;
    padding: 0 6.25rem; }
  .pdt-xl-100 {
    padding-top: 100px;
    padding-top: 6.25rem; }
  .pdb-xl-100 {
    padding-bottom: 100px;
    padding-bottom: 6.25rem; }
  .pdl-xl-100 {
    padding-left: 6.25rem; }
  .pdr-xl-100 {
    padding-right: 100px;
    padding-right: 6.25rem; }
  .mg-xl-105 {
    margin: 105px;
    margin: 6.5625rem; }
  -mg.xl-105-0 {
    margin: 105px 0;
    margin: 6.5625rem 0; }
  .mg-xl-0-105 {
    margin: 0 105px;
    margin: 0 6.5625rem; }
  .mgt-xl-105 {
    margin-top: 105px;
    margin-top: 6.5625rem; }
  .mgb-xl-105 {
    margin-bottom: 105px;
    margin-bottom: 6.5625rem; }
  .mgl-xl-105 {
    margin-left: 105px;
    margin-left: 6.5625rem; }
  .mgr-xl-105 {
    margin-right: 105px;
    margin-right: 6.5625rem; }
  .pd-xl-105 {
    padding: 105px;
    padding: 6.5625rem; }
  .pd-xl-105-0 {
    padding: 105px 0;
    padding: 6.5625rem 0; }
  .pd-xl-0-105 {
    padding: 0 105px;
    padding: 0 6.5625rem; }
  .pdt-xl-105 {
    padding-top: 105px;
    padding-top: 6.5625rem; }
  .pdb-xl-105 {
    padding-bottom: 105px;
    padding-bottom: 6.5625rem; }
  .pdl-xl-105 {
    padding-left: 6.5625rem; }
  .pdr-xl-105 {
    padding-right: 105px;
    padding-right: 6.5625rem; }
  .mg-xl-110 {
    margin: 110px;
    margin: 6.875rem; }
  -mg.xl-110-0 {
    margin: 110px 0;
    margin: 6.875rem 0; }
  .mg-xl-0-110 {
    margin: 0 110px;
    margin: 0 6.875rem; }
  .mgt-xl-110 {
    margin-top: 110px;
    margin-top: 6.875rem; }
  .mgb-xl-110 {
    margin-bottom: 110px;
    margin-bottom: 6.875rem; }
  .mgl-xl-110 {
    margin-left: 110px;
    margin-left: 6.875rem; }
  .mgr-xl-110 {
    margin-right: 110px;
    margin-right: 6.875rem; }
  .pd-xl-110 {
    padding: 110px;
    padding: 6.875rem; }
  .pd-xl-110-0 {
    padding: 110px 0;
    padding: 6.875rem 0; }
  .pd-xl-0-110 {
    padding: 0 110px;
    padding: 0 6.875rem; }
  .pdt-xl-110 {
    padding-top: 110px;
    padding-top: 6.875rem; }
  .pdb-xl-110 {
    padding-bottom: 110px;
    padding-bottom: 6.875rem; }
  .pdl-xl-110 {
    padding-left: 6.875rem; }
  .pdr-xl-110 {
    padding-right: 110px;
    padding-right: 6.875rem; }
  .mg-xl-115 {
    margin: 115px;
    margin: 7.1875rem; }
  -mg.xl-115-0 {
    margin: 115px 0;
    margin: 7.1875rem 0; }
  .mg-xl-0-115 {
    margin: 0 115px;
    margin: 0 7.1875rem; }
  .mgt-xl-115 {
    margin-top: 115px;
    margin-top: 7.1875rem; }
  .mgb-xl-115 {
    margin-bottom: 115px;
    margin-bottom: 7.1875rem; }
  .mgl-xl-115 {
    margin-left: 115px;
    margin-left: 7.1875rem; }
  .mgr-xl-115 {
    margin-right: 115px;
    margin-right: 7.1875rem; }
  .pd-xl-115 {
    padding: 115px;
    padding: 7.1875rem; }
  .pd-xl-115-0 {
    padding: 115px 0;
    padding: 7.1875rem 0; }
  .pd-xl-0-115 {
    padding: 0 115px;
    padding: 0 7.1875rem; }
  .pdt-xl-115 {
    padding-top: 115px;
    padding-top: 7.1875rem; }
  .pdb-xl-115 {
    padding-bottom: 115px;
    padding-bottom: 7.1875rem; }
  .pdl-xl-115 {
    padding-left: 7.1875rem; }
  .pdr-xl-115 {
    padding-right: 115px;
    padding-right: 7.1875rem; }
  .mg-xl-120 {
    margin: 120px;
    margin: 7.5rem; }
  -mg.xl-120-0 {
    margin: 120px 0;
    margin: 7.5rem 0; }
  .mg-xl-0-120 {
    margin: 0 120px;
    margin: 0 7.5rem; }
  .mgt-xl-120 {
    margin-top: 120px;
    margin-top: 7.5rem; }
  .mgb-xl-120 {
    margin-bottom: 120px;
    margin-bottom: 7.5rem; }
  .mgl-xl-120 {
    margin-left: 120px;
    margin-left: 7.5rem; }
  .mgr-xl-120 {
    margin-right: 120px;
    margin-right: 7.5rem; }
  .pd-xl-120 {
    padding: 120px;
    padding: 7.5rem; }
  .pd-xl-120-0 {
    padding: 120px 0;
    padding: 7.5rem 0; }
  .pd-xl-0-120 {
    padding: 0 120px;
    padding: 0 7.5rem; }
  .pdt-xl-120 {
    padding-top: 120px;
    padding-top: 7.5rem; }
  .pdb-xl-120 {
    padding-bottom: 120px;
    padding-bottom: 7.5rem; }
  .pdl-xl-120 {
    padding-left: 7.5rem; }
  .pdr-xl-120 {
    padding-right: 120px;
    padding-right: 7.5rem; }
  .mg-xl-125 {
    margin: 125px;
    margin: 7.8125rem; }
  -mg.xl-125-0 {
    margin: 125px 0;
    margin: 7.8125rem 0; }
  .mg-xl-0-125 {
    margin: 0 125px;
    margin: 0 7.8125rem; }
  .mgt-xl-125 {
    margin-top: 125px;
    margin-top: 7.8125rem; }
  .mgb-xl-125 {
    margin-bottom: 125px;
    margin-bottom: 7.8125rem; }
  .mgl-xl-125 {
    margin-left: 125px;
    margin-left: 7.8125rem; }
  .mgr-xl-125 {
    margin-right: 125px;
    margin-right: 7.8125rem; }
  .pd-xl-125 {
    padding: 125px;
    padding: 7.8125rem; }
  .pd-xl-125-0 {
    padding: 125px 0;
    padding: 7.8125rem 0; }
  .pd-xl-0-125 {
    padding: 0 125px;
    padding: 0 7.8125rem; }
  .pdt-xl-125 {
    padding-top: 125px;
    padding-top: 7.8125rem; }
  .pdb-xl-125 {
    padding-bottom: 125px;
    padding-bottom: 7.8125rem; }
  .pdl-xl-125 {
    padding-left: 7.8125rem; }
  .pdr-xl-125 {
    padding-right: 125px;
    padding-right: 7.8125rem; }
  .mg-xl-130 {
    margin: 130px;
    margin: 8.125rem; }
  -mg.xl-130-0 {
    margin: 130px 0;
    margin: 8.125rem 0; }
  .mg-xl-0-130 {
    margin: 0 130px;
    margin: 0 8.125rem; }
  .mgt-xl-130 {
    margin-top: 130px;
    margin-top: 8.125rem; }
  .mgb-xl-130 {
    margin-bottom: 130px;
    margin-bottom: 8.125rem; }
  .mgl-xl-130 {
    margin-left: 130px;
    margin-left: 8.125rem; }
  .mgr-xl-130 {
    margin-right: 130px;
    margin-right: 8.125rem; }
  .pd-xl-130 {
    padding: 130px;
    padding: 8.125rem; }
  .pd-xl-130-0 {
    padding: 130px 0;
    padding: 8.125rem 0; }
  .pd-xl-0-130 {
    padding: 0 130px;
    padding: 0 8.125rem; }
  .pdt-xl-130 {
    padding-top: 130px;
    padding-top: 8.125rem; }
  .pdb-xl-130 {
    padding-bottom: 130px;
    padding-bottom: 8.125rem; }
  .pdl-xl-130 {
    padding-left: 8.125rem; }
  .pdr-xl-130 {
    padding-right: 130px;
    padding-right: 8.125rem; }
  .mg-xl-135 {
    margin: 135px;
    margin: 8.4375rem; }
  -mg.xl-135-0 {
    margin: 135px 0;
    margin: 8.4375rem 0; }
  .mg-xl-0-135 {
    margin: 0 135px;
    margin: 0 8.4375rem; }
  .mgt-xl-135 {
    margin-top: 135px;
    margin-top: 8.4375rem; }
  .mgb-xl-135 {
    margin-bottom: 135px;
    margin-bottom: 8.4375rem; }
  .mgl-xl-135 {
    margin-left: 135px;
    margin-left: 8.4375rem; }
  .mgr-xl-135 {
    margin-right: 135px;
    margin-right: 8.4375rem; }
  .pd-xl-135 {
    padding: 135px;
    padding: 8.4375rem; }
  .pd-xl-135-0 {
    padding: 135px 0;
    padding: 8.4375rem 0; }
  .pd-xl-0-135 {
    padding: 0 135px;
    padding: 0 8.4375rem; }
  .pdt-xl-135 {
    padding-top: 135px;
    padding-top: 8.4375rem; }
  .pdb-xl-135 {
    padding-bottom: 135px;
    padding-bottom: 8.4375rem; }
  .pdl-xl-135 {
    padding-left: 8.4375rem; }
  .pdr-xl-135 {
    padding-right: 135px;
    padding-right: 8.4375rem; }
  .mg-xl-140 {
    margin: 140px;
    margin: 8.75rem; }
  -mg.xl-140-0 {
    margin: 140px 0;
    margin: 8.75rem 0; }
  .mg-xl-0-140 {
    margin: 0 140px;
    margin: 0 8.75rem; }
  .mgt-xl-140 {
    margin-top: 140px;
    margin-top: 8.75rem; }
  .mgb-xl-140 {
    margin-bottom: 140px;
    margin-bottom: 8.75rem; }
  .mgl-xl-140 {
    margin-left: 140px;
    margin-left: 8.75rem; }
  .mgr-xl-140 {
    margin-right: 140px;
    margin-right: 8.75rem; }
  .pd-xl-140 {
    padding: 140px;
    padding: 8.75rem; }
  .pd-xl-140-0 {
    padding: 140px 0;
    padding: 8.75rem 0; }
  .pd-xl-0-140 {
    padding: 0 140px;
    padding: 0 8.75rem; }
  .pdt-xl-140 {
    padding-top: 140px;
    padding-top: 8.75rem; }
  .pdb-xl-140 {
    padding-bottom: 140px;
    padding-bottom: 8.75rem; }
  .pdl-xl-140 {
    padding-left: 8.75rem; }
  .pdr-xl-140 {
    padding-right: 140px;
    padding-right: 8.75rem; }
  .mg-xl-145 {
    margin: 145px;
    margin: 9.0625rem; }
  -mg.xl-145-0 {
    margin: 145px 0;
    margin: 9.0625rem 0; }
  .mg-xl-0-145 {
    margin: 0 145px;
    margin: 0 9.0625rem; }
  .mgt-xl-145 {
    margin-top: 145px;
    margin-top: 9.0625rem; }
  .mgb-xl-145 {
    margin-bottom: 145px;
    margin-bottom: 9.0625rem; }
  .mgl-xl-145 {
    margin-left: 145px;
    margin-left: 9.0625rem; }
  .mgr-xl-145 {
    margin-right: 145px;
    margin-right: 9.0625rem; }
  .pd-xl-145 {
    padding: 145px;
    padding: 9.0625rem; }
  .pd-xl-145-0 {
    padding: 145px 0;
    padding: 9.0625rem 0; }
  .pd-xl-0-145 {
    padding: 0 145px;
    padding: 0 9.0625rem; }
  .pdt-xl-145 {
    padding-top: 145px;
    padding-top: 9.0625rem; }
  .pdb-xl-145 {
    padding-bottom: 145px;
    padding-bottom: 9.0625rem; }
  .pdl-xl-145 {
    padding-left: 9.0625rem; }
  .pdr-xl-145 {
    padding-right: 145px;
    padding-right: 9.0625rem; }
  .mg-xl-150 {
    margin: 150px;
    margin: 9.375rem; }
  -mg.xl-150-0 {
    margin: 150px 0;
    margin: 9.375rem 0; }
  .mg-xl-0-150 {
    margin: 0 150px;
    margin: 0 9.375rem; }
  .mgt-xl-150 {
    margin-top: 150px;
    margin-top: 9.375rem; }
  .mgb-xl-150 {
    margin-bottom: 150px;
    margin-bottom: 9.375rem; }
  .mgl-xl-150 {
    margin-left: 150px;
    margin-left: 9.375rem; }
  .mgr-xl-150 {
    margin-right: 150px;
    margin-right: 9.375rem; }
  .pd-xl-150 {
    padding: 150px;
    padding: 9.375rem; }
  .pd-xl-150-0 {
    padding: 150px 0;
    padding: 9.375rem 0; }
  .pd-xl-0-150 {
    padding: 0 150px;
    padding: 0 9.375rem; }
  .pdt-xl-150 {
    padding-top: 150px;
    padding-top: 9.375rem; }
  .pdb-xl-150 {
    padding-bottom: 150px;
    padding-bottom: 9.375rem; }
  .pdl-xl-150 {
    padding-left: 9.375rem; }
  .pdr-xl-150 {
    padding-right: 150px;
    padding-right: 9.375rem; } }

.text-9 {
  font-size: 9px;
  font-size: 0.5625rem; }

.text-10 {
  font-size: 10px;
  font-size: 0.625rem; }

.text-11 {
  font-size: 11px;
  font-size: 0.6875rem; }

.text-12 {
  font-size: 12px;
  font-size: 0.75rem; }

.text-13 {
  font-size: 13px;
  font-size: 0.8125rem; }

.text-14 {
  font-size: 14px;
  font-size: 0.875rem; }

.text-15 {
  font-size: 15px;
  font-size: 0.9375rem; }

.text-16 {
  font-size: 16px;
  font-size: 1rem; }

.text-17 {
  font-size: 17px;
  font-size: 1.0625rem; }

.text-18 {
  font-size: 18px;
  font-size: 1.125rem; }

.text-19 {
  font-size: 19px;
  font-size: 1.1875rem; }

.text-20 {
  font-size: 20px;
  font-size: 1.25rem; }

.text-21 {
  font-size: 21px;
  font-size: 1.3125rem; }

.text-22 {
  font-size: 22px;
  font-size: 1.375rem; }

.text-23 {
  font-size: 23px;
  font-size: 1.4375rem; }

.text-24 {
  font-size: 24px;
  font-size: 1.5rem; }

.text-25 {
  font-size: 25px;
  font-size: 1.5625rem; }

.text-26 {
  font-size: 26px;
  font-size: 1.625rem; }

.text-27 {
  font-size: 27px;
  font-size: 1.6875rem; }

.text-28 {
  font-size: 28px;
  font-size: 1.75rem; }

.text-29 {
  font-size: 29px;
  font-size: 1.8125rem; }

.text-30 {
  font-size: 30px;
  font-size: 1.875rem; }

.text-31 {
  font-size: 31px;
  font-size: 1.9375rem; }

.text-32 {
  font-size: 32px;
  font-size: 2rem; }

.text-33 {
  font-size: 33px;
  font-size: 2.0625rem; }

.text-34 {
  font-size: 34px;
  font-size: 2.125rem; }

.text-35 {
  font-size: 35px;
  font-size: 2.1875rem; }

.text-36 {
  font-size: 36px;
  font-size: 2.25rem; }

.text-37 {
  font-size: 37px;
  font-size: 2.3125rem; }

.text-38 {
  font-size: 38px;
  font-size: 2.375rem; }

.text-39 {
  font-size: 39px;
  font-size: 2.4375rem; }

.text-40 {
  font-size: 40px;
  font-size: 2.5rem; }

.text-41 {
  font-size: 41px;
  font-size: 2.5625rem; }

.text-42 {
  font-size: 42px;
  font-size: 2.625rem; }

.text-43 {
  font-size: 43px;
  font-size: 2.6875rem; }

.text-44 {
  font-size: 44px;
  font-size: 2.75rem; }

.text-45 {
  font-size: 45px;
  font-size: 2.8125rem; }

.text-46 {
  font-size: 46px;
  font-size: 2.875rem; }

.text-47 {
  font-size: 47px;
  font-size: 2.9375rem; }

.text-48 {
  font-size: 48px;
  font-size: 3rem; }

.text-49 {
  font-size: 49px;
  font-size: 3.0625rem; }

.text-50 {
  font-size: 50px;
  font-size: 3.125rem; }

@media (min-width: 360px) {
  .text-xs-9 {
    font-size: 9px;
    font-size: 0.5625rem; }
  .text-xs-10 {
    font-size: 10px;
    font-size: 0.625rem; }
  .text-xs-11 {
    font-size: 11px;
    font-size: 0.6875rem; }
  .text-xs-12 {
    font-size: 12px;
    font-size: 0.75rem; }
  .text-xs-13 {
    font-size: 13px;
    font-size: 0.8125rem; }
  .text-xs-14 {
    font-size: 14px;
    font-size: 0.875rem; }
  .text-xs-15 {
    font-size: 15px;
    font-size: 0.9375rem; }
  .text-xs-16 {
    font-size: 16px;
    font-size: 1rem; }
  .text-xs-17 {
    font-size: 17px;
    font-size: 1.0625rem; }
  .text-xs-18 {
    font-size: 18px;
    font-size: 1.125rem; }
  .text-xs-19 {
    font-size: 19px;
    font-size: 1.1875rem; }
  .text-xs-20 {
    font-size: 20px;
    font-size: 1.25rem; }
  .text-xs-21 {
    font-size: 21px;
    font-size: 1.3125rem; }
  .text-xs-22 {
    font-size: 22px;
    font-size: 1.375rem; }
  .text-xs-23 {
    font-size: 23px;
    font-size: 1.4375rem; }
  .text-xs-24 {
    font-size: 24px;
    font-size: 1.5rem; }
  .text-xs-25 {
    font-size: 25px;
    font-size: 1.5625rem; }
  .text-xs-26 {
    font-size: 26px;
    font-size: 1.625rem; }
  .text-xs-27 {
    font-size: 27px;
    font-size: 1.6875rem; }
  .text-xs-28 {
    font-size: 28px;
    font-size: 1.75rem; }
  .text-xs-29 {
    font-size: 29px;
    font-size: 1.8125rem; }
  .text-xs-30 {
    font-size: 30px;
    font-size: 1.875rem; }
  .text-xs-31 {
    font-size: 31px;
    font-size: 1.9375rem; }
  .text-xs-32 {
    font-size: 32px;
    font-size: 2rem; }
  .text-xs-33 {
    font-size: 33px;
    font-size: 2.0625rem; }
  .text-xs-34 {
    font-size: 34px;
    font-size: 2.125rem; }
  .text-xs-35 {
    font-size: 35px;
    font-size: 2.1875rem; }
  .text-xs-36 {
    font-size: 36px;
    font-size: 2.25rem; }
  .text-xs-37 {
    font-size: 37px;
    font-size: 2.3125rem; }
  .text-xs-38 {
    font-size: 38px;
    font-size: 2.375rem; }
  .text-xs-39 {
    font-size: 39px;
    font-size: 2.4375rem; }
  .text-xs-40 {
    font-size: 40px;
    font-size: 2.5rem; }
  .text-xs-41 {
    font-size: 41px;
    font-size: 2.5625rem; }
  .text-xs-42 {
    font-size: 42px;
    font-size: 2.625rem; }
  .text-xs-43 {
    font-size: 43px;
    font-size: 2.6875rem; }
  .text-xs-44 {
    font-size: 44px;
    font-size: 2.75rem; }
  .text-xs-45 {
    font-size: 45px;
    font-size: 2.8125rem; }
  .text-xs-46 {
    font-size: 46px;
    font-size: 2.875rem; }
  .text-xs-47 {
    font-size: 47px;
    font-size: 2.9375rem; }
  .text-xs-48 {
    font-size: 48px;
    font-size: 3rem; }
  .text-xs-49 {
    font-size: 49px;
    font-size: 3.0625rem; }
  .text-xs-50 {
    font-size: 50px;
    font-size: 3.125rem; } }

@media (min-width: 480px) {
  .text-sm-9 {
    font-size: 9px;
    font-size: 0.5625rem; }
  .text-sm-10 {
    font-size: 10px;
    font-size: 0.625rem; }
  .text-sm-11 {
    font-size: 11px;
    font-size: 0.6875rem; }
  .text-sm-12 {
    font-size: 12px;
    font-size: 0.75rem; }
  .text-sm-13 {
    font-size: 13px;
    font-size: 0.8125rem; }
  .text-sm-14 {
    font-size: 14px;
    font-size: 0.875rem; }
  .text-sm-15 {
    font-size: 15px;
    font-size: 0.9375rem; }
  .text-sm-16 {
    font-size: 16px;
    font-size: 1rem; }
  .text-sm-17 {
    font-size: 17px;
    font-size: 1.0625rem; }
  .text-sm-18 {
    font-size: 18px;
    font-size: 1.125rem; }
  .text-sm-19 {
    font-size: 19px;
    font-size: 1.1875rem; }
  .text-sm-20 {
    font-size: 20px;
    font-size: 1.25rem; }
  .text-sm-21 {
    font-size: 21px;
    font-size: 1.3125rem; }
  .text-sm-22 {
    font-size: 22px;
    font-size: 1.375rem; }
  .text-sm-23 {
    font-size: 23px;
    font-size: 1.4375rem; }
  .text-sm-24 {
    font-size: 24px;
    font-size: 1.5rem; }
  .text-sm-25 {
    font-size: 25px;
    font-size: 1.5625rem; }
  .text-sm-26 {
    font-size: 26px;
    font-size: 1.625rem; }
  .text-sm-27 {
    font-size: 27px;
    font-size: 1.6875rem; }
  .text-sm-28 {
    font-size: 28px;
    font-size: 1.75rem; }
  .text-sm-29 {
    font-size: 29px;
    font-size: 1.8125rem; }
  .text-sm-30 {
    font-size: 30px;
    font-size: 1.875rem; }
  .text-sm-31 {
    font-size: 31px;
    font-size: 1.9375rem; }
  .text-sm-32 {
    font-size: 32px;
    font-size: 2rem; }
  .text-sm-33 {
    font-size: 33px;
    font-size: 2.0625rem; }
  .text-sm-34 {
    font-size: 34px;
    font-size: 2.125rem; }
  .text-sm-35 {
    font-size: 35px;
    font-size: 2.1875rem; }
  .text-sm-36 {
    font-size: 36px;
    font-size: 2.25rem; }
  .text-sm-37 {
    font-size: 37px;
    font-size: 2.3125rem; }
  .text-sm-38 {
    font-size: 38px;
    font-size: 2.375rem; }
  .text-sm-39 {
    font-size: 39px;
    font-size: 2.4375rem; }
  .text-sm-40 {
    font-size: 40px;
    font-size: 2.5rem; }
  .text-sm-41 {
    font-size: 41px;
    font-size: 2.5625rem; }
  .text-sm-42 {
    font-size: 42px;
    font-size: 2.625rem; }
  .text-sm-43 {
    font-size: 43px;
    font-size: 2.6875rem; }
  .text-sm-44 {
    font-size: 44px;
    font-size: 2.75rem; }
  .text-sm-45 {
    font-size: 45px;
    font-size: 2.8125rem; }
  .text-sm-46 {
    font-size: 46px;
    font-size: 2.875rem; }
  .text-sm-47 {
    font-size: 47px;
    font-size: 2.9375rem; }
  .text-sm-48 {
    font-size: 48px;
    font-size: 3rem; }
  .text-sm-49 {
    font-size: 49px;
    font-size: 3.0625rem; }
  .text-sm-50 {
    font-size: 50px;
    font-size: 3.125rem; } }

@media (min-width: 768px) {
  .text-md-9 {
    font-size: 9px;
    font-size: 0.5625rem; }
  .text-md-10 {
    font-size: 10px;
    font-size: 0.625rem; }
  .text-md-11 {
    font-size: 11px;
    font-size: 0.6875rem; }
  .text-md-12 {
    font-size: 12px;
    font-size: 0.75rem; }
  .text-md-13 {
    font-size: 13px;
    font-size: 0.8125rem; }
  .text-md-14 {
    font-size: 14px;
    font-size: 0.875rem; }
  .text-md-15 {
    font-size: 15px;
    font-size: 0.9375rem; }
  .text-md-16 {
    font-size: 16px;
    font-size: 1rem; }
  .text-md-17 {
    font-size: 17px;
    font-size: 1.0625rem; }
  .text-md-18 {
    font-size: 18px;
    font-size: 1.125rem; }
  .text-md-19 {
    font-size: 19px;
    font-size: 1.1875rem; }
  .text-md-20 {
    font-size: 20px;
    font-size: 1.25rem; }
  .text-md-21 {
    font-size: 21px;
    font-size: 1.3125rem; }
  .text-md-22 {
    font-size: 22px;
    font-size: 1.375rem; }
  .text-md-23 {
    font-size: 23px;
    font-size: 1.4375rem; }
  .text-md-24 {
    font-size: 24px;
    font-size: 1.5rem; }
  .text-md-25 {
    font-size: 25px;
    font-size: 1.5625rem; }
  .text-md-26 {
    font-size: 26px;
    font-size: 1.625rem; }
  .text-md-27 {
    font-size: 27px;
    font-size: 1.6875rem; }
  .text-md-28 {
    font-size: 28px;
    font-size: 1.75rem; }
  .text-md-29 {
    font-size: 29px;
    font-size: 1.8125rem; }
  .text-md-30 {
    font-size: 30px;
    font-size: 1.875rem; }
  .text-md-31 {
    font-size: 31px;
    font-size: 1.9375rem; }
  .text-md-32 {
    font-size: 32px;
    font-size: 2rem; }
  .text-md-33 {
    font-size: 33px;
    font-size: 2.0625rem; }
  .text-md-34 {
    font-size: 34px;
    font-size: 2.125rem; }
  .text-md-35 {
    font-size: 35px;
    font-size: 2.1875rem; }
  .text-md-36 {
    font-size: 36px;
    font-size: 2.25rem; }
  .text-md-37 {
    font-size: 37px;
    font-size: 2.3125rem; }
  .text-md-38 {
    font-size: 38px;
    font-size: 2.375rem; }
  .text-md-39 {
    font-size: 39px;
    font-size: 2.4375rem; }
  .text-md-40 {
    font-size: 40px;
    font-size: 2.5rem; }
  .text-md-41 {
    font-size: 41px;
    font-size: 2.5625rem; }
  .text-md-42 {
    font-size: 42px;
    font-size: 2.625rem; }
  .text-md-43 {
    font-size: 43px;
    font-size: 2.6875rem; }
  .text-md-44 {
    font-size: 44px;
    font-size: 2.75rem; }
  .text-md-45 {
    font-size: 45px;
    font-size: 2.8125rem; }
  .text-md-46 {
    font-size: 46px;
    font-size: 2.875rem; }
  .text-md-47 {
    font-size: 47px;
    font-size: 2.9375rem; }
  .text-md-48 {
    font-size: 48px;
    font-size: 3rem; }
  .text-md-49 {
    font-size: 49px;
    font-size: 3.0625rem; }
  .text-md-50 {
    font-size: 50px;
    font-size: 3.125rem; } }

@media (min-width: 992px) {
  .text-lg-9 {
    font-size: 9px;
    font-size: 0.5625rem; }
  .text-lg-10 {
    font-size: 10px;
    font-size: 0.625rem; }
  .text-lg-11 {
    font-size: 11px;
    font-size: 0.6875rem; }
  .text-lg-12 {
    font-size: 12px;
    font-size: 0.75rem; }
  .text-lg-13 {
    font-size: 13px;
    font-size: 0.8125rem; }
  .text-lg-14 {
    font-size: 14px;
    font-size: 0.875rem; }
  .text-lg-15 {
    font-size: 15px;
    font-size: 0.9375rem; }
  .text-lg-16 {
    font-size: 16px;
    font-size: 1rem; }
  .text-lg-17 {
    font-size: 17px;
    font-size: 1.0625rem; }
  .text-lg-18 {
    font-size: 18px;
    font-size: 1.125rem; }
  .text-lg-19 {
    font-size: 19px;
    font-size: 1.1875rem; }
  .text-lg-20 {
    font-size: 20px;
    font-size: 1.25rem; }
  .text-lg-21 {
    font-size: 21px;
    font-size: 1.3125rem; }
  .text-lg-22 {
    font-size: 22px;
    font-size: 1.375rem; }
  .text-lg-23 {
    font-size: 23px;
    font-size: 1.4375rem; }
  .text-lg-24 {
    font-size: 24px;
    font-size: 1.5rem; }
  .text-lg-25 {
    font-size: 25px;
    font-size: 1.5625rem; }
  .text-lg-26 {
    font-size: 26px;
    font-size: 1.625rem; }
  .text-lg-27 {
    font-size: 27px;
    font-size: 1.6875rem; }
  .text-lg-28 {
    font-size: 28px;
    font-size: 1.75rem; }
  .text-lg-29 {
    font-size: 29px;
    font-size: 1.8125rem; }
  .text-lg-30 {
    font-size: 30px;
    font-size: 1.875rem; }
  .text-lg-31 {
    font-size: 31px;
    font-size: 1.9375rem; }
  .text-lg-32 {
    font-size: 32px;
    font-size: 2rem; }
  .text-lg-33 {
    font-size: 33px;
    font-size: 2.0625rem; }
  .text-lg-34 {
    font-size: 34px;
    font-size: 2.125rem; }
  .text-lg-35 {
    font-size: 35px;
    font-size: 2.1875rem; }
  .text-lg-36 {
    font-size: 36px;
    font-size: 2.25rem; }
  .text-lg-37 {
    font-size: 37px;
    font-size: 2.3125rem; }
  .text-lg-38 {
    font-size: 38px;
    font-size: 2.375rem; }
  .text-lg-39 {
    font-size: 39px;
    font-size: 2.4375rem; }
  .text-lg-40 {
    font-size: 40px;
    font-size: 2.5rem; }
  .text-lg-41 {
    font-size: 41px;
    font-size: 2.5625rem; }
  .text-lg-42 {
    font-size: 42px;
    font-size: 2.625rem; }
  .text-lg-43 {
    font-size: 43px;
    font-size: 2.6875rem; }
  .text-lg-44 {
    font-size: 44px;
    font-size: 2.75rem; }
  .text-lg-45 {
    font-size: 45px;
    font-size: 2.8125rem; }
  .text-lg-46 {
    font-size: 46px;
    font-size: 2.875rem; }
  .text-lg-47 {
    font-size: 47px;
    font-size: 2.9375rem; }
  .text-lg-48 {
    font-size: 48px;
    font-size: 3rem; }
  .text-lg-49 {
    font-size: 49px;
    font-size: 3.0625rem; }
  .text-lg-50 {
    font-size: 50px;
    font-size: 3.125rem; } }

@media (min-width: 1200px) {
  .text-xl-9 {
    font-size: 9px;
    font-size: 0.5625rem; }
  .text-xl-10 {
    font-size: 10px;
    font-size: 0.625rem; }
  .text-xl-11 {
    font-size: 11px;
    font-size: 0.6875rem; }
  .text-xl-12 {
    font-size: 12px;
    font-size: 0.75rem; }
  .text-xl-13 {
    font-size: 13px;
    font-size: 0.8125rem; }
  .text-xl-14 {
    font-size: 14px;
    font-size: 0.875rem; }
  .text-xl-15 {
    font-size: 15px;
    font-size: 0.9375rem; }
  .text-xl-16 {
    font-size: 16px;
    font-size: 1rem; }
  .text-xl-17 {
    font-size: 17px;
    font-size: 1.0625rem; }
  .text-xl-18 {
    font-size: 18px;
    font-size: 1.125rem; }
  .text-xl-19 {
    font-size: 19px;
    font-size: 1.1875rem; }
  .text-xl-20 {
    font-size: 20px;
    font-size: 1.25rem; }
  .text-xl-21 {
    font-size: 21px;
    font-size: 1.3125rem; }
  .text-xl-22 {
    font-size: 22px;
    font-size: 1.375rem; }
  .text-xl-23 {
    font-size: 23px;
    font-size: 1.4375rem; }
  .text-xl-24 {
    font-size: 24px;
    font-size: 1.5rem; }
  .text-xl-25 {
    font-size: 25px;
    font-size: 1.5625rem; }
  .text-xl-26 {
    font-size: 26px;
    font-size: 1.625rem; }
  .text-xl-27 {
    font-size: 27px;
    font-size: 1.6875rem; }
  .text-xl-28 {
    font-size: 28px;
    font-size: 1.75rem; }
  .text-xl-29 {
    font-size: 29px;
    font-size: 1.8125rem; }
  .text-xl-30 {
    font-size: 30px;
    font-size: 1.875rem; }
  .text-xl-31 {
    font-size: 31px;
    font-size: 1.9375rem; }
  .text-xl-32 {
    font-size: 32px;
    font-size: 2rem; }
  .text-xl-33 {
    font-size: 33px;
    font-size: 2.0625rem; }
  .text-xl-34 {
    font-size: 34px;
    font-size: 2.125rem; }
  .text-xl-35 {
    font-size: 35px;
    font-size: 2.1875rem; }
  .text-xl-36 {
    font-size: 36px;
    font-size: 2.25rem; }
  .text-xl-37 {
    font-size: 37px;
    font-size: 2.3125rem; }
  .text-xl-38 {
    font-size: 38px;
    font-size: 2.375rem; }
  .text-xl-39 {
    font-size: 39px;
    font-size: 2.4375rem; }
  .text-xl-40 {
    font-size: 40px;
    font-size: 2.5rem; }
  .text-xl-41 {
    font-size: 41px;
    font-size: 2.5625rem; }
  .text-xl-42 {
    font-size: 42px;
    font-size: 2.625rem; }
  .text-xl-43 {
    font-size: 43px;
    font-size: 2.6875rem; }
  .text-xl-44 {
    font-size: 44px;
    font-size: 2.75rem; }
  .text-xl-45 {
    font-size: 45px;
    font-size: 2.8125rem; }
  .text-xl-46 {
    font-size: 46px;
    font-size: 2.875rem; }
  .text-xl-47 {
    font-size: 47px;
    font-size: 2.9375rem; }
  .text-xl-48 {
    font-size: 48px;
    font-size: 3rem; }
  .text-xl-49 {
    font-size: 49px;
    font-size: 3.0625rem; }
  .text-xl-50 {
    font-size: 50px;
    font-size: 3.125rem; } }

.navbar-custom {
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
  background-color: #7b7f98;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, transparent 50%); }
  @media (max-width: 991.99px) {
    .navbar-custom .navbar-collapse {
      padding: 10px;
      padding: 0.625rem;
      background-color: rgba(255, 255, 255, 0.14); } }
  @media (min-width: 992px) {
    .navbar-custom .nav-link {
      color: white;
      font-weight: bold; }
      .navbar-custom .nav-link.active, .navbar-custom .nav-link:hover {
        color: #e4ff98; } }
  .navbar-custom .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.btn, .pagination li span,
.pagination li a {
  border-radius: 5px;
  border-radius: 0.3125rem;
  padding: 5px 15px 4px;
  padding: 0.3125rem 0.9375rem 0.25rem;
  font-size: 15px;
  font-size: 0.9375rem; }
  .btn.btn-sm, .btn-group-sm > .btn, .pagination li .btn-group-sm > span,
  .pagination li .btn-group-sm > a, .pagination li span.btn-sm,
  .pagination li a.btn-sm {
    padding: 3px 17px 2px;
    padding: 0.1875rem 1.0625rem 0.125rem;
    font-size: 13px;
    font-size: 0.8125rem; }

.btn-info:active, .pagination li span:active,
.pagination li a:active, .btn-info:focus, .pagination li span:focus,
.pagination li a:focus, .btn-info:hover, .pagination li span:hover,
.pagination li a:hover {
  background-color: white !important;
  color: #01A8A2 !important;
  border-color: #01A8A2 !important; }

.badge {
  border-radius: 5px; }

.form-control {
  border-width: 0;
  border-radius: 10px; }

.pagination {
  justify-content: center; }
  .pagination li span,
  .pagination li a {
    margin: 0 5px;
    margin: 0 0.3125rem;
    display: inline-block; }
  .pagination li.active span,
  .pagination li.active a {
    background-color: white !important;
    color: #01A8A2 !important;
    border-color: #01A8A2 !important; }
  .pagination li.disabled span,
  .pagination li.disabled a {
    opacity: .5;
    cursor: default !important; }
    .pagination li.disabled span:hover,
    .pagination li.disabled a:hover {
      background-color: #01A8A2 !important;
      color: white !important; }

.card-sidebar-home .card-sidebar-box {
  background-color: #99B745;
  border-radius: 10px 10px 0 0;
  padding: 70px 20px 20px 20px;
  padding: 4.375rem 1.25rem 1.25rem 1.25rem; }

.card-sidebar-home .card-sidebar-header {
  text-align: center;
  margin-bottom: -60px;
  margin-bottom: -3.75rem;
  color: white; }
  .card-sidebar-home .card-sidebar-header img {
    width: 260px; }
  .card-sidebar-home .card-sidebar-header strong {
    display: block;
    text-transform: uppercase;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 0.2rem;
    margin-top: 10px;
    margin-top: 0.625rem; }

.card-sidebar-home .card-sidebar-content {
  position: relative; }
  .card-sidebar-home .card-sidebar-content .badge:not(.badge-danger) {
    margin-top: 7px;
    margin-top: 0.4375rem; }
  .card-sidebar-home .card-sidebar-content .badge.badge-danger {
    position: absolute;
    top: -2px;
    right: -10px;
    transform: rotate(10deg); }
  .card-sidebar-home .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-calendar {
    margin-bottom: 0;
    font-size: 12px;
    font-size: 0.75rem;
    display: flex;
    align-content: center;
    flex-wrap: wrap; }
    .card-sidebar-home .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-calendar i {
      margin-top: 1px;
      margin-top: 0.0625rem;
      margin-right: 5px;
      margin-right: 0.3125rem; }
  .card-sidebar-home .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-line {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 15px;
    font-size: 0.9375rem; }
    .card-sidebar-home .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-line a:hover {
      color: #000;
      text-decoration: none; }
  .card-sidebar-home .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-subline-1 {
    margin-bottom: 0;
    font-size: 14px;
    font-size: 0.875rem; }
  .card-sidebar-home .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-subline-2 {
    margin-bottom: 0;
    font-size: 13px;
    font-size: 0.8125rem;
    font-style: italic; }
  .card-sidebar-home .card-sidebar-content .card-sidebar-text-box a {
    text-decoration: underline; }
  .card-sidebar-home .card-sidebar-content .card-sidebar-btn {
    margin-top: 7px;
    margin-top: 0.4375rem; }
    .card-sidebar-home .card-sidebar-content .card-sidebar-btn a {
      color: white;
      font-size: 15px;
      font-size: 0.9375rem;
      display: flex;
      align-content: center;
      flex-wrap: wrap; }
      .card-sidebar-home .card-sidebar-content .card-sidebar-btn a:active, .card-sidebar-home .card-sidebar-content .card-sidebar-btn a:hover {
        text-decoration: none; }
      .card-sidebar-home .card-sidebar-content .card-sidebar-btn a i {
        font-size: 20px;
        font-size: 1.25rem;
        margin-right: 5px;
        margin-right: 0.3125rem; }

.card-sidebar-home .card-sidebar-footer {
  padding: 0;
  border-top-width: 0; }
  .card-sidebar-home .card-sidebar-footer .btn, .card-sidebar-home .card-sidebar-footer .pagination li span, .pagination li .card-sidebar-home .card-sidebar-footer span,
  .card-sidebar-home .card-sidebar-footer .pagination li a, .pagination li .card-sidebar-home .card-sidebar-footer a {
    border-radius: 0 0 10px 10px;
    border-radius: 0 0 0.625rem 0.625rem; }

.card-sidebar-home hr {
  border-color: white; }

.card-sidebar-primary {
  margin-top: 30px;
  margin-top: 1.875rem; }
  .card-sidebar-primary .card-sidebar-box {
    background-color: #99B745;
    border-radius: 10px 10px 0 0;
    padding: 50px 20px 20px 20px;
    padding: 3.125rem 1.25rem 1.25rem 1.25rem; }
  .card-sidebar-primary .card-sidebar-header {
    margin-bottom: -37px;
    margin-bottom: -2.3125rem;
    text-align: center;
    color: white; }
    .card-sidebar-primary .card-sidebar-header strong {
      display: block;
      text-transform: uppercase;
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: 700;
      letter-spacing: 0.2rem;
      margin-top: 10px;
      margin-top: 0.625rem; }
  .card-sidebar-primary .card-sidebar-content {
    position: relative; }
    .card-sidebar-primary .card-sidebar-content .badge:not(.badge-danger) {
      margin-top: 7px;
      margin-top: 0.4375rem; }
    .card-sidebar-primary .card-sidebar-content .badge.badge-danger {
      position: absolute;
      top: -2px;
      right: -10px;
      transform: rotate(10deg); }
    .card-sidebar-primary .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-calendar {
      margin-bottom: 0;
      font-size: 12px;
      font-size: 0.75rem;
      display: flex;
      align-content: center;
      flex-wrap: wrap; }
      .card-sidebar-primary .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-calendar i {
        margin-top: 1px;
        margin-top: 0.0625rem;
        margin-right: 5px;
        margin-right: 0.3125rem; }
    .card-sidebar-primary .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-line {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 15px;
      font-size: 0.9375rem;
      text-decoration: underline; }
      .card-sidebar-primary .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-line:hover {
        color: #000;
        text-decoration: none; }
    .card-sidebar-primary .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-subline-1 {
      margin-bottom: 0;
      font-size: 14px;
      font-size: 0.875rem; }
    .card-sidebar-primary .card-sidebar-content .card-sidebar-text-box .card-sidebar-text-subline-2 {
      margin-bottom: 0;
      font-size: 13px;
      font-size: 0.8125rem;
      font-style: italic; }
    .card-sidebar-primary .card-sidebar-content .card-sidebar-btn {
      margin-top: 7px;
      margin-top: 0.4375rem; }
      .card-sidebar-primary .card-sidebar-content .card-sidebar-btn a {
        color: white;
        font-size: 15px;
        font-size: 0.9375rem;
        display: flex;
        align-content: center;
        flex-wrap: wrap; }
        .card-sidebar-primary .card-sidebar-content .card-sidebar-btn a:active, .card-sidebar-primary .card-sidebar-content .card-sidebar-btn a:hover {
          text-decoration: none; }
        .card-sidebar-primary .card-sidebar-content .card-sidebar-btn a i {
          margin-top: 1px;
          margin-top: 0.0625rem;
          margin-right: 5px;
          margin-right: 0.3125rem; }
  .card-sidebar-primary .card-sidebar-footer {
    padding: 0;
    border-top-width: 0; }
    .card-sidebar-primary .card-sidebar-footer .btn, .card-sidebar-primary .card-sidebar-footer .pagination li span, .pagination li .card-sidebar-primary .card-sidebar-footer span,
    .card-sidebar-primary .card-sidebar-footer .pagination li a, .pagination li .card-sidebar-primary .card-sidebar-footer a {
      border-radius: 0 0 10px 10px;
      border-radius: 0 0 0.625rem 0.625rem; }
  .card-sidebar-primary hr {
    border-color: white; }
  .card-sidebar-primary.has-image {
    margin-top: 0; }
    .card-sidebar-primary.has-image .card-sidebar-header {
      margin-bottom: -66px;
      margin-bottom: -4.125rem; }
    .card-sidebar-primary.has-image .card-sidebar-box {
      padding-top: 75px;
      padding-top: 4.6875rem; }
