/* grid layout, 3 columns */
 .wrapper {
     display: grid;
	 /* 1 fraction */
     grid-template-columns: 1fr 1fr 1fr;
 }

.box {
	background-color: $gray-600;
	border: 1px solid black;
}