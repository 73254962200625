.img-sidebar-header {
	position: relative;
	@include rem(margin-bottom, -9px);
	z-index: 2;
}

.table-tours {
	td {
		//border-top: 1px solid $gray-500;
		//border-bottom: 1px solid $gray-500;
		//&:first-child {
		//	border-left: 1px solid transparent;
		//}
		//&:last-child {
		//	border-right: 1px solid transparent;
		//}
	}
}

.section-content-white {
	img {
		@extend .img-fluid;
	}
}

// Summernote Editor
.note-float-left {
	@include rem(margin-right, 20px);
	@include rem(margin-bottom, 20px);
	height: auto !important;
	@extend .img-thumbnail;
}

.note-float-right {
	@include rem(margin-left, 20px);
	@include rem(margin-bottom, 20px);
	height: auto !important;
	@extend .img-thumbnail;
}

.content-distance {
	@include rem(margin-bottom, 70px);
}