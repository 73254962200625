.text-normal {
	font-weight: 400;
}

.text-semibold {
	font-weight: 600;
}

.text-bold {
	font-weight: 700;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-justify {
	text-align: justify;
}

.text-nowrap {
	white-space: nowrap;
}

.text-lowercase {
	text-transform: lowercase;
}

.text-uppercase {
	text-transform: uppercase;
}

// ===============================================================
// Schleifen
// ===============================================================
// Schleife zum erstellen von Margin und Padding
$loop-max: 150;
$loop-step: 5;
.mg-0 {
	margin: 0;
}

.mgt-0 {
	margin-top: 0;
}

.mgb-0 {
	margin-bottom: 0;
}

.mgl-0 {
	margin-left: 0;
}

.mgr-0 {
	margin-right: 0;
}

.pd-0 {
	padding: 0;
}

.pdt-0 {
	padding-top: 0;
}

.pdb-0 {
	padding-bottom: 0;
}

.pdl-0 {
	padding-left: 0;
}

.pdr-0 {
	padding-right: 0;
}

@for $i from 1 through ceil($loop-max/$loop-step) {
	$value: $i * $loop-step;
	.mg-#{$value} {
		$var-rem: $value/16;
		margin: $value+px;
		margin: $var-rem+rem;
	}

	.mg-#{$value}-0 {
		$var-rem: $value/16;
		margin: $value+px 0;
		margin: $var-rem+rem 0;
	}

	.mg-0-#{$value} {
		$var-rem: $value/16;
		margin: 0 $value+px;
		margin: 0 $var-rem+rem;
	}

	.mgt-#{$value} {
		$var-rem: $value/16;
		margin-top: $value+px;
		margin-top: $var-rem+rem;
	}

	.mgb-#{$value} {
		$var-rem: $value/16;
		margin-bottom: $value+px;
		margin-bottom: $var-rem+rem;
	}

	.mgl-#{$value} {
		$var-rem: $value/16;
		margin-left: $value+px;
		margin-left: $var-rem+rem;
	}

	.mgr-#{$value} {
		$var-rem: $value/16;
		margin-right: $value+px;
		margin-right: $var-rem+rem;
	}

	.pd-#{$value} {
		$var-rem: $value/16;
		padding: $value+px;
		padding: $var-rem+rem;
	}

	.pd-#{$value}-0 {
		$var-rem: $value/16;
		padding: $value+px 0;
		padding: $var-rem+rem 0;
	}

	.pd-0-#{$value} {
		$var-rem: $value/16;
		padding: 0 $value+px;
		padding: 0 $var-rem+rem;
	}

	.pdt-#{$value} {
		$var-rem: $value/16;
		padding-top: $value+px;
		padding-top: $var-rem+rem;
	}

	.pdb-#{$value} {
		$var-rem: $value/16;
		padding-bottom: $value+px;
		padding-bottom: $var-rem+rem;
	}

	.pdl-#{$value} {
		$var-rem: $value/16;
		padding-left: $value+px;
		padding-left: $var-rem+rem;
	}

	.pdr-#{$value} {
		$var-rem: $value/16;
		padding-right: $value+px;
		padding-right: $var-rem+rem;
	}
}

// ms | >= 480px | Medium small device
//@include breakpoint($breakpoint-ms-min, "min") {
@include media-breakpoint-up(sm) {
	.text-sm-normal {
		font-weight: 400;
	}

	.text-sm-semibold {
		font-weight: 600;
	}

	.text-sm-bold {
		font-weight: 700;
	}

	.text-sm-left {
		text-align: left;
	}

	.text-sm-right {
		text-align: right;
	}

	.text-sm-center {
		text-align: center;
	}

	.text-sm-justify {
		text-align: justify;
	}

	.text-sm-nowrap {
		white-space: nowrap;
	}

	.text-sm-lowercase {
		text-transform: lowercase;
	}

	.text-sm-uppercase {
		text-transform: uppercase;
	}

	.mg-sm-0 {
		margin: 0;
	}

	.mgt-sm-0 {
		margin-top: 0;
	}

	.mgb-sm-0 {
		margin-bottom: 0;
	}

	.mgl-sm-0 {
		margin-left: 0;
	}

	.mgr-sm-0 {
		margin-right: 0;
	}

	.pd-sm-0 {
		padding: 0;
	}

	.pdt-sm-0 {
		padding-top: 0;
	}

	.pdb-sm-0 {
		padding-bottom: 0;
	}

	.pdl-sm-0 {
		padding-left: 0;
	}

	.pdr-sm-0 {
		padding-right: 0;
	}
	@for $i from 1 through ceil($loop-max/$loop-step) {
		$value: $i * $loop-step;
		.mg-sm-#{$value} {
			$var-rem: $value/16;
			margin: $value+px;
			margin: $var-rem+rem;
		}

		.mg-sm-#{$value}-0 {
			$var-rem: $value/16;
			margin: $value+px 0;
			margin: $var-rem+rem 0;
		}

		.mg-sm-0-#{$value} {
			$var-rem: $value/16;
			margin: 0 $value+px;
			margin: 0 $var-rem+rem;
		}

		.mgt-sm-#{$value} {
			$var-rem: $value/16;
			margin-top: $value+px;
			margin-top: $var-rem+rem;
		}

		.mgb-sm-#{$value} {
			$var-rem: $value/16;
			margin-bottom: $value+px;
			margin-bottom: $var-rem+rem;
		}

		.mgl-sm-#{$value} {
			$var-rem: $value/16;
			margin-left: $value+px;
			margin-left: $var-rem+rem;
		}

		.mgr-sm-#{$value} {
			$var-rem: $value/16;
			margin-right: $value+px;
			margin-right: $var-rem+rem;
		}

		.pd-sm-#{$value} {
			$var-rem: $value/16;
			padding: $value+px;
			padding: $var-rem+rem;
		}

		.pd-sm-#{$value}-0 {
			$var-rem: $value/16;
			padding: $value+px 0;
			padding: $var-rem+rem 0;
		}

		.pd-sm-0-#{$value} {
			$var-rem: $value/16;
			padding: 0 $value+px;
			padding: 0 $var-rem+rem;
		}

		.pdt-sm-#{$value} {
			$var-rem: $value/16;
			padding-top: $value+px;
			padding-top: $var-rem+rem;
		}

		.pdb-sm-#{$value} {
			$var-rem: $value/16;
			padding-bottom: $value+px;
			padding-bottom: $var-rem+rem;
		}

		.pdl-sm-#{$value} {
			$var-rem: $value/16;
			padding-left: $value+px;
			padding-left: $var-rem+rem;
		}

		.pdr-sm-#{$value} {
			$var-rem: $value/16;
			padding-right: $value+px;
			padding-right: $var-rem+rem;
		}
	}
}

// sm | >= 768px | Small devices
//@include breakpoint($breakpoint-sm-min, "min") {
@include media-breakpoint-up(md) {
	.text-md-normal {
		font-weight: 400;
	}

	.text-md-semibold {
		font-weight: 600;
	}

	.text-md-bold {
		font-weight: 700;
	}

	.text-md-right {
		text-align: right;
	}

	.text-md-center {
		text-align: center;
	}

	.text-md-justify {
		text-align: justify;
	}

	.text-md-nowrap {
		white-space: nowrap;
	}

	.text-md-lowercase {
		text-transform: lowercase;
	}

	.text-md-uppercase {
		text-transform: uppercase;
	}

	.mg-md-0 {
		margin: 0;
	}

	.mgt-md-0 {
		margin-top: 0;
	}

	.mgb-md-0 {
		margin-bottom: 0;
	}

	.mgl-md-0 {
		margin-left: 0;
	}

	.mgr-md-0 {
		margin-right: 0;
	}

	.pd-0md- {
		padding: 0;
	}

	.pdt-md-0 {
		padding-top: 0;
	}

	.pdb-md-0 {
		padding-bottom: 0;
	}

	.pdl-md-0 {
		padding-left: 0;
	}

	.pdr-md-0 {
		padding-right: 0;
	}
	@for $i from 1 through ceil($loop-max/$loop-step) {
		$value: $i * $loop-step;
		.mg-md-#{$value} {
			$var-rem: $value/16;
			margin: $value+px;
			margin: $var-rem+rem;
		}

		.mg-md-#{$value}-0 {
			$var-rem: $value/16;
			margin: $value+px 0;
			margin: $var-rem+rem 0;
		}

		.mg-md-0-#{$value} {
			$var-rem: $value/16;
			margin: 0 $value+px;
			margin: 0 $var-rem+rem;
		}

		.mgt-md-#{$value} {
			$var-rem: $value/16;
			margin-top: $value+px;
			margin-top: $var-rem+rem;
		}

		.mgb-md-#{$value} {
			$var-rem: $value/16;
			margin-bottom: $value+px;
			margin-bottom: $var-rem+rem;
		}

		.mgl-md-#{$value} {
			$var-rem: $value/16;
			margin-left: $value+px;
			margin-left: $var-rem+rem;
		}

		.mgr-md-#{$value} {
			$var-rem: $value/16;
			margin-right: $value+px;
			margin-right: $var-rem+rem;
		}

		.pd-md-#{$value} {
			$var-rem: $value/16;
			padding: $value+px;
			padding: $var-rem+rem;
		}

		.pd-md-#{$value}-0 {
			$var-rem: $value/16;
			padding: $value+px 0;
			padding: $var-rem+rem 0;
		}

		.pd-md-0-#{$value} {
			$var-rem: $value/16;
			padding: 0 $value+px;
			padding: 0 $var-rem+rem;
		}

		.md-pdt-#{$value} {
			$var-rem: $value/16;
			padding-top: $value+px;
			padding-top: $var-rem+rem;
		}

		.pdb-md-#{$value} {
			$var-rem: $value/16;
			padding-bottom: $value+px;
			padding-bottom: $var-rem+rem;
		}

		.pdl-md-#{$value} {
			$var-rem: $value/16;
			padding-left: $value+px;
			padding-left: $var-rem+rem;
		}

		.pdr-md-#{$value} {
			$var-rem: $value/16;
			padding-right: $value+px;
			padding-right: $var-rem+rem;
		}
	}
}

// md | >= 992px | Medium devices
//@include breakpoint($breakpoint-md-min, "min") {
@include media-breakpoint-up(lg) {
	.text-lg-normal {
		font-weight: 400;
	}

	.text-lg-semibold {
		font-weight: 600;
	}

	.text-lg-bold {
		font-weight: 700;
	}

	.text-lg-right {
		text-align: right;
	}

	.text-lg-center {
		text-align: center;
	}

	.text-lg-justify {
		text-align: justify;
	}

	.text-lg-nowrap {
		white-space: nowrap;
	}

	.text-lg-lowercase {
		text-transform: lowercase;
	}

	.text-lg-uppercase {
		text-transform: uppercase;
	}

	.mg-lg-0 {
		margin: 0;
	}

	.mgt-lg-0 {
		margin-top: 0;
	}

	.mgb-lg-0 {
		margin-bottom: 0;
	}

	.mgl-lg-0 {
		margin-left: 0;
	}

	.mgr-lg-0 {
		margin-right: 0;
	}

	.pd-lg-0 {
		padding: 0;
	}

	.pdt-lg-0 {
		padding-top: 0;
	}

	.pdb-lg-0 {
		padding-bottom: 0;
	}

	.pdl-lg-0 {
		padding-left: 0;
	}

	.pdr-lg-0 {
		padding-right: 0;
	}
	@for $i from 1 through ceil($loop-max/$loop-step) {
		$value: $i * $loop-step;
		.mg-lg-#{$value} {
			$var-rem: $value/16;
			margin: $value+px;
			margin: $var-rem+rem;
		}

		.mg-lg-#{$value}-0 {
			$var-rem: $value/16;
			margin: $value+px 0;
			margin: $var-rem+rem 0;
		}

		.mg-lg-0-#{$value} {
			$var-rem: $value/16;
			margin: 0 $value+px;
			margin: 0 $var-rem+rem;
		}

		.mgt-lg-#{$value} {
			$var-rem: $value/16;
			margin-top: $value+px;
			margin-top: $var-rem+rem;
		}

		.mgb-lg-#{$value} {
			$var-rem: $value/16;
			margin-bottom: $value+px;
			margin-bottom: $var-rem+rem;
		}

		.mgl-lg-#{$value} {
			$var-rem: $value/16;
			margin-left: $value+px;
			margin-left: $value+px;
		}

		.mgr-lg-#{$value} {
			$var-rem: $value/16;
			margin-right: $value+px;
			margin-right: $var-rem+rem;
		}

		.pd-lg-#{$value} {
			$var-rem: $value/16;
			padding: $value+px;
			padding: $var-rem+rem;
		}

		.pd-lg-#{$value}-0 {
			$var-rem: $value/16;
			padding: $value+px 0;
			padding: $var-rem+rem 0;
		}

		.pd-lg-0-#{$value} {
			$var-rem: $value/16;
			padding: 0 $value+px;
			padding: 0 $var-rem+rem;
		}

		.pdt-lg-#{$value} {
			$var-rem: $value/16;
			padding-top: $value+px;
			padding-top: $var-rem+rem;
		}

		.pdb-lg-#{$value} {
			$var-rem: $value/16;
			padding-bottom: $value+px;
			padding-bottom: $var-rem+rem;
		}

		.pdl-lg-#{$value} {
			$var-rem: $value/16;
			padding-left: $value+px;
			padding-left: $var-rem+rem;
		}

		.pdr-lg-#{$value} {
			$var-rem: $value/16;
			padding-right: $value+px;
			padding-right: $var-rem+rem;
		}
	}
}

// lg | >= 1200px | Large devices
//@include breakpoint($breakpoint-lg-min, "min") {
@include media-breakpoint-up(xl) {
	.text-xl-normal {
		font-weight: 400;
	}

	.text-xl-semibold {
		font-weight: 600;
	}

	.text-xl-bold {
		font-weight: 700;
	}

	.text-xl-right {
		text-align: right;
	}

	.text-xl-center {
		text-align: center;
	}

	.text-xl-justify {
		text-align: justify;
	}

	.text-xl-nowrap {
		white-space: nowrap;
	}

	.text-xl-lowercase {
		text-transform: lowercase;
	}

	.text-xl-uppercase {
		text-transform: uppercase;
	}

	.mg-xl-0 {
		margin: 0;
	}

	.mgt-xl-0 {
		margin-top: 0;
	}

	.mgb-xl-0 {
		margin-bottom: 0;
	}

	.mgl-xl-0 {
		margin-left: 0;
	}

	.mgr-xl-0 {
		margin-right: 0;
	}

	.pd-xl-0 {
		padding: 0;
	}

	.pdt-xl-0 {
		padding-top: 0;
	}

	.pdb-xl-0 {
		padding-bottom: 0;
	}

	.pdl-xl-0 {
		padding-left: 0;
	}

	.pdr-xl-0 {
		padding-right: 0;
	}
	@for $i from 1 through ceil($loop-max/$loop-step) {
		$value: $i * $loop-step;
		.mg-xl-#{$value} {
			$var-rem: $value/16;
			margin: $value+px;
			margin: $var-rem+rem;
		}

		-mg.xl-#{$value}-0 {
			$var-rem: $value/16;
			margin: $value+px 0;
			margin: $var-rem+rem 0;
		}

		.mg-xl-0-#{$value} {
			$var-rem: $value/16;
			margin: 0 $value+px;
			margin: 0 $var-rem+rem;
		}

		.mgt-xl-#{$value} {
			$var-rem: $value/16;
			margin-top: $value+px;
			margin-top: $var-rem+rem;
		}

		.mgb-xl-#{$value} {
			$var-rem: $value/16;
			margin-bottom: $value+px;
			margin-bottom: $var-rem+rem;
		}

		.mgl-xl-#{$value} {
			$var-rem: $value/16;
			margin-left: $value+px;
			margin-left: $var-rem+rem;
		}

		.mgr-xl-#{$value} {
			$var-rem: $value/16;
			margin-right: $value+px;
			margin-right: $var-rem+rem;
		}

		.pd-xl-#{$value} {
			$var-rem: $value/16;
			padding: $value+px;
			padding: $var-rem+rem;
		}

		.pd-xl-#{$value}-0 {
			$var-rem: $value/16;
			padding: $value+px 0;
			padding: $var-rem+rem 0;
		}

		.pd-xl-0-#{$value} {
			$var-rem: $value/16;
			padding: 0 $value+px;
			padding: 0 $var-rem+rem;
		}

		.pdt-xl-#{$value} {
			$var-rem: $value/16;
			padding-top: $value+px;
			padding-top: $var-rem+rem;
		}

		.pdb-xl-#{$value} {
			$var-rem: $value/16;
			padding-bottom: $value+px;
			padding-bottom: $var-rem+rem;
		}

		.pdl-xl-#{$value} {
			$var-rem: $value/16;
			padding-left: $var-rem+rem;
		}

		.pdr-xl-#{$value} {
			$var-rem: $value/16;
			padding-right: $value+px;
			padding-right: $var-rem+rem;
		}
	}
}

// Schleife fuer Font-Size
$loop-max: 50;
$loop-step: 1;
@for $i from 1 through ceil($loop-max/$loop-step) {
	$value: $i * $loop-step;
	@if $value > 8 {
		.text-#{$value} {
			$var-rem: $value/16;
			font-size: $value+px;
			font-size: $var-rem+rem;
		}
	}
}

@include media-breakpoint-up(xs) {
	@for $i from 1 through ceil($loop-max/$loop-step) {
		$value: $i * $loop-step;
		@if $value > 8 {
			.text-xs-#{$value} {
				$var-rem: $value/16;
				font-size: $value+px;
				font-size: $var-rem+rem;
			}
		}
	}
}

// ms | >= 480px | Medium small device
//@include breakpoint($breakpoint-ms-min, "min") {
@include media-breakpoint-up(sm) {
	@for $i from 1 through ceil($loop-max/$loop-step) {
		$value: $i * $loop-step;
		@if $value > 8 {
			.text-sm-#{$value} {
				$var-rem: $value/16;
				font-size: $value+px;
				font-size: $var-rem+rem;
			}
		}
	}
}

// sm | >= 768px | Small devices
//@include breakpoint($breakpoint-sm-min, "min") {
@include media-breakpoint-up(md) {
	@for $i from 1 through ceil($loop-max/$loop-step) {
		$value: $i * $loop-step;
		@if $value > 8 {
			.text-md-#{$value} {
				$var-rem: $value/16;
				font-size: $value+px;
				font-size: $var-rem+rem;
			}
		}
	}
}

// md | >= 992px | Medium devices
//@include breakpoint($breakpoint-md-min, "min") {
@include media-breakpoint-up(lg) {
	@for $i from 1 through ceil($loop-max/$loop-step) {
		$value: $i * $loop-step;
		@if $value > 8 {
			.text-lg-#{$value} {
				$var-rem: $value/16;
				font-size: $value+px;
				font-size: $var-rem+rem;
			}
		}
	}
}

// lg | >= 1200px | Large devices
//@include breakpoint($breakpoint-lg-min, "min") {
@include media-breakpoint-up(xl) {
	@for $i from 1 through ceil($loop-max/$loop-step) {
		$value: $i * $loop-step;
		@if $value > 8 {
			.text-xl-#{$value} {
				$var-rem: $value/16;
				font-size: $value+px;
				font-size: $var-rem+rem;
			}
		}
	}
}