.card-sidebar-home {

	.card-sidebar-box {
		background-color: #99B745;
		border-radius: 10px 10px 0 0;
		@include rem(padding, 70px 20px 20px 20px);
	}

	.card-sidebar-header {
		text-align: center;
		@include rem(margin-bottom, -60px);
		color: white;

		img {
			width: 260px;
		}

		strong {
			display: block;
			text-transform: uppercase;
			@include rem(font-size, 18px);
			font-weight: 700;
			letter-spacing: 0.2rem;
			@include rem(margin-top, 10px);
		}
	}

	.card-sidebar-body {
	}

	.card-sidebar-content {
		position: relative;

		.badge:not(.badge-danger) {
			@include rem(margin-top, 7px);
		}

		.badge.badge-danger {
			position: absolute;
			top: -2px;
			right: -10px;
			transform: rotate(10deg);
		}

		.card-sidebar-text-box {

			.card-sidebar-text-calendar {
				margin-bottom: 0;
				@include rem(font-size, 12px);

				display: flex;
				align-content: center;
				flex-wrap: wrap;

				i {
					@include rem(margin-top, 1px);
					@include rem(margin-right, 5px);
				}
			}

			.card-sidebar-text-line {
				margin-bottom: 0;
				font-weight: bold;
				@include rem(font-size, 15px);

				a {

					&:hover {
						color: $body-color;
						text-decoration: none;
					}
				}
			}

			.card-sidebar-text-subline-1 {
				margin-bottom: 0;
				@include rem(font-size, 14px);
			}

			.card-sidebar-text-subline-2 {
				margin-bottom: 0;
				@include rem(font-size, 13px);
				font-style: italic;
			}

			a {
				text-decoration: underline;
			}
		}

		.card-sidebar-btn {
			@include rem(margin-top, 7px);

			a {
				color: white;
				@include rem(font-size, 15px);
				display: flex;
				align-content: center;
				flex-wrap: wrap;

				&:active,
				&:hover {
					text-decoration: none;
				}

				i {
					@include rem(font-size, 20px);
					@include rem(margin-right, 5px);
				}
			}
		}
	}

	.card-sidebar-footer {
		padding: 0;
		border-top-width: 0;

		.btn {
			@include rem(border-radius, 0 0 10px 10px);
		}
	}

	hr {
		border-color: white;
	}
}

.card-sidebar-primary {
	@include rem(margin-top, 30px);

	.card-sidebar-box {
		background-color: #99B745;
		border-radius: 10px 10px 0 0;
		@include rem(padding, 50px 20px 20px 20px);
	}

	.card-sidebar-header {
		@include rem(margin-bottom, -37px);
		text-align: center;
		color: white;

		img {
			//width: 260px;
		}

		strong {
			display: block;
			text-transform: uppercase;
			@include rem(font-size, 18px);
			font-weight: 700;
			letter-spacing: 0.2rem;
			@include rem(margin-top, 10px);
		}
	}

	.card-sidebar-body {
	}

	.card-sidebar-content {
		position: relative;

		.badge:not(.badge-danger) {
			@include rem(margin-top, 7px);
		}

		.badge.badge-danger {
			position: absolute;
			top: -2px;
			right: -10px;
			transform: rotate(10deg);
		}

		.card-sidebar-text-box {

			.card-sidebar-text-calendar {
				margin-bottom: 0;
				@include rem(font-size, 12px);

				display: flex;
				align-content: center;
				flex-wrap: wrap;

				i {
					@include rem(margin-top, 1px);
					@include rem(margin-right, 5px);
				}
			}

			.card-sidebar-text-line {
				margin-bottom: 0;
				font-weight: bold;
				@include rem(font-size, 15px);
				text-decoration: underline;

				&:hover {
					color: $body-color;
					text-decoration: none;
				}
			}

			.card-sidebar-text-subline-1 {
				margin-bottom: 0;
				@include rem(font-size, 14px);
			}

			.card-sidebar-text-subline-2 {
				margin-bottom: 0;
				@include rem(font-size, 13px);
				font-style: italic;
			}

			//a {
			//	text-decoration: underline;
			//}
		}

		.card-sidebar-btn {
			@include rem(margin-top, 7px);

			a {
				color: white;
				@include rem(font-size, 15px);
				display: flex;
				align-content: center;
				flex-wrap: wrap;

				&:active,
				&:hover {
					text-decoration: none;
				}

				i {
					//@include rem(font-size, 20px);
					@include rem(margin-top, 1px);
					@include rem(margin-right, 5px);
				}
			}
		}
	}

	.card-sidebar-footer {
		padding: 0;
		border-top-width: 0;

		.btn {
			@include rem(border-radius, 0 0 10px 10px);
		}
	}

	hr {
		border-color: white;
	}

	&.has-image {
		margin-top: 0;

		.card-sidebar-header {
			@include rem(margin-bottom, -66px);
		}
		
		.card-sidebar-box {
			@include rem(padding-top, 75px);
		}
	}
}