.section-content-black {
	@include rem(padding, 30px 0);
	min-height: 400px;
	background-color: $black;
}

.section-content-white {
	min-height: 400px;
	@include rem(border-radius, 10px);

	.content-inner {
		position: relative;
		@include rem(padding, 20px);
		background-color: white;

		.content-headline {
			@include rem(font-size, 24px);
		}
		
		.content-calendar {
			@include rem(padding-bottom, 20px);

			i {
				@include rem(margin-right, 5px);
			}

			span {
				@include rem(font-size, 14px);
				font-weight: 300;
			}
		}
		
		.content-teaser {
			@include rem(font-size, 18px);
		}
	}

	.content-inner-expanded {
		@include rem(padding, 70px 20px 20px 20px);
		background-color: white;
		border-radius: 0 0 10px 10px;
	}

	&.has-image {

		a {
			color: white;
			text-decoration: underline;
			&:hover {
				color: white;

			}
		}

		.news-image-box {
			position: relative;

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100px;
				background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
			}

			img {
				@include rem(border-radius, 10px 10px 0 0);
			}
		}

		.content-inner {
			@include rem(margin-top, -30px);
			background-color: #000;
			color: white;
			@include rem(padding-bottom, 10px);

			&:after {
				content: "";
				position: absolute;
				bottom: -50px;
				left: 0;
				width: 100%;
				height: 50px;
				background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
			}
		}
	}
}