h1
.h1 {
	@include rem(font-size, 26px);
	@include rem(margin-bottom, 10px);
	font-weight: 600;
}

h2,
.h2 {
	@include rem(font-size, 22px);
	@include rem(margin-bottom, 10px);
}

h3,
.h3 {
	@include rem(font-size, 18px);
	@include rem(margin-bottom, 10px);
}

hr {
	border-color: $gray-500;
}

a {
	color: $black;
	&:hover {
		text-decoration: underline;
	}

	&.text-decoration-none {
		text-decoration: none !important;
	}
}

.section-content-white {
	a {
		text-decoration: underline;
	}

	.content-landingpage {
		a {
			color: $blue;
		}
	}
}