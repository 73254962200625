.btn {
	@include rem(border-radius, 5px);
	@include rem(padding, 5px 15px 4px);
	@include rem(font-size, 15px);

	&.btn-sm {
		@include rem(padding, 3px 17px 2px);
		@include rem(font-size, 13px);
	}
}

.btn-info {

	&:active,
	&:focus,
	&:hover {
		background-color: white !important;
		color: theme-color('info') !important;
		border-color: theme-color('info') !important;
	}
}