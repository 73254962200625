.pagination {
	justify-content: center;

	li {
		span,
		a {
			@include rem(margin, 0 5px);
			display: inline-block;
			@extend .btn;
			@extend .btn-info;
		}

		&.active {
			span,
			a {
				background-color: white !important;
				color: theme-color('info') !important;
				border-color: theme-color('info') !important;
			}
		}

		&.disabled {
			span,
			a {
				opacity: .5;
				cursor: default !important;

				&:hover {
					background-color: theme-color('info') !important;
					color: white !important;
					//background-color: red !important;
				}
			}
		}
	}
}