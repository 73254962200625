.navbar-dark {
	padding: 0;
	//border-bottom: 1px solid $gray-800;
	background-color: $black;

	.navbar-brand {
		padding: 0;
		img {
			@include rem(padding, 2px 0);
			height: 40px;
		}
	}

	.navbar-nav {
		.nav-item {
			@include rem(padding, 5px);
			border: solid $gray-800;
			border-width: 0 1px 0 0;
			&:first-of-type {
				border-width: 0 1px 0 1px;
			}

			&.active {
				background-color: $gray-800;
			}
		}

		.nav-link {
			color: $white;
		}
	}
}