.section-video-preview {
	display: block;
	text-decoration: none !important;
	@include rem(margin-bottom, 20px);

	.card {
		border-radius: 10px;
		background-color: #000;
	}

	.card-image-container {
		position: relative;
		border: solid #5d5d5d;
		border-width: 0 0 1px 0;

		img {
			border-radius: 10px 10px 0 0 ;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: $black;
			opacity: 0.1;
			border-radius: 10px 10px 0 0;
		}

		&:after {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-family: FontAwesome;
			color: $white;
			@include rem(font-size, 50px);
			content: "\f16a";
		}
	}

	.card-body {
		.card-text {
			color: white;
			font-weight: 600;
		}
	}

	&:hover {
		.card-image-container {
			&:before {
				opacity: 0.5;
			}
		}
	}
}